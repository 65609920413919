import React from "react"
import { Box, Typography } from "@mui/material"
import { RenderImgFileAsSvg } from "../RenderImgFileAsSvg"

const TITLE_DEFAULT_COLOR = "#4A4A68"

export function Header({ iconSrc, title, iconColor }) {
	return (
		<Box sx={{ display: "flex", alignItems: "center" }}>
			<Box
				sx={{
					width: 26,
					height: 26,
					mr: 1
				}}
			>
				<RenderImgFileAsSvg src={iconSrc} fill={iconColor} />
			</Box>

			<Typography
				variant="h3"
				sx={{
					color: TITLE_DEFAULT_COLOR,
					fontFamily: "Poppins",
					fontSize: "24px",
					fontStyle: "normal",
					fontWeight: 700,
					lineHeight: "normal",
					letterSpacing: "-0.48px"
				}}
			>
				{title}
			</Typography>
		</Box>
	)
}
