import React from "react"
import { ERRORS } from "../../../_enums/errors"

export async function sendViaAPP({
	people = [],
	inputMessage,
	inputSubject,
	alignment,
	inputDate,
	inputTime,
	user,
	customerId,
	setSuccess,
	setFailure,
	dispatch
}) {
	const isBulk = people.length > 0

	const userIDs = isBulk ? people.map(val => val.UID).filter(val => val) : [null]

	const timeString = inputTime?.format("HH:mm:ss")
	const dateString = inputDate?.format("MM/DD/YYYY")
	const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

	const scheduledTo = inputDate && inputTime ? `${dateString} ${timeString}` : null
	const operation = inputDate && inputTime ? "schedule-notification" : "send-notification"
	const action = inputDate && inputTime ? "scheduled" : "sent"
	const type = isBulk ? "message" : "announcement"

	const myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const promises = userIDs.map(async UID => {
		const raw = JSON.stringify({
			type,
			subject: inputSubject,
			body: inputMessage,
			severity: alignment === "critical" ? 3 : alignment === "medium" ? 2 : 1,
			user: {
				callersUserId: user.id,
				callersTenantId: user.tenantId
			},
			...(isBulk && { uidTo: UID }),
			scheduledTo,
			timezone: timeZone
		})

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		}

		console.log("🚀 ~ raw:", raw)

		const url = `${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/customers/${customerId}/communication/${operation}`

		try {
			const response = await fetch(url, requestOptions)
			const result = await response.json()

			if (response?.status === 200) {
				return { success: true, message: result }
			} else {
				return { success: false, message: result }
			}
		} catch (error) {
			return { success: false, message: error.message }
		}
	})

	const results = await Promise.all(promises)

	const successCount = results.filter(res => res.success).length
	const failureCount = results.length - successCount

	if (successCount > 0) {
		setSuccess(true)
		dispatch({
			type: "SNACKBAR_SUCCESS",
			payload: {
				message: isBulk
					? `Messages ${action} successfully to ${successCount} users.`
					: results[0].message
			}
		})
	}

	if (failureCount > 0) {
		setFailure(true)
		dispatch({
			type: ERRORS.TYPES.SNACKBAR_ERROR,
			payload: { message: ERRORS.GENERIC_ERRORS.FAILED_API }
		})
	}
}
