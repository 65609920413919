import React, { useState, useEffect, useMemo } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { BiEditAlt } from "react-icons/bi"
import SVG from "react-inlinesvg"

import { DataGrid } from "@mui/x-data-grid"
import CheckIcon from "@material-ui/icons/Check"

import { useUIContext } from "../UIContext"

import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { ProgressBar } from "../../../../../_metronic/layout/components/extras/ProgressBar"
import inventoryFiltersIcon from "../../../../../app/assets/ck-icons/inventory-filters-icon.svg"
import EditInventoryModelsModal from "./EditInventoryModelsModal"
import DeleteInventoryModelsModal from "./DeleteInventoryModelsModal"
import { filterBySearchString } from "../../../Admin/manage-customer/UIHelpers"

import { Button } from "../../../../_partials/Button"
import CustomPagination from "../_partials/CustomPagination"
import { CustomNoRowsOverlay, CustomNoTechnologyOverlay } from "../_partials/CustomGridOverlays"
import { QuickToolbar } from "../_partials/QuickToolbar"
import { useStyles } from "../_partials/DataGridStylesOverride"

import "../Inventory.css"
import { DATA_GRID } from "../_enums/dataGrid"

export default function ModelsInventoryTable({
	filterTypeOptions,
	filterVendorOptions,
	filterFeaturesOptions,
	filterNodeTypeOptions,
	filterChipsetOptions,
	filterBatteryOptions,
	technology,
	rows,
	setRows,
	searchText,
	setSearchText
}) {
	// UI Context
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.idsInventoryModels,
			setIds: UIContext.setIdsInventoryModels,
			setTechnology: UIContext.setTechnology,
			openDeleteInventoryModelsModal: UIContext.openDeleteInventoryModelsModal,
			setCurrentModelsTablePageInfo: UIContext.setCurrentModelsTablePageInfo,
			setRowsToUseModelsTable: UIContext.setRowsToUseModelsTable,
			setColumnVisibilityModelModelsTable: UIContext.setColumnVisibilityModelModelsTable,
			columnVisibilityModelModelsTable: UIContext.columnVisibilityModelModelsTable
		}
	}, [UIContext])

	// Redux state
	const { modelsInventory, listLoading, floorPlans } = useSelector(
		state => ({
			modelsInventory: state.inventory.modelsInventory,
			listLoading: state.inventory.listLoading,
			floorPlans: state.basePage.floorPlans
		}),

		shallowEqual
	)

	// Local state
	const [page, setPage] = useState(0)
	const [pageSize, setPageSize] = useState(10)
	const [activeFilters, setActiveFilters] = useState(false)
	const [searchedRows, setSearchedRows] = useState(null)
	const [selectionModel, setSelectionModel] = useState([])
	const [showEditModal, setShowEditModal] = useState(false)
	const [showDeleteModel, setShowDeleteModal] = useState(false)
	const [rowsToUse, setRowsToUse] = useState([])
	const [currentRows, setCurrentRows] = useState([])
	const [rowCounter, setRowCounter] = useState(0)
	const [rowCountState, setRowCountState] = useState(rowCounter)
	const [sortModel, setSortModel] = useState([
		{
			field: "name",
			sort: "asc"
		}
	])
	const classes = useStyles()

	useEffect(() => {
		if (!modelsInventory || !modelsInventory.length > 0 || !technology) return

		let unfilteredModels = modelsInventory
		unfilteredModels =
			unfilteredModels &&
			unfilteredModels.length > 0 &&
			unfilteredModels.map((model, index) => {
				return { ...model }
			})

		if (
			!filterTypeOptions ||
			!filterVendorOptions ||
			!filterFeaturesOptions ||
			!filterNodeTypeOptions ||
			!filterChipsetOptions ||
			!filterBatteryOptions
		)
			return

		const anyFilterActive =
			filterTypeOptions.find(filter => filter.selected) ||
			filterVendorOptions.find(filter => filter.selected) ||
			filterFeaturesOptions.find(filter => filter.selected) ||
			filterNodeTypeOptions.find(filter => filter.selected) ||
			filterChipsetOptions.find(filter => filter.selected) ||
			filterBatteryOptions.find(filter => filter.selected)

		let filteredRows = null
		if (anyFilterActive) {
			setActiveFilters(true)

			filteredRows = unfilteredModels.filter(
				node =>
					filterTypeOptions.find(filter => filter.node === node.type && filter.selected) ||
					filterVendorOptions.find(filter => filter.node === node.vendor && filter.selected) ||
					filterFeaturesOptions.find(
						filter => filter.node === node.features && filter.selected
					) ||
					filterNodeTypeOptions.find(
						filter => filter.node === node.nodeType && filter.selected
					) ||
					filterChipsetOptions.find(
						filter => filter.node === node.chipset && filter.selected
					) ||
					filterBatteryOptions.find(
						filter => filter.node === node.battery.toString() && filter.selected
					)
			)
			setRows(filteredRows)
		} else {
			setActiveFilters(false)
			setRows(unfilteredModels)
		}
	}, [
		modelsInventory,
		filterTypeOptions,
		filterFeaturesOptions,
		filterVendorOptions,
		filterNodeTypeOptions,
		filterChipsetOptions,
		filterBatteryOptions,
		technology,
		floorPlans
	])

	// search logic with filterBySearchString
	useEffect(() => {
		if (!rows) return

		if (!searchText && searchText.trim() == "") setSearchedRows(filteredRows)

		// Define the fields to search
		const fieldsToSearch = [
			DATA_GRID.FIELDS.BATTERY,
			DATA_GRID.FIELDS.CHIPSET,
			DATA_GRID.FIELDS.NAME,
			DATA_GRID.FIELDS.NODE_TYPE,
			DATA_GRID.FIELDS.TYPE,
			DATA_GRID.FIELDS.VENDOR
		]
		const filteredRows = filterBySearchString(rows, searchText, fieldsToSearch)

		if (filteredRows && filteredRows.length > 0) {
			setSearchedRows(filteredRows)
		} else {
			setSearchedRows([])
		}
	}, [rows, searchText])

	// rowsToUse is responsible for saving the data state when ever a new data filters appears
	// this means on searching, sorting, filtering or hiding rowsToUse will always have all data
	useEffect(() => {
		if (!rows && !searchedRows) return

		if (searchedRows) {
			setRowsToUse(searchedRows)
		} else if (rows && rows.length > 0) {
			setRowsToUse(rows)
		}
	}, [rows, searchedRows])

	// currentRows is responsible for keeping track of the current rows the user is viewing
	// directly connect to page and pageSize for selection rows to be working properly
	useEffect(() => {
		updateCurrentRows()
		UIProps.setCurrentModelsTablePageInfo({ page: page, pageSize: pageSize })
	}, [rowsToUse, page, pageSize])

	// this controlers the rowsCount prop from data grid
	// it enables to change page and page size and keep track of past selected rows and "select all" only for current page
	useEffect(() => {
		setRowCountState(prevRowCountState =>
			rowCounter !== undefined ? rowCounter : prevRowCountState
		)
	}, [rowCounter])

	const getNestedValue = (obj, path) => {
		if (!path.includes(".")) return obj[path]
		return path
			.split(".")
			.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj)
	}

	const handleSortModelChange = newSortModel => {
		if (!rowsToUse) return

		const sortedRows = [...rowsToUse].sort((a, b) => {
			for (const sortItem of newSortModel) {
				const { field, sort } = sortItem
				if (!field || sort === null) continue

				const sortOrder = sort === "asc" ? 1 : -1

				const aValue = getNestedValue(a, field)
				const bValue = getNestedValue(b, field)

				if (aValue === null || aValue === undefined) return -sortOrder
				if (bValue === null || bValue === undefined) return sortOrder

				if (typeof aValue === "boolean" && typeof bValue === "boolean") {
					return aValue === bValue ? 0 : aValue ? sortOrder : -sortOrder
				}

				if (typeof aValue === "string") {
					const comparison = aValue.localeCompare(bValue, undefined, { numeric: true })
					if (comparison !== 0) return comparison * sortOrder
				}

				if (typeof aValue === "number") {
					return (aValue - bValue) * sortOrder
				}
			}
			return 0
		})

		setRowsToUse(sortedRows)
		setCurrentRows(sortedRows.slice(page * pageSize, (page + 1) * pageSize))
		setSortModel(newSortModel)
	}

	// Function to update the current rows based on the current page and page size
	const updateCurrentRows = () => {
		const startIndex = page * pageSize
		const endIndex = Math.min(startIndex + pageSize, rowsToUse.length)
		const newCurrentRows = rowsToUse.slice(startIndex, endIndex)
		setCurrentRows(newCurrentRows)
		setRowCounter(rowsToUse.length)
		// prints all data
		UIProps.setRowsToUseModelsTable(rowsToUse)
	}

	const columns = [
		{
			field: "actions",
			hideable: false,
			headerName: "Actions",
			renderCell: params => {
				return (
					<div data-testid="actions-cell">
						<>
							<OverlayTrigger
								placement="bottom"
								overlay={<Tooltip id="firebase-Edit-tooltip">Edit Model</Tooltip>}
							>
								<Button
									data-testid="edit_button"
									id={params.row.id}
									variant="text"
									onClick={() => {
										UIProps.setIds([params.row.id])
										setSelectionModel([params.row.id])
										setShowEditModal(true)
									}}
									startIcon={<BiEditAlt color="#2673F0" />}
								/>
							</OverlayTrigger>
							<OverlayTrigger
								placement="bottom"
								overlay={
									<Tooltip id="firebase-Edit-tooltip">Delete Selected Models</Tooltip>
								}
							>
								<Button
									variant="contained"
									color="errorLight"
									className="MUICustom_secondary"
									style={{
										marginLeft: "1rem",
										borderRadius: "5px",
										maxWidth: "34px",
										minWidth: "34px",
										height: "33px"
									}}
									startIcon={
										<SVG
											className="MUICustom_secondary_svg"
											src={toAbsoluteUrl("/media/svg/icons/General/trash2.svg")}
										/>
									}
									onClick={() => {
										UIProps.setIds([params.row.id])
										setSelectionModel([params.row.id])
										setShowDeleteModal(true)
									}}
									data-testid="unassign-button"
								/>
							</OverlayTrigger>
						</>
					</div>
				)
			},
			editable: false
		},
		{
			field: "name",
			headerName: "Name",
			hideable: false,
			type: "string",
			minWidth: 150,
			hideable: false
		},
		{
			field: "vendor",
			headerName: "Vendor",
			type: "string",
			minWidth: 150
		},
		{
			field: "nodeType",
			headerName: "Node Type",
			type: "string",
			minWidth: 150,
			renderCell: params => {
				const { nodeType, sinks } = params.row

				if (sinks && nodeType == "gateway") {
					return (
						<OverlayTrigger
							placement="bottom"
							overlay={<Tooltip id="firebase-notification-tooltip">Sinks: {sinks}</Tooltip>}
						>
							<span>{nodeType}</span>
						</OverlayTrigger>
					)
				}
				return <span>{nodeType}</span>
			}
		},
		{
			field: "type",
			headerName: "type",
			type: "string",
			minWidth: 150,
			renderCell: params => {
				const { type, nodeType } = params.row

				if (!type && nodeType == "gateway") {
					return <span>-</span>
				}
				return <span>{type}</span>
			}
		},
		{
			field: "battery",
			headerName: "Battery",
			type: "string",
			minWidth: 150
		},
		{
			field: "chipset",
			headerName: "Chipset",
			type: "string",
			minWidth: 150
		},
		{
			field: "features.led",
			headerName: "LED",
			type: "string",
			width: 100,
			renderCell: params => {
				return params.row?.features?.led || 0
			}
		},
		{
			field: "ipProtection",
			headerName: "Ip Protection",
			type: "string",
			width: 150,
			renderCell: params => {
				return params.row?.ipProtection || ""
			}
		},
		{
			field: "features.button",
			headerName: "Button",
			type: "boolean",
			width: 100,
			renderCell: params => {
				return params.row?.features?.button ? (
					<SVG src={toAbsoluteUrl("/media/svg/icons/General/CheckCircle.svg")} />
				) : (
					<SVG src={toAbsoluteUrl("/media/svg/icons/General/CrossCircle.svg")} />
				)
			}
		},
		{
			field: "features.buzzer",
			headerName: "Buzzer",
			type: "boolean",
			width: 100,
			renderCell: params => {
				return params.row?.features?.buzzer ? (
					<SVG src={toAbsoluteUrl("/media/svg/icons/General/CheckCircle.svg")} />
				) : (
					<SVG src={toAbsoluteUrl("/media/svg/icons/General/CrossCircle.svg")} />
				)
			}
		},
		{
			field: "features.motion",
			headerName: "Motion",
			type: "boolean",
			width: 100,
			renderCell: params => {
				return params.row?.features?.motion ? (
					<SVG src={toAbsoluteUrl("/media/svg/icons/General/CheckCircle.svg")} />
				) : (
					<SVG src={toAbsoluteUrl("/media/svg/icons/General/CrossCircle.svg")} />
				)
			}
		},
		{
			field: "features.nfc",
			headerName: "NFC",
			type: "boolean",
			width: 100,
			renderCell: params => {
				return params.row?.features?.nfc ? (
					<SVG src={toAbsoluteUrl("/media/svg/icons/General/CheckCircle.svg")} />
				) : (
					<SVG src={toAbsoluteUrl("/media/svg/icons/General/CrossCircle.svg")} />
				)
			}
		}
	]

	// number of rows displayed in the table
	const rowsPerPageOptions = [5, 10, 20, 30, 50, 100]

	return (
		<div style={{ width: "100%" }} data-testid="ModelsInventoryTable">
			<div className="row justify-content-end text-end">
				<span className="selected-filters-title">Selected filters</span>
			</div>
			<div className="row mb-5" style={{ marginRight: "2px" }}>
				{/* //TODO list selected filters options */}
				<div
					className="col d-flex flex-wrap-reverse justify-content-end pr-0"
					style={{
						maxHeight: "75px",
						overflow: "auto"
					}}
				>
					{!activeFilters && (
						<div>
							<div className="no-selected-filters">
								<span>
									NO SELECTED FILTERS <img src={inventoryFiltersIcon} />
								</span>
							</div>
						</div>
					)}
					{filterTypeOptions &&
						filterTypeOptions.map(type => {
							if (type.selected) {
								return (
									<div className="selected-filters" key={type.node}>
										<span>
											{type.node} <CheckIcon className="selected-filters-icon" />
										</span>
									</div>
								)
							}
						})}

					{filterFeaturesOptions &&
						filterFeaturesOptions.map(type => {
							if (type.selected) {
								return (
									<div className="selected-filters" key={type.node}>
										<span>
											{type.node} <CheckIcon className="selected-filters-icon" />
										</span>
									</div>
								)
							}
						})}
					{filterVendorOptions &&
						filterVendorOptions.map(type => {
							if (type.selected) {
								return (
									<div className="selected-filters" key={type.node}>
										<span>
											{type.node} <CheckIcon className="selected-filters-icon" />
										</span>
									</div>
								)
							}
						})}
					{filterNodeTypeOptions &&
						filterNodeTypeOptions.map(type => {
							if (type.selected) {
								return (
									<div className="selected-filters" key={type.node}>
										<span>
											{type.node} <CheckIcon className="selected-filters-icon" />
										</span>
									</div>
								)
							}
						})}
					{filterBatteryOptions &&
						filterBatteryOptions.map(type => {
							if (type.selected) {
								return (
									<div className="selected-filters" key={type.node}>
										<span>
											{type.node} <CheckIcon className="selected-filters-icon" />
										</span>
									</div>
								)
							}
						})}
					{filterChipsetOptions &&
						filterChipsetOptions.map(type => {
							if (type.selected) {
								return (
									<div className="selected-filters" key={type.node}>
										<span>
											{type.node} <CheckIcon className="selected-filters-icon" />
										</span>
									</div>
								)
							}
						})}
				</div>
			</div>
			{listLoading && <ProgressBar.LinearIndeterminate />}
			<DataGrid
				style={{
					fontFamily: "Poppins",
					fontWeight: 400,
					fontSize: "13px",
					border: "none",
					width: "100%",
					color: "#464e5f"
				}}
				rows={currentRows}
				columns={columns}
				className={classes.root}
				density="standard"
				disableExtendRowFullWidth={true}
				pagination
				disableSelectionOnClick
				loading={listLoading || !technology}
				disableColumnMenu={true}
				components={{
					Toolbar: QuickToolbar,
					Pagination: props => (
						<CustomPagination
							{...props}
							rows={rowsToUse}
							classes={classes}
							options={rowsPerPageOptions}
						/>
					),
					NoRowsOverlay: technology ? CustomNoRowsOverlay : CustomNoTechnologyOverlay
				}}
				rowsPerPageOptions={rowsPerPageOptions}
				page={page}
				onPageChange={newPage => {
					setPage(newPage)
				}}
				pageSize={pageSize}
				onPageSizeChange={e => {
					setPageSize(e)
				}}
				autoHeight
				rowCount={rowCountState}
				// to selected only current page rows
				paginationMode="server"
				onSelectionModelChange={newSelectionModel => {
					UIProps.setIds(newSelectionModel)
					setSelectionModel(newSelectionModel)
				}}
				selectionModel={selectionModel}
				// to change page and keep selected rows
				keepNonExistentRowsSelected
				// renders with matching table order
				sortingOrder={["desc", "asc"]}
				sortModel={sortModel}
				onSortModelChange={handleSortModelChange}
				// renders only visible columns
				onColumnVisibilityModelChange={e => {
					UIProps.setColumnVisibilityModelModelsTable(e)
				}}
				columnVisibilityModel={UIProps.columnVisibilityModelModelsTable}
			/>
			<EditInventoryModelsModal
				showEditModal={showEditModal}
				setShowEditModal={setShowEditModal}
				technology={technology}
				setSelectionModel={setSelectionModel}
			/>
			<DeleteInventoryModelsModal
				showDeleteModel={showDeleteModel}
				setShowDeleteModal={setShowDeleteModal}
				technology={technology}
				setSelectionModel={setSelectionModel}
			/>
		</div>
	)
}
