import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	lastError: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const manageSitesSlice = createSlice({
	name: "manageSites",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		sitesFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.sites = action.payload
		},
		siteCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.sites = state.sites ? [...state.sites, action.payload] : [action.payload.user]
		},
		siteEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null

			const newSitesArray = state.sites?.map(entity => {
				if (entity.id === action.payload.id) {
					return action.payload
				}
				return entity
			})

			state.sites = newSitesArray
		}
	}
})
