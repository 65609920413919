import { collection, query, where, getCountFromServer } from "firebase/firestore"
import { firestore as db } from "../../firebase"

export const useCountDb = async ({ collectionPath, queryParams }) => {
	if (!collectionPath) return 0

	const dataRef = collection(db, collectionPath)

	let queryConstraints = []

	if (queryParams?.where) {
		queryConstraints = [
			...queryConstraints,
			...queryParams.where.map(condition => where(...condition))
		]
	}

	const q = query(dataRef, ...queryConstraints)
	const queryResult = await getCountFromServer(q)

	return queryResult.data().count
}
