import React from "react"
import { FormControl, Select, MenuItem, makeStyles } from "@material-ui/core"
import ExpandMore from "@material-ui/icons/ExpandMore"
import SensorsTitle from "../SensorsTitle/SensorsTitle"
import TabsSensors from "../TabsSensors/TabsSensors"

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 90
	},
	select: {
		backgroundColor: "#FAFAFA",
		"& .MuiSelect-select": {
			color: "#4A4A68",
			fontFamily: "Poppins",
			fontWeight: "500"
		},
		"& .MuiSelect-select:focus": {
			backgroundColor: "#FAFAFA" // focus background color
		},
		"& .MuiSelect-icon": {
			color: "#4A4A68" // arrow icon color
		}
	}
}))

const LIVE_PAGE_TITLE = "Real Time"
const HISTORY_PAGE_TITLE = "History"

const RenderMainPageHeader = ({ LIVE_PAGE, HISTORY_PAGE, sensorsPage, setSensorsPage }) => {
	const classes = useStyles()

	const pagesOptions = [
		{ name: LIVE_PAGE_TITLE, value: LIVE_PAGE },
		{ name: HISTORY_PAGE_TITLE, value: HISTORY_PAGE }
	]

	return (
		<div
			className="d-flex align-items-sm-start align-items-center justify-content-between mb-4"
			style={{ width: "100%", minHeight: "8rem" }}
		>
			<SensorsTitle />
			<TabsSensors sensorsPage={sensorsPage} setSensorsPage={setSensorsPage} />
			{/* Mobile dropdown  */}
			<div className="col-6 d-sm-none">
				<div className="d-flex" style={{ justifyContent: "flex-end" }}>
					<FormControl className={classes.formControl}>
						<Select
							id="sensors_page_main_dropdown"
							IconComponent={ExpandMore}
							disableUnderline
							MenuProps={{
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left"
								},
								getContentAnchorEl: null
							}}
							className={classes.select}
							value={sensorsPage}
							onChange={e => setSensorsPage(e.target.value)}
						>
							{pagesOptions &&
								pagesOptions.map((val, i) => {
									return (
										<MenuItem
											key={i}
											value={val.value}
											id={"sensors_page_main_dropdown_" + val.value + "_" + i}
										>
											{val.name}
										</MenuItem>
									)
								})}
						</Select>
					</FormControl>
				</div>
			</div>
		</div>
	)
}

export default RenderMainPageHeader
