import React from "react"
import { SvgIcon } from "@material-ui/core"

const SensorsTitle = () => {
	return (
		<div
			className="col-6 d-flex align-items-sm-end align-items-center p-0"
			style={{ minHeight: "5rem" }}
		>
			<div className="d-flex align-items-center">
				<div
					style={{
						width: "30px",
						height: "30px",
						marginRight: "5px",
						marginBottom: "0px"
					}}
				>
					<SvgIcon style={{ width: "24px", height: "24px" }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="28"
							height="28"
							viewBox="0 0 28 28"
							fill="none"
						>
							<path
								d="M21.75 10.2759H20.3707C20.3686 8.44744 19.6413 6.69451 18.3484 5.40162C17.0555 4.10873 15.3026 3.38145 13.4741 3.37931V2C15.6683 2.00239 17.7719 2.87508 19.3234 4.42659C20.8749 5.97809 21.7476 8.0817 21.75 10.2759ZM18.3017 14.4138H21.0603V15.7931H18.3017V17.8621C18.3012 18.2277 18.1558 18.5783 17.8972 18.8368C17.6386 19.0954 17.2881 19.2409 16.9224 19.2414H14.8534V22H13.4741V19.2414H9.33621V22H7.9569V19.2414H5.88793C5.52224 19.241 5.17165 19.0955 4.91307 18.8369C4.6545 18.5783 4.50904 18.2278 4.50862 17.8621V15.7931H1.75V14.4138H4.50862V10.2759H1.75V8.89655H4.50862V6.82759C4.50899 6.46188 4.65442 6.11126 4.91301 5.85267C5.17161 5.59408 5.52223 5.44864 5.88793 5.44828H7.9569V2.68966H9.33621V5.44828H12.0948V6.82697L5.88793 6.82759V17.8621H16.9224V11.6552H18.3017V14.4138ZM7.9569 15.7931H14.8534V8.89655H7.9569V15.7931ZM13.4741 14.4138H9.33621V10.2759H13.4741V14.4138ZM16.9224 10.2759H18.3017C18.3002 8.99598 17.7911 7.76897 16.886 6.86396C15.981 5.95894 14.754 5.44983 13.4741 5.44828V6.82759C14.3883 6.82866 15.2648 7.19231 15.9112 7.83875C16.5577 8.4852 16.9213 9.36165 16.9224 10.2759Z"
								fill="#4A4A68"
							/>
						</svg>
					</SvgIcon>
				</div>

				<h2 style={{ color: "#4A4A68" }}>
					<b>Sensors</b>
				</h2>
			</div>
		</div>
	)
}

export default SensorsTitle
