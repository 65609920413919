import { firestore as db } from "../../../../firebase"
import {
	collection,
	doc,
	getDocs,
	getDoc,
	serverTimestamp,
	setDoc,
	orderBy,
	where,
	query
} from "firebase/firestore"
import moment from "moment"
import useFirestoreGetDocs from "../../../_utils/useFirestoreGetDocs"
import { COLLECTIONS, QUERY_CONSTRAINTS } from "../../../_enums/firestoreConstants"

function callGetAllCustomerNodes(params) {
	const { customerId, callersUserId, callersTenantId } = params

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/customers/${customerId}/getNodes`
	const url = new URL(baseUrl)
	url.searchParams.append("callersUserId", callersUserId)
	callersTenantId && url.searchParams.append("callersTenantId", callersTenantId)

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

function callFetchAllSensors(params) {
	const {
		siteId,
		dateRange = { startDate: moment().startOf("day"), endDate: moment() },
		sensors = []
	} = params

	const startDate = dateRange.startDate
		? moment(dateRange.startDate).format("YYYY-MM-DDTHH:mm:ss")
		: moment(moment().startOf("day"))
				.format("YYYY-MM-DDTHH:mm:ss")
				.replace("T00:00:00", "T23:59:59")

	const endDate = moment(dateRange.endDate || moment())
		.format("YYYY-MM-DDTHH:mm:ss")
		.replace("T00:00:00", "T23:59:59")

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	}

	const baseUrl = `${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/sites/${siteId}/sensors`
	const url = new URL(baseUrl)
	url.searchParams.append("dateStart", startDate)
	url.searchParams.append("dateEnd", endDate)

	if (sensors.length) {
		url.searchParams.append("sensors", `[${sensors}]`)
	}

	return fetch(url, requestOptions).catch(err => {
		console.log("Error: ", err)
	})
}

export function getAllCustomerNodes(queryParams) {
	if (!queryParams?.customerId || !queryParams?.callersUserId) return

	return callGetAllCustomerNodes({
		customerId: queryParams.customerId,
		callersUserId: queryParams.callersUserId,
		callersTenantId: queryParams.callersTenantId
	})
}

export function getIconsFromDB() {
	const iconsCollectionRef = collection(
		doc(db, "GlobalOptions", "pointsOfInterestIcons"),
		"POIsIcons"
	)
	const iconsQuery = query(iconsCollectionRef, orderBy("listOrder", "asc"))

	return getDocs(iconsQuery).then(snapshot => {
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))
	})
}

export function getThings(queryParams) {
	const thingsCollectionRef = collection(doc(db, "Customers", queryParams.customerId), "Things")

	return getDocs(thingsCollectionRef).then(snapshot => {
		return snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))
	})
}

export async function getUsers(queryParams) {
	const userDocRef = doc(db, `Customers/${queryParams.customerId}/Users/${queryParams.userId}`)
	const userDoc = await getDoc(userDocRef)

	const user = userDoc.data()
	if (!user?.buddies?.length > 0) {
		return
	}

	let newUsers = [user]

	const buddyDocRefs = user.buddies.map(val =>
		doc(db, `Customers/${queryParams.customerId}/Users/${val}`)
	)

	const results = await Promise.all(buddyDocRefs.map(ref => getDoc(ref)))

	const newBuddies = results.map(doc => doc.data())
	newUsers = [...newUsers, ...newBuddies]

	return newUsers
}

export function getPeopleStats(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	const peopleStatsDocRef = doc(
		db,
		`Customers/${queryParams.customerId}/${queryParams.selectedLocation}/peopleStats`
	)

	return getDoc(peopleStatsDocRef)
}

export function getAllLocationsPeopleStats(queryParams) {
	if (!queryParams || !queryParams.customerId || !queryParams.customerId.merakiNetworks) {
		return Promise.resolve(null)
	}

	const { customerId, merakiNetworks } = queryParams

	const fetchStatsPromises = merakiNetworks.map(location => {
		const docRef = doc(db, `Customers/${customerId.id}/${location.netId}/peopleStats`)
		return getDoc(docRef)
	})

	return Promise.all(fetchStatsPromises)
}

export function getAllLocationsMerakiDevices(queryParams) {
	if (!queryParams || !queryParams.customerId || !queryParams.customerId.merakiNetworks) {
		return Promise.resolve(null)
	}

	const { customerId, merakiNetworks } = queryParams

	const fetchDevicesPromises = merakiNetworks.map(location => {
		const docRef = doc(db, `Customers/${customerId.id}/${location.netId}/merakiDevices`)
		return getDoc(docRef)
	})

	return Promise.all(fetchDevicesPromises)
}

export function getDailyUsers(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	var startDate = new moment()
	var endDate = new moment()
	startDate.subtract(endDate.date() - 1, "days")

	if (queryParams.selectedLocation.customerType == "office") {
		var SSIDs = JSON.parse(JSON.stringify(queryParams?.selectedLocation?.SSIDs))
		SSIDs && SSIDs.push("")
	} else if (queryParams.selectedLocation.customerType == "shop") {
		var SSIDs = JSON.parse(JSON.stringify(queryParams?.selectedLocation?.guestSSID))
		SSIDs && SSIDs.push("")
	}

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
	myHeaders.append("Content-Type", "application/json")
	var raw = JSON.stringify({
		typechart: "globalInfo",
		networkId: queryParams.selectedLocation.netId,
		ssid: SSIDs,
		startDate: moment(startDate).format("YYYY-MM-DD"),
		endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]")
	})
	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${queryParams.selectedCustomer.customerId}`,
		requestOptions
	).catch(error => console.log("Error", error))
}

export async function getPois(queryParams) {
	if (!queryParams || !queryParams.siteId) {
		return Promise.resolve(null)
	}

	try {
		const poisCollectionRef = collection(doc(db, "Sites", queryParams.siteId), "PointsOfInterest")
		const poisSnapshot = await getDocs(poisCollectionRef)
		const pois = poisSnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))
		return pois
	} catch (error) {
		console.error("Error fetching Points of Interest:", error)
		return Promise.reject(error)
	}
}

export async function getAreasInDB(queryParams) {
	if (!queryParams || !queryParams.siteId) {
		return Promise.resolve(null)
	}

	try {
		const areasCollectionRef = collection(doc(db, "Sites", queryParams.siteId), "Areas")
		const areasSnapshot = await getDocs(areasCollectionRef)
		const areas = areasSnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))
		return areas
	} catch (error) {
		console.error("Error fetching Areas:", error)
		return Promise.reject(error)
	}
}

export async function getPreferencesInDB(queryParams) {
	if (!queryParams || !queryParams.userId) {
		return Promise.resolve(null)
	}

	try {
		const preferencesDocRef = doc(db, "UserPreferences", queryParams.userId)
		const preferencesDoc = await getDoc(preferencesDocRef)
		if (preferencesDoc) {
			return { id: preferencesDoc.id, ...preferencesDoc.data() }
		} else {
			console.log("No such document!")
			return null
		}
	} catch (error) {
		console.error("Error fetching preferences:", error)
		return Promise.reject(error)
	}
}

export async function getResourcesMeetings({
	selectedCustomer,
	selectedLocation,
	floorPlanSelected
}) {
	if (!selectedCustomer) {
		return Promise.resolve(null)
	}

	try {
		const resourcesCollectionRef = collection(doc(db, "Customers", selectedCustomer), "Resources")
		const resourcesQuery = query(
			resourcesCollectionRef,
			where("networkId", "==", selectedLocation),
			where("type", "==", "meeting"),
			orderBy("created.date")
		)

		const querySnapshot = await getDocs(resourcesQuery)
		const resources = querySnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return resources
	} catch (error) {
		console.error("Error fetching resources:", error)
		return Promise.reject(error)
	}
}

export async function createNodes(queryParams) {
	if (!queryParams || !queryParams.newNodesArray || !queryParams.newNodesArray[0]) {
		return Promise.resolve(null)
	}

	try {
		const batch = db.batch()
		const siteRef = doc(db, "Sites", queryParams.selectedSite.id)
		const nodesCollectionRef = collection(siteRef, "Nodes")

		queryParams.newNodesArray.forEach(node => {
			const nodeRef = doc(nodesCollectionRef, node.uuid.toString())
			batch.set(nodeRef, {
				mockNode: node.mockNode,
				floorPlanId: node.floorPlanId,
				siteId: node.siteId,
				gpsData: node.gpsData,
				status: node.status,
				tagState: node.tagState,
				nodeType: node.nodeType,
				networkAddress: node.networkAddress,
				uuid: node.uuid,
				is_approved: node.is_approved,
				geoHash: node.geoHash
			})
		})

		await batch.commit()
		console.log("Nodes successfully created.")
	} catch (error) {
		console.error("Error creating nodes:", error)
		return Promise.reject(error)
	}
}

export async function saveUserFilterPreferences(queryParams) {
	if (!queryParams || !queryParams[0] || !queryParams[0].userId || !queryParams[0].preferences) {
		console.error("Invalid queryParams. Unable to save preferences.")
		return Promise.resolve(null)
	}

	const userId = queryParams[0].userId
	const preferences = queryParams[0].preferences

	try {
		const userPreferencesRef = doc(db, "UserPreferences", userId)
		await setDoc(userPreferencesRef, {
			userId: userId,
			preferences: preferences,
			updated: {
				id: userId,
				date: serverTimestamp()
			}
		})
		console.log("User preferences successfully saved.")
	} catch (error) {
		console.error("Error saving user preferences:", error)
		return Promise.reject(error)
	}
}

export function fetchAllSensorsFromToday(queryParams) {
	if (!queryParams?.siteId) return

	return callFetchAllSensors({
		siteId: queryParams.siteId,
		dateRange: queryParams.dateRange,
		sensors: queryParams.sensors
	})
}

export async function fetchAllSensors(queryParams) {
	if (!queryParams) {
		console.warn("No sensorsIds provided. Returning empty result.")
		return []
	}

	try {
		const dataRef = collection(
			db,
			`${COLLECTIONS.SITES}/${queryParams.siteId}/${COLLECTIONS.NODES}`
		)
		const q = query(
			dataRef,
			where(
				QUERY_CONSTRAINTS.WHERE.NODE_TYPE,
				QUERY_CONSTRAINTS.OPERATORS.EQUAL,
				QUERY_CONSTRAINTS.VALUES.SENSOR
			),
			where(
				QUERY_CONSTRAINTS.WHERE.STATUS,
				QUERY_CONSTRAINTS.OPERATORS.EQUAL,
				QUERY_CONSTRAINTS.VALUES.ONLINE
			)
		)

		const queryResult = await getDocs(q)

		return queryResult.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))
	} catch (error) {
		console.error("Error fetching sensors from Firestore:", error)
		return null
	}
}
