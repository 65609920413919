import React, { useState } from "react"
import { DateRangePicker } from "./CustomDateRangePicker"
import { SelectMultiCheckboxAreas } from "../../../_partials/inputs/SelectMultiCheckboxAreas"

import { HISTORY } from "../../Communication/_enums/history"
import { useStyles } from "../_partials/StylesOverride"
import { Button } from "../../../_partials/Button"

const SELECT_ALL = "select-all"

const DateFilter = ({
	dateRange,
	setDateRange,
	options,
	selectedSensors,
	setSelectedSensors,
	enableSubmit,
	handleSubmit
}) => {
	const classes = useStyles()

	const isSelectAll = selectedSensors?.length === options?.length

	const handleSelectAllToggle = () => {
		if (isSelectAll) {
			setSelectedSensors([])
		} else {
			setSelectedSensors(options.map(option => option.value))
		}
	}

	const handleSelectMultipleCheckbox = selectedValues => {
		if (selectedValues.includes(SELECT_ALL)) {
			return handleSelectAllToggle()
		}

		setSelectedSensors(selectedValues)
	}

	return (
		<div
			className="row"
			data-testid="sensors-history-date-filter"
			style={{
				filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.10))",
				padding: "1rem 0",
				margin: "0",
				backgroundColor: "#FFFFFF"
			}}
		>
			<div className="col">
				<div style={{ marginBottom: HISTORY.STYLES.LABELS.MARGIN_BOTTOM }}>
					<span className={classes.inputLabel}>Sensor</span>
				</div>

				<SelectMultiCheckboxAreas
					disabled={!options.length > 0}
					value={selectedSensors}
					options={options || []}
					selectAllEnabled
					disableUnderline
					onChange={e => {
						handleSelectMultipleCheckbox(e.target.value)
					}}
				/>
			</div>

			<div className="col">
				<div style={{ marginBottom: HISTORY.STYLES.LABELS.MARGIN_BOTTOM }}>
					<span className={classes.inputLabel}>{HISTORY.TEXTS.LABELS.DATE}</span>
				</div>

				<DateRangePicker
					dateRange={dateRange}
					setDateRange={setDateRange}
					maxDate={new Date()}
				/>
			</div>

			<div className="col-2 mt-4 d-flex align-items-center justify-content-end">
				<Button
					color={HISTORY.BUTTONS.COLOR_PRIMARY}
					variant={HISTORY.BUTTONS.VARIANT_CONTAINED}
					text={HISTORY.BUTTONS.SUBMIT_TEXT}
					onClick={handleSubmit}
					disabled={!enableSubmit}
					style={{ width: HISTORY.STYLES.BUTTONS.WIDTH }}
					data-testid="submit-date-button"
				/>
			</div>
		</div>
	)
}

export default DateFilter
