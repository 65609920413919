export function addHeatmaptoMap(props) {
	const {
		map,
		sensors,
		selectedFloorPlan,
		// setShowPeople,
		// setShowAssets,
		// setShowUnassignedTags,
		setShowSensors,
		// setShowGateways,
		// setShowPOIs,
		// setShowAreaInfoMarkers,
		// setShowConnectors,
		drawerOptions,
		setDrawerOptions,
		heatmapFilter
		// drawerPreferences,
		// user
	} = props

	const sourceId = "heatmap-source"
	const layerId = "heatmap-layer"
	const layers = map.getStyle()

	if (layers) {
		// turn on/off heatmap drawer
		if (!drawerOptions.openHeatmap) {
			if (map && map.getLayer(layerId)) {
				if (typeof map.getLayer(layerId) !== "undefined") {
					map.removeLayer(layerId)
				}
				if (typeof map.getSource(sourceId) !== "undefined") {
					map.removeSource(sourceId)
				}

				// const foundedPreferences =
				// 	drawerPreferences && drawerPreferences.find(val => val.userId === user?.id)

				// setShowAreaInfoMarkers(
				// 	foundedPreferences?.preferences?.areas
				// 		? foundedPreferences?.preferences?.areas
				// 		: true
				// )
				// setShowPeople(
				// 	foundedPreferences?.preferences?.people
				// 		? foundedPreferences?.preferences?.people
				// 		: true
				// )
				// setShowAssets(
				// 	foundedPreferences?.preferences?.assets
				// 		? foundedPreferences?.preferences?.assets
				// 		: true
				// )
				// setShowUnassignedTags(
				// 	foundedPreferences?.preferences?.unassignedTags
				// 		? foundedPreferences?.preferences?.unassignedTags
				// 		: true
				// )
				// setShowPOIs(
				// 	foundedPreferences?.preferences?.pois ? foundedPreferences?.preferences?.pois : true
				// )
				// setShowConnectors(
				// 	foundedPreferences?.preferences?.connectors
				// 		? foundedPreferences?.preferences?.connectors
				// 		: true
				// )

				// setShowSensors(
				// 	foundedPreferences?.preferences?.anchors
				// 		? foundedPreferences?.preferences?.anchors
				// 		: false
				// )
				// setShowGateways(
				// 	foundedPreferences?.preferences?.gateways
				// 		? foundedPreferences?.preferences?.gateways
				// 		: false
				// )

				setShowSensors(true) // when close heatmap on drawer show sensor markers

				setDrawerOptions({
					...drawerOptions,
					open3d: false
				})
				// center and rezoom
				recenterAndRezoom({
					map,
					selectedFloorPlan
				})

				return
			}
			return
		}

		// // remove all markers
		// setShowPeople(false)
		// setShowAssets(false)
		// setShowUnassignedTags(false)
		setShowSensors(false)
		// setShowGateways(false)
		// setShowPOIs(false)
		// setShowAreaInfoMarkers(false)
		// setShowConnectors(false)

		// check if source and layer exist
		if (typeof map.getLayer(layerId) !== "undefined") {
			map.removeLayer(layerId)
		}
		if (typeof map.getSource(sourceId) !== "undefined") {
			map.removeSource(sourceId)
		}

		// sensors only on selectedFloorplan and if has gpsData
		const sensorsInSelectedFloorplan = sensors.filter(tag => {
			return tag.floorPlanId === selectedFloorPlan?.id
		})

		// filter tags by drawer filter
		const filteredTags = sensorsInSelectedFloorplan.filter(tag => {
			if (!heatmapFilter.displayTemperature) return false
			// if (!heatmapFilter.displayAssets && tag.asset) return false
			// if (!heatmapFilter.displayUnassigned && !tag.person && !tag.asset) return false
			return true
		})

		const heatmapRadius =
			filteredTags && filteredTags.length <= 50
				? 50
				: filteredTags && filteredTags.length > 51
				? 47
				: filteredTags && filteredTags.length > 100
				? 44
				: filteredTags && filteredTags.length > 200
				? 41
				: filteredTags && filteredTags.length > 300
				? 38
				: filteredTags && filteredTags.length > 400
				? 35
				: filteredTags && filteredTags.length > 500
				? 32
				: filteredTags && filteredTags.length > 700
				? 29
				: 30

		const featureTags =
			filteredTags &&
			filteredTags.map(d => {
				return {
					type: "Feature",
					properties: {
						...d,
						weight: d.person ? 10 : d.asset ? 6 : 1.7
						// radius: d.person ? 30 : d.asset ? 25 : 20
					},
					geometry: {
						type: "Point",
						coordinates: [d?.gpsData.longitude, d?.gpsData.latitude, 0.0]
					}
				}
			})

		// add new source and layer
		map.addSource(sourceId, {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: featureTags
			}
		})

		map.addLayer(
			{
				id: layerId,
				type: "heatmap",
				source: sourceId,
				paint: {
					"heatmap-weight": ["interpolate", ["linear"], ["get", "weight"], 0, 0.1, 6, 6.2],
					"heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 50, 8, 140], // Increased radius
					"heatmap-color": [
						"interpolate",
						["linear"],
						["heatmap-density"],
						0,
						"rgba(0, 0, 255, 0)", // Transparent blue
						0.5,
						"blue", // Blue
						0.6,
						"green", // Bright green
						0.75,
						"yellow", // Yellow
						1,
						"red" // Red
					],
					"heatmap-opacity": 1,
					"heatmap-intensity": ["interpolate", ["linear"], ["zoom"], 0, 1, 1, 1.1]
				}
			}
			// "country-label"
		)
	}
}

function recenterAndRezoom({ map, selectedFloorPlan }) {
	// Calculate the center coords of the selectedFloorPlan?.coords
	let corners = selectedFloorPlan?.coords
	let latSum = 0
	let lonSum = 0

	for (let corner in corners) {
		latSum += corners[corner].coords.latitude
		lonSum += corners[corner].coords.longitude
	}

	let centerLat = latSum / Object.keys(corners).length
	let centerLon = lonSum / Object.keys(corners).length

	if (Boolean(selectedFloorPlan.mapOrientation) === true) {
		const lat = selectedFloorPlan.mapOrientation.center.latitude
		const lng = selectedFloorPlan.mapOrientation.center.longitude
		map.easeTo({
			center: [lng, lat],
			bearing: selectedFloorPlan.mapOrientation.bearing,
			pitch: selectedFloorPlan.mapOrientation.pitch,
			zoom: selectedFloorPlan.mapOrientation.zoom,
			duration: 0
		})
	} else {
		// Onde é centrado o mapa por default
		selectedFloorPlan?.mapOrientation?.zoom
			? map.flyTo({
					center: [centerLon, centerLat],
					zoom: selectedFloorPlan?.mapOrientation?.zoom
			  })
			: map.flyTo({
					center: [centerLon, centerLat]
			  })
		// selectedFloorPlan.coords.topLeftCorner?.coords?.longitude
		// 	? map.fitBounds(
		// 			[
		// 				[
		// 					selectedFloorPlan?.coords.topLeftCorner.coords.longitude,
		// 					selectedFloorPlan?.coords.topLeftCorner.coords.latitude
		// 				],
		// 				[
		// 					selectedFloorPlan.coords.bottomRightCorner.coords.longitude,
		// 					selectedFloorPlan.coords.bottomRightCorner.coords.latitude
		// 				]
		// 			],
		// 			// ↓ Duração da animação a iniciar o mapa e o zoom inicial
		// 			{
		// 				padding: { top: 10, bottom: 10, left: 10, right: 10 }
		// 			}
		// 	  )
		// 	: map.fitBounds(
		// 			[
		// 				[
		// 					selectedFloorPlan?.coords.topLeftCorner.longitude,
		// 					selectedFloorPlan?.coords.topLeftCorner.latitude
		// 				],
		// 				[
		// 					selectedFloorPlan.coords.bottomRightCorner.longitude,
		// 					selectedFloorPlan.coords.bottomRightCorner.latitude
		// 				]
		// 			],
		// 			// ↓ Duração da animação a iniciar o mapa e o zoom inicial
		// 			{
		// 				padding: { top: 10, bottom: 10, left: 10, right: 10 }
		// 			}
		// 	  )
	}
}
