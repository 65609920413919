import { firestore as db } from "../../../../../firebase"
import {
	collection,
	doc,
	query,
	where,
	getDocs,
	arrayUnion,
	updateDoc,
	addDoc,
	deleteDoc,
	arrayRemove,
	orderBy,
	setDoc,
	serverTimestamp,
	writeBatch,
	getDoc
} from "firebase/firestore"
import { COLLECTIONS, QUERY_CONSTRAINTS } from "../../../../_enums/firestoreConstants"
import useFirestoreGetDocs from "../../../../_utils/useFirestoreGetDocs"

export async function updateUrls(queryParams) {
	if (!queryParams) {
		return null
	}

	if (
		!queryParams.zoneToUpdateUrls ||
		queryParams.zoneToUpdateUrls.length === 0 ||
		!queryParams.customerId ||
		!queryParams.newImagesUrls ||
		!queryParams.floorPlanId ||
		!queryParams.geoJson
	) {
		return null
	}

	const customersRef = collection(db, "Customers")

	const zoneId = queryParams.zoneToUpdateUrls[0]?.id

	// Create document references
	const zoneDocRef = doc(collection(doc(customersRef, queryParams.customerId), "Zones"), zoneId)
	const floorPlanDocRef = doc(
		doc(customersRef, queryParams.customerId),
		"FloorPlans",
		queryParams.floorPlanId
	)

	try {
		await Promise.all([
			updateDoc(zoneDocRef, {
				imagesUrls: queryParams.newImagesUrls
			}),
			updateDoc(floorPlanDocRef, {
				geoJson: queryParams.geoJson
			})
		])
		return true
	} catch (error) {
		console.error("Error updating documents:", error)
		return false
	}
}

// Create a reference to the Areas collection within a specific network
export async function getZonesInDB({ floorPlanId, networkId }) {
	if (!floorPlanId || !networkId) {
		return null
	}

	// Reference to the Areas collection within the network
	const areasCollectionRef = collection(doc(collection(db, "Sites"), networkId), "Areas")

	// Create a query to filter by floorPlanId
	const zonesQuery = query(areasCollectionRef, where("floorPlanId", "==", floorPlanId))

	try {
		// Fetch the documents matching the query
		const querySnapshot = await getDocs(zonesQuery)
		// Map the documents to their data
		const zones = querySnapshot?.docs?.map(doc => ({ id: doc.id, ...doc.data() }))
		return zones
	} catch (error) {
		console.error("Error fetching zones:", error)
		return null
	}
}

export async function saveUrls(queryParams) {
	if (!queryParams) {
		return null
	}

	if (
		!queryParams.customerId ||
		!queryParams.floorPlanId ||
		!queryParams.geoJson ||
		!queryParams.row ||
		!queryParams.row.geoJson ||
		!queryParams.row.geoJson.properties ||
		!queryParams.row.geoJson.properties.zoneId ||
		!queryParams.result
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	const customersRef = collection(db, "Customers")

	// Document references
	const floorPlanDocRef = doc(
		customersRef,
		queryParams.customerId,
		"FloorPlans",
		queryParams.floorPlanId
	)
	const zoneDocRef = doc(
		collection(doc(customersRef, queryParams.customerId), "Zones"),
		queryParams.row.geoJson.properties.zoneId
	)

	try {
		await Promise.all([
			updateDoc(floorPlanDocRef, {
				geoJson: queryParams.geoJson
			}),
			updateDoc(zoneDocRef, {
				imagesUrls: arrayUnion(...queryParams.result)
			})
		])
		return true
	} catch (error) {
		console.error("Error updating documents:", error)
		return false
	}
}

export async function saveGeoJson(queryParams) {
	if (!queryParams) {
		return null
	}

	if (
		!queryParams.promises ||
		!queryParams.networkId ||
		!queryParams.floorPlanId ||
		!queryParams.geoJson
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	const sitesRef = collection(db, "Sites")

	// Create document reference
	const floorPlanDocRef = doc(
		collection(doc(sitesRef, queryParams.networkId), "FloorPlans"),
		queryParams.floorPlanId
	)

	try {
		// Update the document and wait for all promises to resolve
		await Promise.all([
			...queryParams.promises,
			updateDoc(floorPlanDocRef, {
				geoJson: queryParams.geoJson
			})
		])
		return true
	} catch (error) {
		console.error("Error updating GeoJson:", error)
		return false
	}
}

export async function saveLayerTypes(queryParams) {
	if (!queryParams) {
		return null
	}

	if (!queryParams.customerId || !queryParams.layerTypes) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	// Create document reference
	const customersRef = collection(db, "Customers")

	const customerDocRef = doc(customersRef, queryParams.customerId)

	try {
		// Update the document
		await updateDoc(customerDocRef, {
			layerTypes: queryParams.layerTypes
		})
		return true
	} catch (error) {
		console.error("Error updating layer types:", error)
		return false
	}
}

export async function deleteLayerTypes(queryParams) {
	if (!queryParams) {
		return null
	}

	if (!queryParams.customerId || !queryParams.layerTypes) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	// Create document reference
	const customersRef = collection(db, "Customers")

	const customerDocRef = doc(customersRef, queryParams.customerId)

	try {
		// Update the document by removing items from the layerTypes array
		await updateDoc(customerDocRef, {
			layerTypes: arrayRemove(...queryParams.layerTypes)
		})
		return true
	} catch (error) {
		console.error("Error deleting layer types:", error)
		return false
	}
}

export async function updateLayerTypes(queryParams) {
	if (!queryParams) {
		return null
	}

	if (!queryParams.customerId || !queryParams.layerTypes) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	// Create a reference to the document
	const customersRef = collection(db, "Customers")

	const customerDocRef = doc(customersRef, queryParams.customerId)

	try {
		// Update the document
		await updateDoc(customerDocRef, {
			layerTypes: queryParams.layerTypes
		})
		return true
	} catch (error) {
		console.error("Error updating layer types:", error)
		return false
	}
}

export async function saveZones({ data, customerId, netId, floorPlanId }) {
	if (!Array.isArray(data)) {
		return null // If data is not an array, resolve with null
	}

	// Create a reference to the Zones collection for the specified customer
	const customersRef = collection(db, "Customers")

	const zonesCollectionRef = collection(doc(customersRef, customerId), "Zones")

	try {
		await Promise.all(
			data.map(async zone => {
				// Add a new document to the Zones collection and then update it
				const newZoneDocRef = await addDoc(zonesCollectionRef, {
					name: zone.name,
					color: zone.color,
					polygon: zone.coords,
					floorPlanId: floorPlanId,
					netId: netId,
					limit: zone.capacity,
					wirelessAP: []
				})

				// Return the promise from updateDoc, resolving once the update is complete
				return updateDoc(newZoneDocRef, {
					// Additional updates if needed can be added here
				})
			})
		)
		return true // Return true when all updates are successful
	} catch (error) {
		console.error("Error saving zones:", error)
		return false // Return false if an error occurs
	}
}

export async function deleteZones(queryParams) {
	if (!queryParams) {
		return null // Return null if queryParams is not provided
	}

	if (
		!queryParams.zoneToDelete ||
		!queryParams.zoneToDelete.type ||
		!queryParams.networkId ||
		!queryParams.floorPlanId ||
		!queryParams.geoJson
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	const sitesRef = collection(db, "Sites")

	// Create references to the documents
	const floorPlanDocRef = doc(
		collection(doc(sitesRef, queryParams.networkId), "FloorPlans"),
		queryParams.floorPlanId
	)

	if (queryParams.zoneToDelete.type === "baseLayer") {
		try {
			// Update the base layer
			await updateDoc(floorPlanDocRef, {
				geoJson: queryParams.geoJson
			})
			return true // Return true on successful update
		} catch (error) {
			console.error("Error updating base layer:", error)
			return false // Return false on error
		}
	} else {
		// Create a reference to the Areas collection and the specific document
		const areaDocRef = doc(
			collection(doc(sitesRef, queryParams.networkId), "Areas"),
			queryParams.zoneToDelete.geoJson.properties.areaId
		)

		try {
			// Delete the document and update the floor plan
			await Promise.all([
				deleteDoc(areaDocRef),
				updateDoc(floorPlanDocRef, {
					geoJson: queryParams.geoJson
				})
			])
			return true // Return true on successful operations
		} catch (error) {
			console.error("Error deleting zones:", error)
			return false // Return false on error
		}
	}
}

export async function saveMarkersFromZones(queryParams) {
	if (!queryParams) {
		return null // Return null if queryParams is not provided
	}

	if (
		!queryParams.networkId ||
		!queryParams.marker ||
		!queryParams.marker.markerOfAreaId ||
		!queryParams.marker.coords
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	const sitesRef = collection(db, "Sites")

	// Create a reference to the Areas collection and the specific document
	const areaDocRef = doc(
		collection(doc(sitesRef, queryParams.networkId), "Areas"),
		queryParams.marker.markerOfAreaId
	)

	try {
		// Update the document with the marker coordinates
		await updateDoc(areaDocRef, {
			marker: queryParams.marker.coords
		})
		return true // Return true on successful update
	} catch (error) {
		console.error("Error saving markers from zones:", error)
		return false // Return false on error
	}
}

export async function saveDoorMarkersFromZones(queryParams) {
	if (!queryParams) {
		return null // Return null if queryParams is not provided
	}

	if (
		!queryParams.networkId ||
		!queryParams.doorMarker ||
		!queryParams.doorMarker.markerOfAreaId ||
		!queryParams.doorMarker.coords
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	const sitesRef = collection(db, "Sites")

	// Create a reference to the Areas collection and the specific document
	const areaDocRef = doc(
		collection(doc(sitesRef, queryParams.networkId), "Areas"),
		queryParams.doorMarker.markerOfAreaId
	)

	try {
		// Update the document with the door marker coordinates
		await updateDoc(areaDocRef, {
			doorMarker: queryParams.doorMarker.coords
		})
		return true // Return true on successful update
	} catch (error) {
		console.error("Error saving door markers from zones:", error)
		return false // Return false on error
	}
}

//★━━━━━━━━━★ Points of Interest CRUD ★━━━━━━━━━★\\

export async function createPointOfInterest({
	data,
	siteId,
	user,
	imagesUrls,
	floorPlanId,
	coords,
	markerVisible
}) {
	if (!siteId || !data || !user || !imagesUrls || !floorPlanId || !coords || !markerVisible) {
		return null // Return null if required parameters are missing
	}

	const sitesRef = collection(db, "Sites")

	// Create a reference to the PointsOfInterest collection and generate a new document ID
	const pointOfInterestRef = doc(collection(doc(sitesRef, siteId), "PointsOfInterest"))

	try {
		// Set the document with provided data
		await setDoc(pointOfInterestRef, {
			name: data.name,
			description: data.description,
			type: data.type,
			imagesUrls: imagesUrls,
			siteId: siteId,
			created: {
				id: user.id,
				email: user.email,
				date: serverTimestamp() // Use serverTimestamp() for timestamp
			},
			floorPlanId: floorPlanId,
			coords: coords,
			markerVisible: markerVisible
		})

		return pointOfInterestRef.id // Return the ID of the created document
	} catch (error) {
		console.error("Error creating point of interest:", error)
		return null // Return null on error
	}
}

export async function updatePointOfInterest({ data, siteId, user, newImagesUrlsArray }) {
	if (!siteId || !data || !user) {
		return null // Return null if required parameters are missing
	}

	const id = data.id
	// Create a reference to the PointsOfInterest collection and the specific document
	const sitesRef = collection(db, "Sites")
	const pointOfInterestRef = doc(collection(doc(sitesRef, siteId), "PointsOfInterest"), id)

	try {
		// Update the document with the provided data
		await updateDoc(pointOfInterestRef, {
			name: data.name,
			description: data.description,
			type: data.type,
			imagesUrls: newImagesUrlsArray,
			siteId: siteId,
			updated: {
				id: user.id,
				email: user.email,
				date: serverTimestamp() // Use serverTimestamp() for timestamp
			},
			markerVisible: data.markerVisible
		})
		return id // Return the ID of the updated document
	} catch (error) {
		console.error("Error updating point of interest:", error)
		return null // Return null on error
	}
}

export async function savePointsOfInterest({ data, customerId }) {
	if (!customerId || !data) {
		return null // Return null if required parameters are missing
	}

	const customersRef = collection(db, "Customers")

	// Create an array of update promises
	const updatePromises = data
		.filter(resource => resource.id !== undefined)
		.map(resource => {
			// Create a reference to the specific document
			const pointOfInterestRef = doc(
				collection(doc(customersRef, customerId), "pointsOfInterest"),
				resource.id
			)

			// Return the promise for updating the document
			return updateDoc(pointOfInterestRef, {
				floorPlanId: resource.floorPlanId || "",
				networkId: resource.networkId || "",
				coords: resource.coords || ""
			})
		})

	// Execute all update promises concurrently
	try {
		await Promise.all(updatePromises)
		return true // Return true on successful update
	} catch (error) {
		console.error("Error saving points of interest:", error)
		return false // Return false on error
	}
}

export async function saveMarkersFromPOIs(queryParams) {
	if (!queryParams) {
		return null // Return null if queryParams is missing
	}

	const { siteId, marker } = queryParams

	if (!siteId || !marker || !marker.markerOfPOIsId) {
		return null // Return null if required parameters are missing
	}

	const sitesRef = collection(db, "Sites")
	// Create a reference to the specific PointsOfInterest document
	const pointOfInterestRef = doc(
		collection(doc(sitesRef, siteId), "PointsOfInterest"),
		marker.markerOfPOIsId
	)

	try {
		// Update the document with provided data
		await updateDoc(pointOfInterestRef, {
			coords: marker.coords || "",
			floorPlanId: marker.floorPlanId || ""
		})
		return true // Return true on successful update
	} catch (error) {
		console.error("Error saving markers from POIs:", error)
		return false // Return false on error
	}
}

export async function getPointsOfInterest(queryParams) {
	if (!queryParams || !queryParams.siteId) {
		console.error("Missing queryParams or siteId")
		return null // Return null if queryParams or siteId is missing
	}

	try {
		// Create a reference to the Sites collection
		const sitesRef = collection(db, "Sites")

		// Create a reference to the PointsOfInterest collection within the specified siteId
		const pointsOfInterestRef = collection(doc(sitesRef, queryParams.siteId), "PointsOfInterest")

		// Create a query to order the documents by "created.date"
		const pointsOfInterestQuery = query(pointsOfInterestRef, orderBy("created.date"))

		// Execute the query and get the documents
		const querySnapshot = await getDocs(pointsOfInterestQuery)

		// Map the documents to an array of data
		const pointsOfInterest = querySnapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		return pointsOfInterest // Return the array of points of interest
	} catch (error) {
		console.error("Error getting points of interest:", error)
		return null // Return null on error
	}
}

export async function deletePointOfInterest({ id, siteId }) {
	if (!id || !siteId) {
		return null // Return null if id or siteId is missing
	}

	// Create a reference to the specific PointsOfInterest document
	const pointOfInterestRef = doc(collection(doc(sitesRef, siteId), "PointsOfInterest"), id)
	const sitesRef = collection(db, "Sites")
	try {
		// Delete the document
		await deleteDoc(pointOfInterestRef)
		return true // Return true on successful deletion
	} catch (error) {
		console.error("Error deleting point of interest:", error)
		return false // Return false on error
	}
}

export async function getPointOfInterestToUpdate({ id, siteId }) {
	if (!siteId || !id) {
		return null // Return null if siteId or id is missing
	}

	// Create a reference to the specific PointsOfInterest document
	const sitesRef = collection(db, "Sites")
	const pointOfInterestRef = doc(collection(doc(sitesRef, siteId), "PointsOfInterest"), id)
	try {
		// Get the document snapshot
		const docSnapshot = await getDoc(pointOfInterestRef)
		if (docSnapshot) {
			return { id: docSnapshot.id, ...docSnapshot.data() } // Return the document data with id
		} else {
			return null // Return null if document does not exist
		}
	} catch (error) {
		console.error("Error getting point of interest:", error)
		return null // Return null on error
	}
}

// ━━━━━━━━━━━━━━━━━━━━━━━━ Anchors Position ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\

export async function getAnchorsCollection(queryParams) {
	const sitesRefAux = collection(db, "Sites")

	if (!queryParams || !queryParams.siteId) {
		return null // Return null if queryParams or siteId is missing
	}

	// Create a reference to the AnchorsPosition collection within the specified siteId
	const anchorsPositionRef = collection(doc(sitesRefAux, queryParams.siteId), "AnchorsPosition")

	// Create a query to order the documents by "created.date"
	const anchorsPositionQuery = query(anchorsPositionRef, orderBy("created.date"))

	try {
		// Execute the query and get the documents
		const querySnapshot = await getDocs(anchorsPositionQuery)
		// Map the documents to an array of data
		const anchors = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
		return anchors // Return the array of anchors
	} catch (error) {
		console.error("Error getting anchors collection:", error)
		return null // Return null on error
	}
}

export async function createAnchorOnDatabase({
	data,
	siteId,
	networkAddress,
	user,
	imagesUrls,
	floorPlanId,
	wirepasFloorPlanId,
	gpsData,
	customerId
}) {
	if (
		!data ||
		!siteId ||
		!networkAddress ||
		!user ||
		!floorPlanId ||
		!wirepasFloorPlanId ||
		!gpsData ||
		!customerId
	) {
		return null // Return null if any required parameter is missing
	}

	// Create a reference to a new document in the AnchorsPosition collection
	const sitesRef = collection(db, "Sites")
	const anchorRef = doc(collection(doc(sitesRef, siteId), "AnchorsPosition"))
	try {
		// Set the document with the provided data
		await setDoc(anchorRef, {
			uuid: Number(data.uuid),
			node_name: data.node_name || data.uuid, // Use the provided node_name or fallback to uuid
			description: data.description,
			imagesUrls: imagesUrls,
			siteId: siteId,
			network_address: networkAddress,
			created: {
				id: user.id,
				email: user.email,
				date: serverTimestamp() // Use serverTimestamp for the current timestamp
			},
			floorPlanId: floorPlanId,
			wirepasFloorPlanId: wirepasFloorPlanId,
			gpsData: gpsData,
			status: "created",
			approved: false,
			customerId: customerId
		})

		return anchorRef.id // Return the ID of the newly created document
	} catch (error) {
		console.error("Error creating anchor on database:", error)
		return null // Return null if there's an error
	}
}

export async function getAnchorToUpdate({ id, siteId }) {
	if (!siteId || !id) {
		return null
	}

	const anchorDocRef = doc(
		db,
		COLLECTIONS.SITES,
		siteId,
		COLLECTIONS.ANCHORS_POSITION,
		id.toString()
	)

	try {
		const anchorDoc = await getDoc(anchorDocRef)

		if (anchorDoc) {
			return anchorDoc.data()
		} else {
			console.warn("No such document exists!")
			return null
		}
	} catch (error) {
		console.error("Error fetching anchor to update:", error)
		return null
	}
}

export async function updateAnchor({
	data,
	siteId,
	user,
	newImagesUrlsArray,
	oldAnchorDoc,
	customerId
}) {
	if (!siteId || !data || !data.uuid || !user || !customerId) return null // Return null if any required parameter is missing

	const id = data.id.toString()

	// Reference to the AnchorsPosition collection
	const anchorsRef = collection(doc(db, "Sites", siteId), "AnchorsPosition")

	// New data
	const updateData = {
		uuid: Number(data.uuid),
		node_name: typeof data.uuid === "string" ? data.uuid : data.uuid.toString(),
		description: data.description || "",
		imagesUrls: newImagesUrlsArray,
		siteId: siteId,
		updated: {
			id: user.id,
			email: user.email,
			date: serverTimestamp() // Use serverTimestamp for the current timestamp
		},
		status: data?.status,
		customerId: customerId
	}

	// Reference to the AnchorsPositionLogs collection
	const anchorsLogsRef = collection(db, "AnchorsPositionLogs")

	// Log data
	const logData = {
		docId: id,
		previousData: oldAnchorDoc,
		updatedData: updateData,
		updated: {
			id: user.id,
			email: user.email,
			date: serverTimestamp() // Use serverTimestamp for the current timestamp
		}
	}

	// Document references
	const anchorDocRef = doc(anchorsRef, id)
	const logDocRef = doc(anchorsLogsRef)

	try {
		// Perform update and set operations
		const updateAnchorOperation = updateDoc(anchorDocRef, updateData) // Update AnchorsPosition
		const updateOtherCollectionOperation = setDoc(logDocRef, logData) // Set log data in AnchorsPositionLogs

		await Promise.all([updateAnchorOperation, updateOtherCollectionOperation])

		return 2 // Return the number of operations performed
	} catch (error) {
		console.error("Error updating anchor:", error)
		return null // Return null in case of an error
	}
}

export async function deleteAnchorFromDatabase({ id, siteId, oldAnchorDoc, user }) {
	if (!id || !siteId || !oldAnchorDoc || oldAnchorDoc.length === 0 || !user) return null // Return null if any required parameter is missing

	// Create a reference to the AnchorsPosition collection
	const anchorsRef = collection(doc(db, "Sites", siteId), "AnchorsPosition")

	// Create a reference to the AnchorsPositionLogs collection
	const anchorsLogsRef = collection(db, "AnchorsPositionLogs")

	// Log data
	const logData = {
		docId: id,
		previousData: oldAnchorDoc,
		deleted: {
			id: user.id,
			email: user.email,
			date: serverTimestamp() // Use serverTimestamp for the current timestamp
		},
		anchorDeleted: true
	}

	// Create document references
	const anchorDocRef = doc(anchorsRef, id)
	const logDocRef = doc(anchorsLogsRef)

	try {
		// Perform delete and set operations
		const deleteAnchorOperation = deleteDoc(anchorDocRef) // Delete from AnchorsPosition
		const updateOtherCollectionOperation = setDoc(logDocRef, logData) // Set log data in AnchorsPositionLogs

		await Promise.all([deleteAnchorOperation, updateOtherCollectionOperation])

		return 2 // Return the number of operations performed
	} catch (error) {
		console.error("Error deleting anchor from database:", error)
		return null // Return null in case of an error
	}
}

export async function updateActiveAnchor({
	data,
	siteId,
	user,
	newImagesUrlsArray,
	oldAnchorDoc,
	customerId,
	wirepasFloorPlanId
}) {
	if (!siteId || !data || data.length === 0 || !data.id || !user || !wirepasFloorPlanId)
		return null // Return null if any required parameter is missing

	const id = data.id.toString()

	// Create a reference to the Nodes collection
	const nodesRef = collection(doc(db, "Sites", siteId), "Nodes")

	// New data
	const updateData = {
		...data,
		description: data?.description || "",
		imagesUrls: newImagesUrlsArray || [],
		status: data.status,
		updated: {
			id: user.id,
			email: user.email,
			date: serverTimestamp() // Use serverTimestamp for the current timestamp
		},
		customerId: customerId,
		wirepasFloorPlanId: wirepasFloorPlanId
	}

	// Create a reference to the AnchorsActiveLogs collection
	const anchorsLogsRef = collection(db, "AnchorsActiveLogs")

	// Log data
	const logData = {
		docId: id,
		previousData: oldAnchorDoc,
		updatedData: updateData,
		updated: {
			id: user.id,
			email: user.email,
			date: serverTimestamp() // Use serverTimestamp for the current timestamp
		}
	}

	// Create document references
	const nodeDocRef = doc(nodesRef, id)
	const logDocRef = doc(anchorsLogsRef)

	try {
		// Perform update and set operations
		const updateActiveAnchorOperation = updateDoc(nodeDocRef, updateData) // Update in Nodes collection
		const updateOtherCollectionOperation = setDoc(logDocRef, logData) // Set log data in AnchorsActiveLogs collection

		await Promise.all([updateActiveAnchorOperation, updateOtherCollectionOperation])

		return 2 // Return the number of operations performed
	} catch (error) {
		console.error("Error updating active anchor:", error)
		return null // Return null in case of an error
	}
}

export async function deleteSeveralAnchorsFromDatabase(queryParams) {
	if (
		!queryParams ||
		!queryParams.siteId ||
		!queryParams.user ||
		!queryParams.selectedData ||
		queryParams.selectedData.length === 0
	) {
		return null // Return null if any required parameter is missing
	}

	const { siteId, user, selectedData } = queryParams

	// Create a reference to the AnchorsPosition collection
	const anchorsRef = collection(doc(db, "Sites", siteId), "AnchorsPosition")

	// Create a reference to the AnchorsPositionLogs collection
	const anchorsLogsRef = collection(db, "AnchorsPositionLogs")

	// Create deletion and logging operations
	const deletionOperations = selectedData.map(anchor => deleteDoc(doc(anchorsRef, anchor.id)))
	const loggingOperations = selectedData.map(anchor => {
		const logData = {
			docId: anchor.id,
			previousData: anchor,
			deleted: {
				id: user.id,
				email: user.email,
				date: serverTimestamp() // Use serverTimestamp for the current timestamp
			},
			anchorDeleted: true
		}
		return setDoc(doc(anchorsLogsRef), logData)
	})

	try {
		// Perform all delete and log operations
		await Promise.all([...deletionOperations, ...loggingOperations])
		return selectedData.length // Return the number of operations performed
	} catch (error) {
		console.error("Error deleting anchors from database:", error)
		return null // Return null in case of an error
	}
}

export async function unplaceAnchorsFromDatabase(queryParams) {
	if (!queryParams || !queryParams.siteId || !queryParams.user || !queryParams.ids) {
		return null // Return null if any required parameter is missing
	}

	const { siteId, user, ids } = queryParams

	// Create references for the AnchorsPosition and AnchorsPositionLogs collections
	const anchorsRef = collection(doc(db, "Sites", siteId), "AnchorsPosition")
	const anchorsLogsRef = collection(db, "AnchorsPositionLogs")

	const updateData = {
		approved: false,
		status: "assigned",
		updated: {
			id: user.id,
			email: user.email,
			date: serverTimestamp() // Use serverTimestamp for the current timestamp
		}
	}

	const batch = writeBatch(db)

	// Iterate over the ids to update anchors and log the changes
	ids.forEach(id => {
		const anchorDocRef = doc(anchorsRef, id)
		batch.update(anchorDocRef, updateData)

		const logData = {
			docId: id,
			updatedData: updateData,
			updated: {
				id: user.id,
				email: user.email,
				date: serverTimestamp() // Use serverTimestamp for the current timestamp
			}
		}
		const logDocRef = doc(anchorsLogsRef)
		batch.set(logDocRef, logData)
	})

	try {
		// Commit the batch operation
		await batch.commit()
	} catch (error) {
		console.error("Error unplacing anchors from database:", error)
		return null // Return null in case of an error
	}
}

function callUpdateAnchor(params) {
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...params
	})

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/update-status`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}

export async function updateAnchorStatus(queryParams) {
	if (
		!queryParams.nodes ||
		!queryParams.status ||
		!queryParams?.siteId ||
		!queryParams?.nodeType ||
		!queryParams?.callersUserId ||
		!queryParams.oldAnchorDoc
	)
		return

	const sitesRef = collection(db, "Sites")

	// Log data
	const logData = {
		docId: queryParams.nodes,
		previousData: queryParams.oldAnchorDoc,
		updatedData: { ...queryParams },
		updated: {
			id: queryParams.callersUserId,
			email: queryParams.callersEmail || "",
			date: serverTimestamp() // Firestore v9 server timestamp
		}
	}

	let anchorsLogsRef = null
	let anchorsRef = null

	if (
		(queryParams.status && queryParams.status === "placed") ||
		queryParams.status === "planned" ||
		queryParams.status === "assigned"
	) {
		anchorsLogsRef = collection(db, "AnchorsPositionLogs") // Log Collection
		anchorsRef = collection(sitesRef, queryParams.siteId, "AnchorsPosition") // Anchor Collection
	} else {
		// Active anchor case
		anchorsLogsRef = collection(db, "AnchorsActiveLogs") // Log Collection
		anchorsRef = collection(sitesRef, queryParams.siteId, "Nodes") // Anchor Collection
	}

	const updateOtherCollectionOperation = setDoc(doc(anchorsLogsRef), logData)

	let end = []
	if (queryParams.imagesUrls && queryParams.imagesUrls.length > 0) {
		const updateImages = updateDoc(doc(anchorsRef, queryParams.nodes[0]), {
			imagesUrls: queryParams.imagesUrls
		})
		end = [updateOtherCollectionOperation, updateImages]
	} else {
		end = [updateOtherCollectionOperation]
	}

	await Promise.all(end)

	callUpdateAnchor(queryParams)

	return end.length
}

function callApproveNodes(params) {
	// console.log("🚀 ~ callApproveNodes ~ params:", params)
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...params
	})

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/approve-nodes`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}

function callUpdateApprovedNodes(params) {
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...params
	})

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/update-approved-nodes`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}

export function approveNodes(queryParams) {
	if (!queryParams?.nodes || !queryParams?.nodeType || !queryParams?.callersUserId) return

	return callApproveNodes(queryParams)
}

export function updateApprovedNodes(queryParams) {
	if (
		!queryParams?.node ||
		!queryParams?.siteId ||
		!queryParams?.callersUserId ||
		!queryParams?.customerId
	)
		return

	return callUpdateApprovedNodes(queryParams)
}

// ━━━━━━━━━━━━━━━━━━━━━━━━ Sensors Position ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\

export async function getSensorsCollection(queryParams) {
	if (!queryParams) {
		return null
	}

	const params = {
		orderBy: [[QUERY_CONSTRAINTS.ORDER_BY.CREATED_DATA, QUERY_CONSTRAINTS.ORDER_BY.ASC]]
	}
	const queryResult = useFirestoreGetDocs({
		collectionPath: `${COLLECTIONS.SITES}/${queryParams.siteId}/${COLLECTIONS.SENSORS_POSITION}`,
		params
	})

	return queryResult
}

export async function saveSensorsDraggedOnDatabase(queryParams) {
	if (
		!queryParams.siteId ||
		!queryParams.marker ||
		!queryParams.marker.markerOfPOIsId ||
		!queryParams.network_address ||
		!queryParams.wirepasFloorPlanId ||
		!queryParams.oldSensorDoc ||
		!queryParams.user
	) {
		return
	}

	const sensorsRef = doc(
		db,
		`Sites/${queryParams.siteId}/SensorsPosition/${queryParams.marker.markerOfPOIsId}`
	)

	// New data
	const updateData = {
		gpsData: queryParams.marker.gpsData || "",
		floorPlanId: queryParams.marker.floorPlanId || "",
		status: queryParams.marker.status || "",
		network_address: queryParams.network_address || "",
		wirepasFloorPlanId: queryParams.wirepasFloorPlanId || ""
	}

	const sensorsLogsRef = doc(collection(db, "SensorsPositionLogs")) // Log Collection

	// Log data
	const logData = {
		docId: queryParams.marker.markerOfPOIsId,
		previousData: queryParams.oldSensorDoc,
		updatedData: queryParams.marker,
		updated: {
			id: queryParams.user.id,
			email: queryParams.user.email,
			date: serverTimestamp()
		}
	}

	const updateSensorOperation = updateDoc(sensorsRef, updateData) // SensorsPosition Collection
	const updateOtherCollectionOperation = setDoc(sensorsLogsRef, logData) // SensorsPositionLogs Collection

	await Promise.all([updateSensorOperation, updateOtherCollectionOperation])
}

export async function getSensorToUpdate({ id, siteId }) {
	if (!siteId || !id) {
		return null
	}

	const sensorRef = doc(db, `Sites/${siteId}/SensorsPosition/${id?.toString()}`)
	const sensorDoc = await getDoc(sensorRef)

	return sensorDoc.exists() ? sensorDoc.data() : null
}

// Update sensor
export async function updateSensor({
	data,
	siteId,
	user,
	newImagesUrlsArray,
	oldSensorDoc,
	customerId
}) {
	if (!siteId || !data || !data.uuid || !user || !customerId) return

	const id = data.id.toString()

	const sensorsRef = doc(db, `Sites/${siteId}/SensorsPosition/${id}`)

	// New data
	const updateData = {
		uuid: Number(data.uuid),
		node_name: typeof data.uuid === "string" ? data.uuid : data.uuid.toString(),
		description: data.description || "",
		imagesUrls: newImagesUrlsArray,
		siteId: siteId,
		updated: {
			id: user.id,
			email: user.email,
			date: serverTimestamp()
		},
		status: data?.status,
		customerId: customerId
	}

	const sensorsLogsRef = doc(collection(db, "SensorsPositionLogs")) // Log Collection

	// Log data
	const logData = {
		docId: id,
		previousData: oldSensorDoc,
		updatedData: updateData,
		updated: {
			id: user.id,
			email: user.email,
			date: serverTimestamp()
		}
	}

	const updateSensorOperation = updateDoc(sensorsRef, updateData) // SensorsPosition Collection
	const updateOtherCollectionOperation = setDoc(sensorsLogsRef, logData) // SensorsPositionLogs Collection

	await Promise.all([updateSensorOperation, updateOtherCollectionOperation])
}

// Update active sensor
export async function updateActiveSensor({
	data,
	siteId,
	user,
	newImagesUrlsArray,
	oldSensorDoc,
	customerId,
	wirepasFloorPlanId
}) {
	if (!siteId || !data || !data.id || !user || !wirepasFloorPlanId) return

	const id = data.id.toString()

	const nodesRef = doc(db, `Sites/${siteId}/Nodes/${id}`)

	// New data
	const updateData = {
		...data,
		description: data?.description || "",
		imagesUrls: newImagesUrlsArray || [],
		status: data.status,
		updated: {
			id: user.id,
			email: user.email,
			date: serverTimestamp()
		},
		customerId: customerId,
		wirepasFloorPlanId: wirepasFloorPlanId
	}

	const sensorsLogsRef = doc(collection(db, "SensorsActiveLogs")) // Log Collection

	// Log data
	const logData = {
		docId: id,
		previousData: oldSensorDoc,
		updatedData: updateData,
		updated: {
			id: user.id,
			email: user.email,
			date: serverTimestamp()
		}
	}

	const updateActiveSensorOperation = updateDoc(nodesRef, updateData) // Nodes Collection
	const updateOtherCollectionOperation = setDoc(sensorsLogsRef, logData) // SensorsActiveLogs Collection

	await Promise.all([updateActiveSensorOperation, updateOtherCollectionOperation])
}

export async function unplaceSensorsFromDatabase(queryParams) {
	if (!queryParams || !queryParams.siteId || !queryParams.user || !queryParams.ids) {
		return null
	}

	const sensorsRef = collection(db, `Sites/${queryParams.siteId}/SensorsPosition`)
	const sensorsLogsRef = collection(db, "SensorsPositionLogs")

	const updateData = {
		approved: false,
		status: "assigned",
		floorPlanId: null,
		updated: {
			id: queryParams.user.id,
			email: queryParams.user.email,
			date: serverTimestamp()
		}
	}

	const batch = writeBatch(db)

	queryParams.ids.forEach(id => {
		const sensorDocRef = doc(sensorsRef, id)
		batch.update(sensorDocRef, updateData)

		const logData = {
			docId: id,
			updatedData: updateData,
			updated: {
				id: queryParams.user.id,
				email: queryParams.user.email,
				date: serverTimestamp()
			}
		}

		const logDocRef = doc(sensorsLogsRef)
		batch.set(logDocRef, logData)
	})

	await batch.commit()
}
