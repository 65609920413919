import React from "react"
import { Box, useMediaQuery, useTheme } from "@mui/material"
import { ROUTES } from "../_enums/inventory"
import { Header } from "../../../_partials/widgets/Header"
import { NavigationMenu } from "../../../_partials/widgets/NavigationMenu"

import "./Header.css"

const OPERATIONS = "Operations"
const OPERATIONS_ICON = "/media/svg/icons/General/operations-icon.svg"
const NODES_HEADER_ICON = "/media/svg/icons/General/Nodes.svg"
const MODELS_HEADER_ICON = "/media/svg/icons/General/Models.svg"
const FIRMWARE_HEADER_ICON = "/media/svg/icons/General/Firmware.svg"
const MODELS = "Models"
const FIRMWARE = "Firmware"
const NODES = "Nodes"

const menuItemsConfig = [
	{
		route: ROUTES.NODES_ROUTE,
		icon: NODES_HEADER_ICON,
		label: NODES
	},
	{
		route: ROUTES.MODELS_ROUTE,
		icon: MODELS_HEADER_ICON,
		label: MODELS
	},
	{
		route: ROUTES.FIRMWARES_ROUTE,
		icon: FIRMWARE_HEADER_ICON,
		label: FIRMWARE
	}
]

const validRoutes = [ROUTES.NODES_ROUTE, ROUTES.MODELS_ROUTE, ROUTES.FIRMWARES_ROUTE]

export function HeaderWithNavigationMenu() {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				mt: 2.5,
				mb: 4,
				width: "100%",
				padding: "0 15px"
			}}
		>
			<Header iconSrc={OPERATIONS_ICON} title={OPERATIONS} />
			<NavigationMenu
				defaultRoute={ROUTES.NODES_ROUTE}
				validRoutes={validRoutes}
				menuItemsConfig={menuItemsConfig}
			/>
		</Box>
	)
}
