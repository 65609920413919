import React from "react"
import { UIProvider } from "./UIContext"
import SensorsMainPage from "./SensorsMainPage"

export function SensorsLivePage() {
	return (
		<UIProvider>
			<SensorsMainPage />
		</UIProvider>
	)
}
