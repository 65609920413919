import React, { useState, useEffect } from "react"
import { Card, CardBody } from "../../../_partials/Card"
import Chart from "react-apexcharts"
import { generateMockData } from "../Helpers/Helpers"

const BarometricPressureChart = ({ chartOptions, sensorData, count }) => {
	const [chartState, setChartState] = useState({
		series: [{ name: "Barometric Pressure", data: [] }],
		options: {
			chart: {
				type: "area",
				height: 140,
				zoom: {
					type: "x",
					enabled: true,
					autoScaleYaxis: true
				},

				toolbar: {
					autoSelected: "zoom",
					show: true,
					tools: {
						download: false
					}
				}
			},
			dataLabels: {
				enabled: false
			},
			markers: {
				size: 0
			},

			fill: {
				type: "gradient",
				gradient: {
					shadeIntensity: 1,
					inverseColors: false,
					opacityFrom: 0.5,
					opacityTo: 0,
					stops: [0, 90, 100]
				}
			},
			yaxis: {
				labels: {
					formatter: function (val) {
						return val.toFixed(2) // Format as needed
					}
				}
			},
			xaxis: {
				type: "datetime",
				labels: {
					format: "HH:mm"
				}
			},
			tooltip: {
				shared: false,
				y: {
					formatter: function (val) {
						return val.toFixed(2) // Format as needed
					}
				}
			}
		}
	})

	useEffect(() => {
		let isMounted = true

		setTimeout(() => {
			if (isMounted) {
				// Get the last timestamp
				const lastTimestamp = sensorData[sensorData.length - 1]?.x

				// Calculate the start time of the last hour
				const oneHourAgo = lastTimestamp - 60 * 60 * 1000 // 1 hour in milliseconds

				// Filter data to include only the last hour
				const filteredData = sensorData.filter(d => d.x >= oneHourAgo)

				setChartState({
					series: [{ name: "Barometric Pressure", data: filteredData }],
					options: {
						...chartState.options,
						xaxis: {
							...chartState.options.xaxis,
							min: oneHourAgo, // Set min value of x-axis to start from last hour
							max: lastTimestamp // Set max value to the last timestamp
						}
					}
				})
			}
		}, 1000)

		return () => {
			isMounted = false
		}
	}, [sensorData])

	const titleBar = (
		<div className="title_bar_main_div">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<div className="floor_sensor_icon">
						<img src={chartOptions?.icon} alt="icon" style={{ width: "16px" }} />
					</div>
					<div>
						<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
							{chartOptions?.name || "Barometric Pressure"}
						</span>
					</div>
				</div>
				<div className="floor_sensor_icon_count">
					<span
						style={{
							color: "#8c8ca1",
							fontSize: "13px",
							fontWeight: 700
						}}
					>
						{count}
					</span>
				</div>
			</div>
		</div>
	)

	return (
		<Card>
			{titleBar}
			<CardBody style={{ padding: "0px" }}>
				<Chart
					options={chartState.options}
					series={chartState.series}
					type="area"
					height={140}
				/>
			</CardBody>
		</Card>
	)
}

export default BarometricPressureChart
