import React, { useRef, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { FaArrowLeft } from "react-icons/fa6"
import {
	addGeoJsonLayerToMap,
	addImageLayerToMap,
	addSingleSensorToMap
} from "../../Helpers/Helpers"

const MapWidgetDetail = ({
	sensor,
	currentFloorPlan,
	selectedCustomer,
	selectedSite,
	sensors,
	sensorsMeasurements
}) => {
	const mapcontainerref = useRef()
	const history = useHistory()

	// ━━━━━━━━ States ━━━━━━━━
	const [map, setMap] = useState(null)
	const [sensorMarkers, setSensorMarkers] = useState([])

	// ━━━━━━━━ Map ━━━━━━━━
	useEffect(() => {
		let isMounted = true

		const map = new mapboxgl.Map({
			container: mapcontainerref.current,
			style: "mapbox://styles/mapbox/streets-v11"
		})

		map.on("load", function (e) {
			// // Map controls
			// map.addControl(
			// 	new mapboxgl.NavigationControl({
			// 		showCompass: false
			// 	}),
			// 	"top-left"
			// )
			// map.addControl(new MapDimensions(), "bottom-left")

			if (isMounted) {
				setMap(map)
			}
		})

		// disable zoom when doubleClick map
		map.doubleClickZoom.disable()

		return () => {
			if (!map) return
			isMounted = false
			setMap(prevMap => {
				if (prevMap) {
					prevMap.remove()
				}
				return null
			})
		}
	}, [])

	// ━━━━━━━━━━━ FloorPlan Image on Map ━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !currentFloorPlan) return

		addImageLayerToMap({
			map,
			floorPlan: currentFloorPlan
			// mapStyleChanged,
			// newStyleLayer
		})
		// }
	}, [
		map,
		currentFloorPlan
		//  mapStyleChanged, newStyleLayer
	])

	// ━━━━━━━━━━━━ GeoJson Areas ━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !currentFloorPlan) return

		addGeoJsonLayerToMap({
			map,
			geoJson: currentFloorPlan?.geoJson,
			layerTypes: selectedCustomer?.layerTypes
		})
	}, [
		map,
		currentFloorPlan,
		selectedCustomer
		//  mapStyleChanged
	])

	// ━━━━━━━━━━━ Sensor markers ━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !selectedSite || !currentFloorPlan) return

		const layers = map.getStyle()

		if (layers) {
			addSingleSensorToMap({
				// dispatch,
				map,
				selectedCustomer,
				selectedSite,
				currentFloorPlan,
				sensor,
				sensors,
				sensorsMeasurements,
				sensorMarkers,
				setSensorMarkers
			})
		}
	}, [map, selectedSite, currentFloorPlan, sensor, sensors, sensorsMeasurements, selectedCustomer])

	return (
		<div
			style={{
				boxShadow: " 0px 0px 6px 2px rgba(28, 28, 59, 0.10)",
				borderRadius: "5px"
			}}
		>
			<div
				className="d-flex align-items-center pr-3"
				style={{
					minHeight: "50px",
					backgroundColor: "#FFF",
					borderRadius: "5px"
				}}
			>
				<div
					className="d-flex justify-content-center align-items-center fullscreen_sensor ml-4 mr-4"
					onClick={() => history.push(`/sensors`)}
				>
					<FaArrowLeft color="#2673F0" />
				</div>
				<div className="mr-3 d-flex justify-content-center align-items-center">
					<span
						style={{
							fontSize: "15px",
							fontWeight: 600,
							color: "#0A1B3D"
						}}
					>
						Positioning on Map
					</span>
				</div>
			</div>
			<div style={{ height: "330px" }}>
				<div
					ref={mapcontainerref}
					style={{
						height: "100%",
						borderRadius: "0px 0px 5px 5px"
					}}
				/>
			</div>
		</div>
	)
}

export default MapWidgetDetail
