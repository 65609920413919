import React, { useState, useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import Chart from "react-apexcharts"
import { ModalProgressBar } from "../../../_partials/ModalProgressBar"

const ActiveSensorsChart = ({ isLoading }) => {
	const { selectedSite, sensors } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			sensors: state.liveData?.sensors || {}
		}),
		shallowEqual
	)

	const [graphData, setGraphData] = useState({
		labels: [],
		series: []
	})

	const aggregateSensorData = sensors => {
		return Object.values(sensors)
			.flat()
			.reduce(
				(acc, sensor) => {
					const measurement = sensor?.measurement

					acc.series[0] += measurement?.temperature || 0
					acc.series[1] += measurement?.humidity || 0
					acc.series[2] += measurement?.pressure || 0
					acc.series[3] += measurement?.luminosity || 0
					acc.series[4] += measurement?.co2 || 0

					return acc
				},
				{
					labels: ["Temperature", "Humidity", "Pressure", "Luminosity", "CO2"],
					series: [0, 0, 0, 0, 0]
				}
			)
	}

	useEffect(() => {
		if (!selectedSite || Object.keys(sensors).length === 0) return

		const aggregatedData = aggregateSensorData(sensors)

		const scalingFactors = [1, 1, 0.01, 1, 1]
		const scaledSeries = aggregatedData.series.map(
			(value, index) => value * scalingFactors[index]
		)

		const total = scaledSeries.reduce((sum, value) => sum + value, 0)

		// Normalize and set the data
		setGraphData({
			labels: aggregatedData.labels,
			series:
				total > 0
					? scaledSeries.map(value => Math.round((value / total) * 100)) // Normalize to percentage
					: [0, 0, 0, 0, 0]
		})
	}, [selectedSite, sensors])

	return (
		<div
			className="chart-container w-100 h-100 d-flex justify-content-center align-items-center"
			style={{
				borderRadius: "5px",
				boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.10)"
			}}
		>
			{sensors && (
				<div className="w-100 h-100">
					<div style={{ minHeight: "7px" }}>
						{isLoading && <ModalProgressBar variant="query" />}
					</div>
					<div className="mt-2">
						<Chart
							options={getChartOptions(graphData, isLoading, sensors)}
							series={graphData?.series || []}
							type="donut"
							height="100%"
						/>
					</div>
				</div>
			)}
		</div>
	)
}

export default ActiveSensorsChart

function getChartOptions(graphData, isLoading, sensors) {
	return {
		chart: {
			type: "donut",
			toolbar: {
				show: true,
				tools: {
					download: false,
					selection: false,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: false,
					customIcons: []
				}
			}
		},
		noData: {
			text: isLoading ? "Loading data..." : "No data to display."
		},
		labels: graphData?.labels,
		series: graphData?.series,
		fill: {
			colors: [
				"#0a1b3d",
				"#0C48AA",
				"#1065EF",
				"#5591F3",
				"#7aa9f5",
				"#a0c1f8",
				"#c5dafa",
				"#dbe7fc",
				"#e6eefc"
			]
		},
		plotOptions: {
			pie: {
				donut: {
					labels: {
						show: true,
						value: {
							fontSize: "12px",
							fontWeight: 500,
							color: "black",
							offsetY: 2
						},
						total: {
							show: true,
							label: "Active Sensors",
							color: "black",
							fontSize: "9px",
							fontWeight: 500,
							formatter: () => sensors?.length || 0
						}
					}
				}
			}
		},
		responsive: [
			{
				breakpoint: 900,
				options: {
					chart: {
						width: "100%",
						height: "100%"
					}
				}
			}
		]
	}
}
