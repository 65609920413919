import * as requestFromServer from "./DesignStudioCrud"
import { DesignStudioSlice, callTypes } from "./DesignStudioSlice"
import { profileSlice } from "../../../../redux/profile/profileSlice"
import { convertCollectionSnapshotToMap } from "../../_helpers/ActionHelpers"
import { firestore as db } from "../../../../../firebase"
import firebase from "firebase/compat/app"
import * as turf from "@turf/turf"
import { collection, doc, setDoc, updateDoc, GeoPoint } from "firebase/firestore"
import { ERRORS } from "../../../../_enums/errors"
const { actions } = DesignStudioSlice

export const updateUrlsFromDB = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	// console.log(queryParams, "params actions");

	const promises = []
	var features = queryParams.data.map(val => {
		const coordinates = val.geoJson.geometry.coordinates[0].map(v => {
			return new firebase.firestore.GeoPoint(v[1], v[0])
		})
		var zoneId = null

		zoneId = val.geoJson.properties.zoneId
		const zoneIdFromDB = queryParams.zoneToUpdateUrls[0].id

		return {
			...val.geoJson,
			properties: {
				name: val.name,
				type: val.type,
				capacity: val.capacity,
				zoneId: zoneId,
				zoneImagesUrls:
					zoneId === zoneIdFromDB
						? queryParams.newImagesUrls
						: val.geoJson.properties.zoneImagesUrls || null
			},
			geometry: {
				type: val.geoJson.geometry.type,
				coordinates
			}
		}
	})

	var newParams = {
		...queryParams,
		geoJson: {
			type: "FeatureCollection",
			features: features
		},
		promises
	}

	return requestFromServer
		.updateUrls(newParams)
		.then(response => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: {
					message: "Image deleted successfully"
				}
			})
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't delete image"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Error uploading images"
				}
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const getUrlsFromDB = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.zonesInDBFetched({ entities: [] }))
	}

	return requestFromServer
		.getZonesInDB(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			// const zones = convertCollectionSnapshotToMap(response)
			const zones = response
			// console.log("🚀 ~ zones", zones);

			dispatch(actions.zonesInDBFetched({ entities: zones }))
		})
		.catch(error => {
			console.log("fetch zones from DB ", error)
			error.clientMessage = "Can't find  zones in DB"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const saveUrlsToDB = queryParams => dispatch => {
	// dispatch(actions.startCall({ callType: callTypes.action }));

	const zoneFetched = queryParams.zonesFetchedFromDB.find(
		zone => zone.id === queryParams.row.geoJson.properties.zoneId
	)

	const oldImages = zoneFetched.imagesUrls ? zoneFetched.imagesUrls : []

	let imagesSummed = [...oldImages]

	queryParams.result.forEach(res => {
		imagesSummed.push(res)
	})

	const promises = []
	var features = queryParams.data.map(val => {
		const coordinates = val.geoJson.geometry.coordinates[0].map(v => {
			return new firebase.firestore.GeoPoint(v[1], v[0])
		})
		var zoneId = null

		zoneId = val.geoJson.properties.zoneId

		return {
			...val.geoJson,
			properties: {
				name: val.name,
				type: val.type,
				capacity: val.capacity,
				zoneId: zoneId,
				zoneImagesUrls:
					zoneId === zoneFetched.id
						? imagesSummed
						: val.geoJson.properties.zoneImagesUrls || null
			},
			geometry: {
				type: val.geoJson.geometry.type,
				coordinates
			}
		}
	})

	var newParams = {
		...queryParams,
		geoJson: {
			type: "FeatureCollection",
			features: features
		},
		promises
	}

	return requestFromServer
		.saveUrls(newParams)
		.then(response => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: {
					message: "Images uploaded successfully"
				}
			})
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't updload images"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Error uploading images"
				}
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const saveAreasAndGeoJson = queryParams => async dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	const promises = []

	const features = queryParams.data.map(val => {
		const coordinates = val.geoJson.geometry.coordinates[0].map(v => {
			return new GeoPoint(v[1], v[0])
		})

		let areaId = null

		// Info and Door Markers
		const point1 = turf.point(val.geoJson.geometry.coordinates[0][0])
		const point2 = turf.point(val.geoJson.geometry.coordinates[0][1])
		const midpoint = turf.midpoint(point1, point2)
		const midPointLng = midpoint.geometry.coordinates[0]
		const midPointLat = midpoint.geometry.coordinates[1]

		const poly2 = turf.polygon(val.geoJson.geometry.coordinates)
		const pointOnPolygon = turf.pointOnFeature(poly2)
		const pointOnPolygonLng = pointOnPolygon.geometry.coordinates[0]
		const pointOnPolygonLat = pointOnPolygon.geometry.coordinates[1]

		const fetchZoneWithMarkerCoords =
			queryParams.zonesFetchedFromDB &&
			queryParams.zonesFetchedFromDB.find(area => area.id === val.geoJson.properties.areaId)

		const fetchedLngDoor = fetchZoneWithMarkerCoords?.doorMarker?.longitude
		const fetchedLatDoor = fetchZoneWithMarkerCoords?.doorMarker?.latitude
		const conditionalLngDoor = fetchedLngDoor ? fetchedLngDoor : midPointLng
		const conditionalLatDoor = fetchedLatDoor ? fetchedLatDoor : midPointLat

		const fetchedLng = fetchZoneWithMarkerCoords?.marker?.longitude
		const fetchedLat = fetchZoneWithMarkerCoords?.marker?.latitude
		const conditionalLng = fetchedLng ? fetchedLng : pointOnPolygonLng
		const conditionalLat = fetchedLat ? fetchedLat : pointOnPolygonLat

		const value = {
			color: val.color,
			name: val.name,
			type: val.type,
			capacity: val.capacity,
			polygon: coordinates,
			floorPlanId: queryParams.floorPlanId,
			networkId: queryParams.networkId,
			marker: new GeoPoint(conditionalLat, conditionalLng),
			doorMarker: new GeoPoint(conditionalLatDoor, conditionalLngDoor),
			visible: val.visible,
			geoFencing: val.geoFencing,
			altitude: queryParams.floorPlan?.coords?.bottomLeftCorner?.altitude
				? queryParams.floorPlan?.coords?.bottomLeftCorner?.altitude
				: 0
		}

		if (val.type === "baseLayer") {
			// Do nothing for baseLayer type
		} else if (val.geoJson.properties.areaId) {
			const ref = doc(db, "Sites", queryParams.networkId, "Areas", val.geoJson.properties.areaId)
			areaId = val.geoJson.properties.areaId

			promises.push(updateDoc(ref, value))
		} else {
			const ref = doc(collection(db, "Sites", queryParams.networkId, "Areas"))
			areaId = ref.id
			promises.push(setDoc(ref, value))
		}

		return {
			...val.geoJson,
			properties: {
				name: val.name,
				type: val.type,
				capacity: Number(val.capacity),
				areaId: areaId,
				zoneImagesUrls: val.geoJson.properties?.zoneImagesUrls
					? val.geoJson.properties?.zoneImagesUrls
					: [],
				visible: val.visible,
				geoFencing: val.geoFencing,
				altitude: queryParams.floorPlan?.coords?.bottomLeftCorner?.altitude
					? queryParams.floorPlan?.coords?.bottomLeftCorner?.altitude
					: 0
			},
			geometry: {
				type: val.geoJson.geometry.type,
				coordinates
			}
		}
	})

	const newParams = {
		...queryParams,
		geoJson: {
			type: "FeatureCollection",
			features: features
		},
		promises
	}

	try {
		await requestFromServer.saveGeoJson(newParams)
		dispatch({
			type: "SNACKBAR_SUCCESS",
			payload: { message: `Saved successfully` }
		})
	} catch (error) {
		console.log("Error: ", error)
		error.clientMessage = "Can't save"
		dispatch({
			type: "SNACKBAR_ERROR",
			payload: { message: `Error saving` }
		})
		dispatch(actions.catchError({ error, callType: callTypes.action }))
	}
}

export const saveLayerTypes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	// console.log(queryParams, "queryParams saveLayerTypes nas Actions");

	return requestFromServer
		.saveLayerTypes(queryParams)
		.then(response => {
			dispatch(profileSlice.actions.layerTypesFetched(queryParams.layerTypes))

			// doesn't dispatch success message when creating default layer type
			if (queryParams.layerTypes.length !== 1) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: {
						message: "Area types saved successfully"
					}
				})
			}
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Error saving area types"
				}
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const deleteLayerTypes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.deleteLayerTypes(queryParams)
		.then(response => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: {
					message: "Area type delete successfully"
				}
			})
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't delete"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Error deleting area types"
				}
			})

			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const updateLayerTypes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	// console.log(queryParams, "queryParams updateLayerTypes nas Actions");

	return requestFromServer
		.updateLayerTypes(queryParams)
		.then(response => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: {
					message: "Area types updated successfully"
				}
			})
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't update"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Error updating area types"
				}
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const saveZoneMarkers = queryParams => dispatch => {
	// dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.saveMarkersFromZones(queryParams)
		.then(response => {})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save marker coordinates"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Error saving marker coordinates"
				}
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const saveZoneDoorMarkers = queryParams => dispatch => {
	// dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.saveDoorMarkersFromZones(queryParams)
		.then(response => {})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save marker coordinates"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Error saving marker coordinates."
				}
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const deleteZonesFromDB = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	// console.log(queryParams, "params actions");

	const filteredData = queryParams.data.filter(val => val.id !== queryParams.zoneToDelete.id)

	var features = filteredData.map(val => {
		const coordinates = val.geoJson.geometry.coordinates[0].map(v => {
			return new firebase.firestore.GeoPoint(v[1], v[0])
		})

		return {
			...val.geoJson,
			properties: {
				name: val.name,
				type: val.type,
				capacity: val.capacity,
				areaId: val.geoJson.properties.areaId,
				zoneImagesUrls: val.geoJson.properties.zoneImagesUrls || null
			},
			geometry: {
				type: val.geoJson.geometry.type,
				coordinates
			}
		}
	})

	// console.log(filteredData, "filteredData");
	// console.log(features, "features");

	var newParams = {
		...queryParams,
		geoJson: {
			type: "FeatureCollection",
			features: features
		}
	}

	return requestFromServer
		.deleteZones(newParams)
		.then(response => {})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't delete"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Error deleting areas"
				}
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

//★━━━━━━━━━★ Points of Interest ACTIONS ★━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const createPointOfInterest = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.createPointOfInterest(queryParams)
		.then(res => {
			dispatch(
				actions.poiCreated({
					name: queryParams.data.name,
					description: queryParams.data.description,
					type: queryParams.data.type,
					imagesUrls: queryParams.imagesUrls,
					siteId: queryParams.siteId,
					created: {
						id: queryParams.user.id,
						email: queryParams.user.email,
						date: firebase.firestore.FieldValue.serverTimestamp()
					},
					floorPlanId: queryParams.floorPlanId,
					coords: queryParams.coords,
					markerVisible: queryParams.markerVisible,
					id: res || ""
				})
			)
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: {
					message: "Point of Interest created"
				}
			})
			return res
		})
		.catch(error => {
			error.clientMessage = "Can't create Point of Interest"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const updatePointOfInterest = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updatePointOfInterest(queryParams)
		.then(res => {
			const updatedPOI = {
				...queryParams.data,
				name: queryParams.data.name,
				description: queryParams.data.description,
				type: queryParams.data.type,
				imagesUrls: queryParams.newImagesUrlsArray,
				siteId: queryParams.siteId,
				updated: {
					id: queryParams.user.id,
					email: queryParams.user.email,
					date: firebase.firestore.FieldValue.serverTimestamp()
				},
				markerVisible: queryParams.data.markerVisible
			}

			dispatch(actions.poiEdited(updatedPOI))

			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: {
					message: "Point of Interest updated"
				}
			})
			return res
		})
		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Point of Interest"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const savePointsOfInterest = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.savePointsOfInterest(queryParams)
		.then(() => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: {
					message: "Point of Interest saved"
				}
			})
		})
		.catch(error => {
			error.clientMessage = "Can't save Points of Interest"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const savePOIsSingleMarkers = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.saveMarkersFromPOIs(queryParams)
		.then(response => {
			const updatedPOI = {
				...queryParams.marker,
				coords: queryParams.marker.coords || "",
				floorPlanId: queryParams.marker.floorPlanId || ""
			}

			dispatch(actions.poiEdited(updatedPOI))
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save marker coordinates"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Error saving marker coordinates"
				}
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchPointsOfInterest = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.pointsOfInterestFetched({ entities: [] }))
	}

	return requestFromServer
		.getPointsOfInterest(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			// const pointsOfInterest = convertCollectionSnapshotToMap(response)
			const pointsOfInterest = response

			dispatch(actions.pointsOfInterestFetched({ entities: pointsOfInterest }))
		})
		.catch(error => {
			console.log("fetch points of interest ", error)
			error.clientMessage = "Can't find points of interest"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const deletePointOfInterest = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.deletePointOfInterest(queryParams)
		.then(response => {
			dispatch(actions.pointOfInterestDeleted({ id: queryParams.id }))

			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: { message: `Point of Interest ${queryParams.name} deleted` }
			})
		})
		.catch(error => {
			error.clientMessage = "Can't delete product"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchToUpdatePointOfInterestAction = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	if (!queryParams) {
		dispatch(actions.pointOfInterestFetchedToUpdate({ entities: [] }))
	}

	return requestFromServer
		.getPointOfInterestToUpdate(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			// const pointOfInterestResponse = response.data()
			const pointOfInterestResponse = response
			const pointOfInterest = {
				...pointOfInterestResponse,
				id: queryParams.id
			}

			dispatch(
				actions.pointOfInterestFetchedToUpdate({
					entities: pointOfInterest
				})
			)
		})
		.catch(error => {
			console.log("can't fetch point of interest to update ", error)
			error.clientMessage = "Can't find point of interest to update"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// ━━━━━━━━━━━━━━━━━━━━━━━━ Anchors Position ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\

export const fetchAnchorsPosition = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.anchorsPositioningFetched({ entities: [] }))
	}

	return requestFromServer
		.getAnchorsCollection(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const anchorsFromDatabase = response

			dispatch(actions.anchorsPositioningFetched({ entities: anchorsFromDatabase }))
		})
		.catch(error => {
			console.log("fetch anchors", error)
			error.clientMessage = "Can't find anchors position"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const fetchToUpdateAnchorAction = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	if (!queryParams) {
		dispatch(actions.anchorPositionFetchedToUpdate({ entities: [] }))
	}

	return requestFromServer
		.getAnchorToUpdate(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const newAnchorsData = {
				...response,
				id: queryParams.uuid
			}

			dispatch(
				actions.anchorPositionFetchedToUpdate({
					entities: newAnchorsData
				})
			)
		})
		.catch(error => {
			console.log("can't fetch Anchors to update ", error)
			error.clientMessage = "Can't find Anchors to update"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// update anchor
export const updateAnchorPosition = queryParams => dispatch => {
	// console.log("🚀 ~ updateAnchorPosition ~ queryParams:", queryParams)
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateAnchor(queryParams)
		.then(res => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: {
					message: "Anchor updates"
				}
			})
			return res
		})
		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Anchor"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

// update active anchor
export const updateActiveAnchorPosition = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateActiveAnchor(queryParams)
		.then(res => {
			// console.log("🚀 ~ updateActiveAnchorPosition ~ res:", res)

			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: {
					message: "Active anchor successfully updated"
				}
			})
			return res
		})
		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Active anchor."
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const deleteSeveralAnchors = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.deleteSeveralAnchorsFromDatabase(queryParams)
		.then(response => {
			queryParams &&
				queryParams.selectedData.forEach(val => {
					dispatch(actions.anchorDeleted({ id: val.id }))
				})
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Anchors successfully deleted"
				}
			})
		})
		.catch(error => {
			error.clientMessage = "Can't delete several anchors"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const unplaceAnchors = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.unplaceAnchorsFromDatabase(queryParams)
		.then(response => {
			dispatch(actions.anchorsUnplaced({ ids: queryParams.ids }))

			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: { message: `Unplaced successfully.` }
			})
			// console.log("🚀 ~ unplaceAnchors ~ response:", response)
		})
		.catch(error => {
			error.clientMessage = "Can't delete several anchors"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const updateAnchor = queryParams => dispatch => {
	// console.log("🚀 ~ updateAnchor ~ queryParams:", queryParams)
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateAnchorStatus(queryParams)
		.then(res => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: { message: `Anchor updated` }
			})
			return res
		})
		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Anchor"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const approveNodes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.approveNodes(queryParams)
		.then(response => {
			if (!response) throw Error("Can't make a request for server")
			return response.json()
		})

		.catch(error => {
			console.log("Get Nodes: ", error)
			error.clientMessage = "Can't get nodes"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: { message: `Anchor not updated. Please try again later.` }
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
			return error
		})
}

export const updateApprovedNodes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateApprovedNodes(queryParams)
		.then(response => {
			if (!response) throw Error(ERRORS.SERVER_ERRORS.INTERNAL_SERVER)
			return response.json()
		})
		.then(result => {
			dispatch({
				type: ERRORS.TYPES.SNACKBAR_SUCCESS,
				payload: { message: result }
			})
			return result
		})
		.catch(error => {
			dispatch({
				type: ERRORS.TYPES.SNACKBAR_ERROR,
				payload: { message: ERRORS.UPDATING_ERRORS.ACTIVE_ANCHOR_DATA }
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
			return error
		})
}

// ━━━━━━━━━━━━━━━━━━━━━━━━ Sensors Position ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\

export const fetchSensorsPosition = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.sensorsPositioningFetched({ entities: [] }))
	}

	return requestFromServer
		.getSensorsCollection(queryParams)
		.then(response => {
			if (!response) {
				throw Error(ERRORS.SERVER_ERRORS.INTERNAL_SERVER)
			}

			const sensorsFromDatabase = convertCollectionSnapshotToMap(response)

			dispatch(actions.sensorsPositioningFetched({ entities: sensorsFromDatabase }))
		})
		.catch(error => {
			error.clientMessage = ERRORS.FETCH_ERRORS.SENSORS_DATA
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const saveSensorSingleMarkers = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.saveSensorsDraggedOnDatabase(queryParams)
		.then(response => {})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save sensor marker coordinates"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: { message: `Error saving sensor marker coordinates.` }
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchToUpdateSensorAction = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	if (!queryParams) {
		dispatch(actions.sensorPositionFetchedToUpdate({ entities: [] }))
	}

	return requestFromServer
		.getSensorToUpdate(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const sensorsResponse = response
			const newSensorsData = {
				...sensorsResponse,
				// id: queryParams.id
				id: queryParams.uuid
			}

			dispatch(
				actions.sensorPositionFetchedToUpdate({
					entities: newSensorsData
				})
			)
		})
		.catch(error => {
			console.log("can't fetch Sensors to update ", error)
			error.clientMessage = "Can't find Sensors to update"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// update sensor
export const updateSensorPosition = queryParams => dispatch => {
	// console.log("🚀 ~ updateSensorPosition ~ queryParams:", queryParams)
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateSensor(queryParams)
		.then(res => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: { message: `Sensor updated` }
			})
			return res
		})
		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Sensor"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

// update active sensor
export const updateActiveSensorPosition = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateActiveSensor(queryParams)
		.then(res => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: { message: `Active sensor successfully updated.` }
			})
			return res
		})
		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Active sensor."
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const unplaceSensors = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.unplaceSensorsFromDatabase(queryParams)
		.then(response => {
			dispatch(actions.sensorsUnplaced({ ids: queryParams.ids }))

			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: { message: `Unplaced successfully.` }
			})
		})
		.catch(error => {
			error.clientMessage = "Can't delete several sensors"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}
