import React, { useState, forwardRef } from "react"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { useStyles } from "./StylesOverride"
import { TextField, InputAdornment } from "@material-ui/core"
import { MdOutlineCalendarToday } from "react-icons/md"

export const DateRangePicker = ({ dateRange, setDateRange, ...props }) => {
	const [startDate, endDate] = dateRange

	return (
		<DatePicker
			selectsRange={true}
			startDate={startDate}
			endDate={endDate}
			onChange={update => {
				setDateRange(update)
			}}
			isClearable={true}
			popperPlacement="top-end"
			customInput={<ExampleCustomInput />}
			{...props}
		/>
	)
}

// eslint-disable-next-line react/display-name
const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
	const classes = useStyles()

	return (
		<div className="row" style={{ margin: "auto" }} onClick={onClick} ref={ref}>
			<TextField
				className={classes.datePicker}
				fullWidth
				value={value}
				style={{
					backgroundColor: "#f4f4f4",
					justifyContent: "center",
					padding: "16px 12px",
					cursor: "pointer"
				}}
				InputProps={{
					readOnly: true,
					disableUnderline: true,
					endAdornment: (
						<InputAdornment position="end">
							<MdOutlineCalendarToday
								style={{ fontSize: "1.4rem", marginRight: "10px", color: "#8C8CA1" }}
							/>
						</InputAdornment>
					)
				}}
			/>
		</div>
	)
})
