import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles, ButtonGroup, Typography, Box, SvgIcon, Button } from "@material-ui/core"

const TabsSensors = ({ sensorsPage, setSensorsPage }) => {
	const classes = useStyles()

	const [secondPage, setSecondPage] = useState(0)
	const [newPageName, setNewPageName] = useState({ first: null, second: null, onChange: false })

	useEffect(() => {
		if (newPageName.onChange) {
			if (newPageName?.second === "live") {
				setSensorsPage("live")
			}
			if (newPageName?.second === "history") {
				setSensorsPage("history")
			}
		}
	}, [newPageName])

	useEffect(() => {
		if (sensorsPage.includes("live")) {
			setSecondPage(0)
			setNewPageName(prevState => ({
				...prevState,
				second: "live",
				onChange: false
			}))
		}
		if (sensorsPage.includes("history")) {
			setSecondPage(1)
			setNewPageName(prevState => ({
				...prevState,
				second: "history",
				onChange: false
			}))
		}
	}, [sensorsPage])

	const variantActiveColor = index => (secondPage === index ? "contained" : "outlined")
	const textActiveColor = index => (secondPage === index ? "#FFFFFF" : "#8C8CA1")

	return (
		<div className="col-6 p-0 d-none d-sm-flex justify-content-end">
			<div className="d-flex justify-content-end" style={{ margin: "2rem 0 1rem 0" }}>
				<ButtonGroup>
					<Button
						classes={{ outlinedPrimary: classes.outlinedPrimary }}
						onClick={() => {
							setSecondPage(0)
							setNewPageName({
								...newPageName,
								second: "live",
								onChange: true
							})
						}}
						variant={variantActiveColor(0)}
						color="primary"
						startIcon={<TimesheetIcon1 textActiveColor={() => textActiveColor(0)} />}
						style={{
							fontSize: "14px",
							fontWeight: 700,
							color: textActiveColor(0)
						}}
					>
						REAL TIME
					</Button>
					<Button
						classes={{ outlinedPrimary: classes.outlinedPrimary }}
						onClick={() => {
							setSecondPage(1)
							setNewPageName({
								...newPageName,
								second: "history",
								onChange: true
							})
						}}
						variant={variantActiveColor(1)}
						color="primary"
						startIcon={<TimesheetIcon2 textActiveColor={() => textActiveColor(1)} />}
						style={{
							fontSize: "14px",
							fontWeight: 700,
							color: textActiveColor(1)
						}}
					>
						HISTORY
					</Button>
				</ButtonGroup>
			</div>
		</div>
	)
}

export default TabsSensors

const TimesheetIcon1 = ({ textActiveColor }) => {
	return (
		<SvgIcon>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="22"
				height="22"
				viewBox="0 0 22 22"
				fill="none"
			>
				<path
					d="M9.5895 7.33665C9.60954 7.07615 9.82734 6.875 10.0886 6.875C10.3455 6.875 10.5611 7.06965 10.5867 7.32525L11 11.4583L13.9639 13.152C14.1151 13.2384 14.2083 13.3991 14.2083 13.5732C14.2083 13.8931 13.9042 14.1254 13.5956 14.0412L9.56534 12.9421C9.33396 12.879 9.17998 12.6605 9.19837 12.4213L9.5895 7.33665Z"
					fill={textActiveColor(0)}
				/>
				<path
					d="M5.73749 1.36349C5.47338 1.04874 4.96344 1.16922 4.86811 1.56888L3.86585 5.77076C3.78817 6.09641 4.04624 6.40491 4.38049 6.38597L8.70302 6.14102C9.11392 6.11773 9.3223 5.6357 9.05775 5.32043L8.01965 4.08327C8.82049 3.80961 9.66969 3.66668 10.5417 3.66668C14.8449 3.66668 18.3333 7.15513 18.3333 11.4583C18.3333 15.7616 14.8449 19.25 10.5417 19.25C6.23844 19.25 2.74999 15.7616 2.74999 11.4583C2.74999 10.7359 2.84782 10.0284 3.03857 9.34837L1.27336 8.85323C1.04095 9.68178 0.916656 10.5555 0.916656 11.4583C0.916656 16.7741 5.22592 21.0833 10.5417 21.0833C15.8574 21.0833 20.1667 16.7741 20.1667 11.4583C20.1667 6.1426 15.8574 1.83334 10.5417 1.83334C9.20456 1.83334 7.93114 2.10599 6.77397 2.59872L5.73749 1.36349Z"
					fill={textActiveColor(0)}
				/>
			</svg>
		</SvgIcon>
	)
}

const TimesheetIcon2 = ({ textActiveColor }) => (
	<SvgIcon>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="23"
			height="22"
			viewBox="0 0 23 22"
			fill="none"
		>
			<path
				d="M15.625 3.4375H17.6875C18.0522 3.4375 18.4019 3.58237 18.6598 3.84023C18.9176 4.09809 19.0625 4.44783 19.0625 4.8125V19.25C19.0625 19.6147 18.9176 19.9644 18.6598 20.2223C18.4019 20.4801 18.0522 20.625 17.6875 20.625H5.3125C4.94783 20.625 4.59809 20.4801 4.34023 20.2223C4.08237 19.9644 3.9375 19.6147 3.9375 19.25V4.8125C3.9375 4.44783 4.08237 4.09809 4.34023 3.84023C4.59809 3.58237 4.94783 3.4375 5.3125 3.4375H7.375V2.75C7.375 2.38533 7.51987 2.03559 7.77773 1.77773C8.03559 1.51987 8.38533 1.375 8.75 1.375H14.25C14.6147 1.375 14.9644 1.51987 15.2223 1.77773C15.4801 2.03559 15.625 2.38533 15.625 2.75V3.4375ZM14.25 2.75H8.75V5.5H14.25V2.75ZM5.3125 19.25H17.6875V4.8125H15.625V6.875H7.375V4.8125H5.3125V19.25ZM8.75 9.625H7.375V16.5H8.75V9.625ZM14.25 12.375H15.625V16.5H14.25V12.375ZM10.8125 13.75H12.1875V16.5H10.8125V13.75Z"
				fill={textActiveColor(1)}
			/>
		</svg>
	</SvgIcon>
)

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: "100%",
		backgroundColor: theme.palette.background.paper
	},
	outlinedPrimary: {
		borderColor: "#8C8CA1",
		"&:hover": {
			borderColor: "#505063"
		}
	}
}))
