import React from "react"
import { HeaderWithNavigationMenu } from "../../header/Header"
import "../Inventory.css"

const InventoryHeader = () => {
	return (
		<>
			<div
				className="row align-items-center justify-content-between mb-4 w-100"
				data-testid="InventoryHeader"
			>
				<HeaderWithNavigationMenu />
			</div>
		</>
	)
}

export default InventoryHeader
