import React, { useEffect, useState } from "react"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import Chart from "react-apexcharts"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"

import { fetchAllCustomerNodes } from "../../../_redux/liveData/liveDataActions"

const areaLocationTimeSpent = {
	labels: [
		"teste2",
		"First Top Right North",
		"First Floor",
		"Geofencing test 16",
		"new field ",
		"teste"
	],
	series: [2950, 12178, 40869, 40858, 12219, 1823],
	buildingsName: [
		"School building A",
		"School building A",
		"School building A",
		"School building A",
		"School building A",
		"School building A"
	],
	floors: [
		"First floor",
		"First floor",
		"First floor",
		"First floor",
		"First floor",
		"First floor"
	],
	siteName: "Baltimore"
}

const colors = ["#0F2A5E", "#0c48aa", "#004ECC", "#1065ef", "#2673F0", "#6CA4FF"]

const NoSelectedSiteHeader = () => {
	const dispatch = useDispatch()

	// useSelector
	const { listLoading, customerId, customerNodes, user } = useSelector(
		state => ({
			listLoading: state.liveData?.listLoading,
			user: state.auth?.user,
			customerId: state.profile?.currentCustomer?.id,
			customerNodes: state.liveData?.customerNodes
		}),
		shallowEqual
	)

	//useEffect
	useEffect(() => {
		if (!customerId || !user) return

		dispatch(
			fetchAllCustomerNodes({
				customerId,
				callersUserId: user.id,
				callersTenantId: user.tenantId
			})
		)
	}, [customerId, user])

	// States
	const [graphData, setGraphData] = useState({
		labels: [],
		series: []
	})
	const [noDataMessage, setNoDataMessage] = useState("")

	// useEffect
	useEffect(() => {
		// console.log("🚀 ~ NoSelectedSiteHeader ~ customerNodes:", customerNodes)

		// if (customerNodes && customerNodes.length === 0) {
		// 	setNoDataMessage("No customer nodes. ")
		// } else {
		// 	setNoDataMessage("No data to display.")
		// }

		if (!customerNodes || !customerNodes.sitesTotal) {
			setGraphData({ series: [], labels: [], colors: [] })
		} else {
			const labels = Object.keys(customerNodes.sitesTotal)
			// console.log("🚀 ~ useEffect ~ labels:", labels)
			const series = Object.values(customerNodes.sitesTotal)
			// console.log("🚀 ~ useEffect ~ series:", series)

			setGraphData({
				series: series,
				labels: labels,
				colors: colors
			})
		}
	}, [customerNodes])

	return (
		<>
			<div className="row" data-testid="top_row_div" id="dashboard_top_row_info_main_div">
				{listLoading && <ModalProgressBar variant="query" />}
				<div className="col">
					<GlobalPeopleInfo
						value={`${customerNodes?.onSitePeople || 0} / ${
							customerNodes?.onSitePeople || 0 + customerNodes?.offSitePeople || 0
						}`}
						text="On-site / Total people"
						icon="/media/crowdkeep/Person_present.svg"
						color="#99BDF8"
					/>
					<GlobalPeopleInfo
						value={customerNodes?.offSitePeople || 0}
						text="Off-site people"
						icon="/media/crowdkeep/Person_absent.svg"
						color="#DDE9FD"
					/>
				</div>
				<div className="col">
					<GlobalPeopleInfo
						value={`${customerNodes?.onSiteAssets || 0} / ${
							customerNodes?.onSiteAssets || 0 + customerNodes?.offSiteAssets || 0
						}`}
						text="On-site / Total assets"
						icon="/media/crowdkeep/asset_active.svg"
						color="#B7B3FF"
					/>
					<GlobalPeopleInfo
						value={customerNodes?.offSiteAssets || 0}
						text="Off-site assets"
						icon="/media/crowdkeep/asset_idle.svg"
						color="#E7E5FF"
					/>
				</div>
				<div
					className="chart-container col-5"
					style={{
						borderRadius: "5px",
						background: "#FFF",
						boxShadow: " 0px 0px 6px 2px rgba(0, 0, 0, 0.10)",
						// height: "275px",
						// minWidth: "250px",
						height: "215px",
						minWidth: "215px",
						margin: "15px 0px"
					}}
				>
					{graphData?.series && graphData?.series.length !== 0 ? (
						<Chart
							options={getChartOptions(graphData)}
							series={graphData?.series || []}
							type="donut"
							height="100%"
						/>
					) : (
						<div
							style={{ textAlign: "center" }}
							className="d-flex justify-content-center align-items-center"
						>
							{customerNodes &&
								customerNodes.length !== 0 &&
								graphData?.series.length === 0 && (
									<div
										className="d-flex align-items-center"
										style={{ minHeight: "215px" }}
									>
										<div>
											<p className="mb-0 mt-4" style={{ fontSize: "14px" }}>
												No data to display.
											</p>
										</div>
									</div>
								)}
						</div>
					)}
					{(!customerNodes || customerNodes.length === 0) && (
						<div
							style={{ textAlign: "center" }}
							className="d-flex justify-content-center align-items-center"
						>
							<div className="d-flex align-items-center" style={{ minHeight: "215px" }}>
								<div>
									<p className="mb-0 mt-4" style={{ fontSize: "14px" }}>
										No data to display.
									</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	)
}

function getChartOptions(graphData, listLoading) {
	return {
		chart: {
			type: "donut",
			toolbar: {
				show: true,
				tools: {
					download: false,
					selection: false,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: false,
					customIcons: []
				}
				// export: {
				// 	csv: {
				// 		filename: "TotalPeopleOnSite",
				// 		columnDelimiter: ",",
				// 		headerCategory: "Floor",
				// 		headerValue: "Value",
				// 		dateFormatter(timestamp) {
				// 			return new Date(timestamp).toDateString()
				// 		}
				// 	},
				// 	svg: {
				// 		filename: "TotalPeopleOnSite"
				// 	},
				// 	png: {
				// 		filename: "TotalPeopleOnSite"
				// 	}
				// }
			},
			zoom: {
				enabled: false
			}
		},
		noData: {
			text: listLoading ? "Loading data..." : "No data to display."
		},
		labels: graphData?.labels,
		// legend: {
		// 	position: "right"
		// },
		fill: {
			colors: [
				// "#0a1b3d",
				"#0C48AA",
				"#1065EF",
				"#5591F3",
				"#7aa9f5",
				"#a0c1f8",
				"#c5dafa",
				"#dbe7fc",
				"#e6eefc"
			]
			// colors: props?.colors || []
		},
		// // colors: ["#99BDF8", "#2673F0"],
		colors: [
			"#0C48AA",
			"#1065EF",
			"#5591F3",
			"#7aa9f5",
			"#a0c1f8",
			"#c5dafa",
			"#dbe7fc",
			"#e6eefc"
		],
		plotOptions: {
			pie: {
				donut: {
					labels: {
						show: true,
						value: {
							fontSize: "12px",
							fontWeight: 500,
							color: "black",
							offsetY: 2
						},
						total: {
							show: true,
							label: "Total Employees",
							color: "black",
							fontSize: "9px",
							fontWeight: 500,
							formatter: w => {
								const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
								return total
								// const average = total / w.globals.seriesTotals.length;
								// return Math.floor(average);
							}
						}
					}
				}
			}
		},
		responsive: [
			{
				breakpoint: 900,
				options: {
					chart: {
						width: "100%",
						height: "100%"
					}
				}
			}
		]
	}
}

function CustomLegend({ data }) {
	let options = data
	// console.log("🚀  options:", options)

	const [displayedOptions, setDisplayedOptions] = useState([])

	useEffect(() => {
		if (!options) return

		setDisplayedOptions(options?.labels?.slice(0, 2))
	}, [options])

	const showNextOptions = () => {
		const currentIndex = options.labels.indexOf(displayedOptions[1])
		let nextIndex = currentIndex + 1

		if (nextIndex >= options.labels.length) {
			nextIndex = 0
		}

		const nextOptions = options.labels.slice(nextIndex, nextIndex + 2)
		setDisplayedOptions(nextOptions)
	}

	const showPreviousOptions = () => {
		let currentIndex = options.labels.indexOf(displayedOptions[0])
		let previousIndex = currentIndex - 2

		if (previousIndex < 0) {
			previousIndex = options.labels.length + previousIndex
		}

		const previousOptions = options.labels.slice(previousIndex, previousIndex + 2)
		setDisplayedOptions(previousOptions)
	}

	return (
		<div className="d-flex justify-content-center align-items-center w-100 h-100">
			{displayedOptions && displayedOptions.length > 0 && (
				<div
					className="row d-flex align-items-center w-100 h-100"
					style={{ justifyContent: displayedOptions.length <= 1 ? "center" : "" }}
				>
					{displayedOptions.length > 0 && (
						<div className="col-2 w-100 h-100 d-flex justify-content-center align-items-center">
							<div
								className="d-flex flex-column justify-content-between"
								style={{
									border: "1px solid #DDE9FD",
									borderRadius: "4px",
									width: "25px",
									height: "60px"
								}}
							>
								<div
									onClick={showPreviousOptions}
									className="d-flex custom_legend_arrow_div_top"
								>
									<ExpandLessIcon style={{ fontSize: "23px", color: "#3077D3" }} />
								</div>
								<div
									onClick={showNextOptions}
									className="d-flex custom_legend_arrow_div_bottom"
								>
									<ExpandMoreIcon style={{ fontSize: "23px", color: "#3077D3" }} />
								</div>
							</div>
						</div>
					)}

					<div className="col-10 pl-1">
						{displayedOptions &&
							displayedOptions.length > 0 &&
							displayedOptions.map((option, index) => (
								<div
									key={index}
									className="d-flex align-items-center mb-5 mt-5"
									// style={{ margin: "12px 0" }}
								>
									<div
										className="mr-3"
										style={{
											backgroundColor:
												options?.colors?.[options.labels?.indexOf(option)],
											width: "20px",
											height: "20px",
											borderRadius: "50%"
										}}
									/>
									<p style={{ fontSize: "11px", margin: "0" }}>
										{/* {options?.buildingsName?.[options.labels?.indexOf(option)]} -{" "} */}
										{options?.floors
											? options?.floors?.[options.labels?.indexOf(option)]
											: options?.buildingsName?.[options.labels?.indexOf(option)]}{" "}
										{options.labels?.length > 1 ? " - " : " "}
										<span style={{ fontWeight: "500" }}>{option}</span>
									</p>
								</div>
							))}
					</div>
				</div>
			)}
		</div>
	)
}

export function GlobalPeopleInfo({ value, text, icon, color }) {
	return (
		<div
			id="dashboard_GlobalPeopleInfo_div"
			className="d-flex justify-content-between align-items-start"
			style={{
				backgroundColor: color,
				borderRadius: "5px",
				padding: "15px",
				height: "100px",
				margin: "15px 0"
			}}
		>
			<div
				className="d-flex flex-column"
				style={{
					fontFamily: "Poppins",
					fontStyle: "normal",
					// letterSpacing: "-0.02em",
					color: "#4A4A68",
					alignSelf: "end"
				}}
			>
				<span
					style={{
						fontSize: "28px",
						lineHeight: "40px",
						fontWeight: "700"
					}}
				>
					{value}
				</span>
				<span
					className="top_row_info_text"
					style={{
						// marginTop: "-8px",
						fontSize: "12px",
						lineHeight: "18px",
						fontWeight: "500"
					}}
				>
					{text}
				</span>
			</div>
			<img alt="Person Info Icon" src={icon} />
		</div>
	)
}

export default NoSelectedSiteHeader
