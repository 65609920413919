import React from "react"
import { NavLink } from "react-router-dom"
import { useLocation } from "react-router"
import { Box, Typography } from "@mui/material"
import { RenderImgFileAsSvg } from "./RenderImgFileAsSvg"
// TODO: refactor from metronic
import { checkIsActive, getLastValidRoute } from "../../_metronic/_helpers"

const DEFAULT_CLASS_NAME = "operations-menu-link"
const ACTIVE_ITEM_COLOR = "#2673F0"
const NOT_ACTIVE_ITEM_COLOR = "#4A4A68"

const DEFAULT_NAVIGATION_MENU_TEXT_STYLE = {
	color: "#4a4a68",
	textAlign: "center",
	fontFamily: "Poppins",
	fontSize: "14px",
	fontStyle: "normal",
	fontWeight: "700",
	lineHeight: "normal",
	letterSpacing: "0.56px",
	textTransform: "uppercase",
	"&:hover": {
		color: ACTIVE_ITEM_COLOR
	}
}

const DEFAULT_NAVIGATION_MENU_ITEM_STYLE = {
	display: "flex",
	width: "91px",
	height: "70px",
	padding: "10px",
	flexDirection: "column",
	alignItems: "center",
	gap: "5px",
	textAlign: "center"
}

export const NavigationMenuItem = ({
	route,
	icon,
	label,
	className,
	validRoutes,
	defaultRoute
}) => {
	const location = useLocation()
	const lastValidRoute = getLastValidRoute(location.pathname, validRoutes, defaultRoute)

	const isMenuItemActive = url => checkIsActive(lastValidRoute, url)

	return (
		<Box
			sx={{
				...DEFAULT_NAVIGATION_MENU_ITEM_STYLE,
				borderBottom: isMenuItemActive(route) ? `4px solid ${ACTIVE_ITEM_COLOR}` : "none"
			}}
		>
			<NavLink className={className ? className : DEFAULT_CLASS_NAME} to={route}>
				<RenderImgFileAsSvg
					src={icon}
					fill={isMenuItemActive(route) ? ACTIVE_ITEM_COLOR : NOT_ACTIVE_ITEM_COLOR}
					style={{ marginBottom: "5px" }}
				/>
				<Typography
					variant="body2"
					sx={{
						...DEFAULT_NAVIGATION_MENU_TEXT_STYLE,
						color: isMenuItemActive(route) ? ACTIVE_ITEM_COLOR : NOT_ACTIVE_ITEM_COLOR
					}}
				>
					{label}
				</Typography>
			</NavLink>
		</Box>
	)
}
