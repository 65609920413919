import React from "react"
import ReactDOM from "react-dom"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import sensorIcon from "../../../../app/assets/dashboard_markers/sensor-marker.png"
import Chart from "react-apexcharts"
import ReactApexChart from "react-apexcharts"

import { CgArrowsExpandRight } from "react-icons/cg"
import { CgBlock } from "react-icons/cg"
import PopupStickOnClick from "../../Operations/map-infrastructure/map-infra-helpers/PopupStickOnClick"

export const addImageLayerToMap = params => {
	//━━━ Parameters ━━━\\
	const { map, floorPlan, mapStyleChanged, newStyleLayer } = params

	if (!map || !floorPlan) return

	const layers = map.getStyle()

	if (layers) {
		//━━━ Remove sources and layers ━━━\\
		typeof map.getLayer("imageLayer") !== "undefined" && map.removeLayer("imageLayer")
		typeof map.getSource("imageSource") !== "undefined" && map.removeSource("imageSource")
		typeof map.getLayer("geoJsonLayer") !== "undefined" && map.removeLayer("geoJsonLayer")
		typeof map.getSource("geoJsonSource") !== "undefined" && map.removeSource("geoJsonSource")
		typeof map.getLayer("geoJsonLinesLayer") !== "undefined" &&
			map.removeLayer("geoJsonLinesLayer")
		typeof map.getSource("geoJsonLinesSource") !== "undefined" &&
			map.removeSource("geoJsonLinesSource")
		//

		if (!floorPlan?.imageUrl) {
			map.fitBounds(
				[
					[
						floorPlan?.coords?.topLeftCorner.coords.longitude,
						floorPlan?.coords?.topLeftCorner.coords.latitude
					],
					[
						floorPlan?.coords?.bottomRightCorner.coords.longitude,
						floorPlan?.coords?.bottomRightCorner.coords.latitude
					]
				],
				// ↓ Duração da animação a iniciar o mapa e o zoom inicial
				{
					padding: { top: 10, bottom: 10, left: 10, right: 10 },
					// animationOptions,
					duration: 0
				}
			)
		} else {
			//━━━ Add image ━━━\\
			map.addSource("imageSource", {
				type: "image",
				url: floorPlan?.imageUrl ? floorPlan?.imageUrl : "",
				coordinates: [
					[
						floorPlan?.coords?.topLeftCorner?.coords.longitude,
						floorPlan?.coords?.topLeftCorner?.coords.latitude
					],
					[
						floorPlan?.coords?.topRightCorner?.coords.longitude,
						floorPlan?.coords?.topRightCorner?.coords.latitude
					],
					[
						floorPlan?.coords?.bottomRightCorner?.coords.longitude,
						floorPlan?.coords?.bottomRightCorner?.coords.latitude
					],
					[
						floorPlan?.coords?.bottomLeftCorner?.coords.longitude,
						floorPlan?.coords?.bottomLeftCorner?.coords.latitude
					]
				]
			})

			typeof map.getSource("geoJsonLayer-0") !== "undefined"
				? map.addLayer(
						{
							id: "imageLayer",
							source: "imageSource",
							type: "raster",
							paint: {
								"raster-opacity": 0.8
							}
						}
						// "settlement-subdivision-label"
						// "state-label"
				  )
				: map.addLayer(
						{
							id: "imageLayer",
							source: "imageSource",
							type: "raster",
							paint: {
								"raster-opacity": 0.8
							}
						},
						"settlement-subdivision-label"
						// "state-label"
				  )
		}

		if (mapStyleChanged && !newStyleLayer) {
			return
		} else if (!mapStyleChanged && newStyleLayer) {
			return
		} else {
			// ↓ If exists is this floorPlan mapOrientation it displays orientation from db
			if (Boolean(floorPlan.mapOrientation) === true) {
				const lat = floorPlan?.mapOrientation?.center?.latitude
				const lng = floorPlan?.mapOrientation?.center?.longitude

				// ↑ equal to map.easeTo (to give animation fx)
				lng &&
					lat &&
					map.easeTo({
						center: [lng, lat],
						bearing: floorPlan?.mapOrientation?.bearing,
						pitch: floorPlan?.mapOrientation?.pitch,
						zoom: floorPlan?.mapOrientation?.zoom,
						duration: 0
					})
			} else {
				// Onde é centrado o mapa por default
				map.fitBounds(
					[
						[
							floorPlan?.coords?.topLeftCorner?.coords?.longitude,
							floorPlan?.coords?.topLeftCorner?.coords?.latitude
						],
						[
							floorPlan?.coords?.bottomRightCorner?.coords?.longitude,
							floorPlan?.coords?.bottomRightCorner?.coords?.latitude
						]
					],
					// ↓ Duração da animação a iniciar o mapa e o zoom inicial
					{
						padding: { top: 10, bottom: 10, left: 10, right: 10 },
						// animationOptions,
						duration: 0
					}
				)
			}
		}
	}
}

export const addGeoJsonLayerToMap = params => {
	const { map, geoJson, layerTypes } = params

	if (!map) return

	const layers = map.getStyle()

	if (layers) {
		typeof map.getLayer("geoJsonLayer") !== "undefined" && map.removeLayer("geoJsonLayer")
		typeof map.getSource("geoJsonSource") !== "undefined" && map.removeSource("geoJsonSource")
		typeof map.getLayer("geoJsonLinesLayer") !== "undefined" &&
			map.removeLayer("geoJsonLinesLayer")
		typeof map.getSource("geoJsonLinesSource") !== "undefined" &&
			map.removeSource("geoJsonLinesSource")

		var iterateWhile = true
		var index = 0

		while (iterateWhile === true) {
			typeof map.getLayer(`geoJsonLayer-${index}`) !== "undefined" &&
				map.removeLayer(`geoJsonLayer-${index}`)
			typeof map.getSource(`geoJsonSource-${index}`) !== "undefined" &&
				map.removeSource(`geoJsonSource-${index}`)
			typeof map.getLayer(`geoJsonLinesLayer-${index}`) !== "undefined" &&
				map.removeLayer(`geoJsonLinesLayer-${index}`)
			typeof map.getSource(`geoJsonLinesSource-${index}`) !== "undefined" &&
				map.removeSource(`geoJsonLinesSource-${index}`)
			index++
			if (
				typeof map.getLayer(`geoJsonLinesLayer-${index}`) === "undefined" &&
				typeof map.getSource(`geoJsonLinesSource-${index}`) === "undefined" &&
				typeof map.getLayer(`geoJsonLayer-${index}`) === "undefined" &&
				typeof map.getSource(`geoJsonSource-${index}`) === "undefined"
			) {
				iterateWhile = false
			}
		}

		if (!geoJson) return

		//━━━ Tranform data for mapbox ━━━\\
		geoJson.features.forEach((feature, index) => {
			typeof map.getLayer(`geoJsonLayer-${index}`) !== "undefined" &&
				map.removeLayer(`geoJsonLayer-${index}`)
			typeof map.getSource(`geoJsonSource-${index}`) !== "undefined" &&
				map.removeSource(`geoJsonSource-${index}`)
			typeof map.getLayer(`geoJsonLinesLayer-${index}`) !== "undefined" &&
				map.removeLayer(`geoJsonLinesLayer-${index}`)
			typeof map.getSource(`geoJsonLinesSource-${index}`) !== "undefined" &&
				map.removeSource(`geoJsonLinesSource-${index}`)

			var currentType =
				layerTypes && layerTypes.find(type => type.id === feature.properties.type)

			var coordinates = []
			feature.geometry.coordinates.forEach(coords => {
				coordinates.push([coords.longitude, coords.latitude])
			})

			const data = {
				type: geoJson.features[index].type,
				properties: {
					...geoJson.features[index].properties,
					color: currentType?.color || "#e9ecef",
					outline: feature.properties.geoFencing
						? "#4A4A68"
						: currentType?.color
						? currentType?.color
						: "#ced4da",
					lineWidth: feature.properties.type !== "baseLayer" ? (currentType ? 2 : 2) : 4
				},
				geometry: {
					type: geoJson.features[index].geometry.type,
					coordinates: [coordinates]
				}
			}

			const isVisible = feature.properties.visible === true ? "visible" : "none"

			const noPropertyInDBCondition =
				feature.properties.visible !== undefined ? isVisible : "visible"

			//━━━ Add to floorplan ━━━\\
			map.addSource(`geoJsonSource-${index}`, {
				type: "geojson",
				data: data
			})

			map.addLayer(
				{
					id: `geoJsonLayer-${index}`,
					source: `geoJsonSource-${index}`,
					type: "fill",
					layout: {
						visibility: `${noPropertyInDBCondition}`
					},
					paint: {
						"fill-color": ["get", "color"],
						"fill-outline-color": ["get", "outline"],
						"fill-opacity": 0.4
					}
				},
				"settlement-subdivision-label"
				// "state-label"
			)

			//━━━ Add lines ━━━\\
			map.addSource(`geoJsonLinesSource-${index}`, {
				type: "geojson",
				data: data
			})
			map.addLayer(
				{
					id: `geoJsonLinesLayer-${index}`,
					source: `geoJsonLinesSource-${index}`,
					type: "line",
					layout: {
						visibility: `${noPropertyInDBCondition}`
						// visibility: "visible",
					},
					paint: {
						"line-color": ["get", "outline"],
						"line-width": ["get", "lineWidth"]
					}
				},
				"settlement-subdivision-label"
				// "state-label"
			)
		})
	}
}

export const generateMockData = () => {
	const data = []
	const now = new Date()
	for (let i = 0; i <= 24; i++) {
		const timestamp = new Date(now.getTime() - (23 - i) * 3600000) // hourly intervals for last 24 hours
		data.push({
			x: timestamp,
			y: Math.floor(Math.random() * 30) + 1 // random temperature between 1 and 30ºC
		})
	}
	return data
}

export function addSensorsToMap(params) {
	const {
		map,
		selectedCustomer,
		selectedSite,
		currentFloorPlan,
		sensors,
		sensorsMeasurements,
		sensorMarkers,
		setSensorMarkers,
		showSensors,
		linkToSensorsId,
		setShowSensors
	} = params

	if (!map || !selectedCustomer || !selectedSite || !currentFloorPlan) return

	if (sensorMarkers) {
		sensorMarkers.forEach(marker => marker.remove())
	}
	// setSensorMarkers([])

	if (!showSensors) return
	const imageId = "sensor-icon"
	map.loadImage(sensorIcon, (error, img) => {
		if (!img || error) return

		// If map already has the image we need to remove so there aren't any re-renders
		const mapHasImage = map.hasImage(imageId)
		// ↓ So don't get error of loading image with same sourceId
		if (mapHasImage) {
			map.removeImage(imageId)
		}

		// add icon image
		map.addImage(imageId, img)
	})

	const _sensors = sensors ? sensors.filter(val => val.floorPlanId === currentFloorPlan.id) : []

	const geojsonData = {
		type: "FeatureCollection",
		features: _sensors.map(val => ({
			type: "Feature",
			geometry: {
				type: "Point",
				coordinates: [val.gpsData.longitude, val.gpsData.latitude]
			},
			properties: {
				id: val.id,
				temperatureData: val.measurements?.temperature || [],
				humidityData: val.measurements?.humidity || [],
				sensorData: val,
				iconImage: imageId
			}
		}))
	}

	const layersToRemove = ["unclustered-images", "cluster-pins", "cluster-pins-count"]

	const sourcesToRemove = ["pins"]

	layersToRemove.forEach(layerId => {
		if (map.getLayer(layerId)) {
			map.removeLayer(layerId)
		}
	})

	sourcesToRemove.forEach(sourceId => {
		if (map.getSource(sourceId)) {
			map.removeSource(sourceId)
		}
	})

	if (map.getSource("pins")) {
		map.getSource("pins").setData(geojsonData)
	} else {
		map.addSource("pins", {
			type: "geojson",
			data: geojsonData,
			cluster: true,
			clusterRadius: 35,
			clusterMaxZoom: 22
		})
	}

	map.addLayer(
		{
			id: "unclustered-images",
			type: "symbol",
			source: "pins",
			filter: ["!", ["has", "point_count"]],
			layout: {
				"icon-image": "sensor-icon", // Ensure this matches the image id added to the map
				"icon-allow-overlap": true,
				"icon-ignore-placement": true
			}
		},
		"country-label"
	)

	map.addLayer(
		{
			id: "cluster-pins",
			type: "circle",
			source: "pins",
			filter: ["all", ["has", "point_count"]],
			paint: {
				"circle-color": {
					type: "interval",
					property: "point_count",
					stops: [
						[0, "#A791FE"],
						[20, "#635AFF"],
						[200, "#3F39A5"],
						[500, "#1C1C3B"]
					]
				},
				"circle-radius": 18,
				"circle-stroke-width": 2,
				"circle-stroke-color": "#ffffff"
			}
		},
		"country-label"
	)

	map.addLayer(
		{
			id: "cluster-pins-count",
			type: "symbol",
			source: "pins",
			layout: {
				"text-field": "{point_count}",
				"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
				"text-size": 12
			},
			paint: {
				"text-color": "#FFFFFF"
			}
		},
		"country-label"
	)

	map.on("mouseenter", "unclustered-images", function (e) {
		const feature = e.features[0]

		_sensors &&
			_sensors.map(val => {
				if (!val.gpsData) return null
				const popupStickNew = (
					<PopupStickOnClick
						component={
							<div
								key={val.id} // Add a key to each mapped element
								style={{
									background: "#ECF1F4",
									boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
									borderRadius: "8px",
									fontFamily: "Poppins",
									fontStyle: "normal",
									color: "#4A4A68",
									padding: "1rem",
									width: "400px"
								}}
							>
								<div className="d-flex justify-content-between d-flex align-items-center mb-4">
									<div className="d-flex align-items-center">
										<span
											className="mr-3"
											style={{
												color: "#4A4A68",
												fontSize: "16px",
												fontWeight: 700
											}}
										>
											Temperature #1
										</span>
										<div
											style={{
												backgroundColor: "green",
												width: "14px",
												height: "14px",
												borderRadius: "50%"
											}}
										/>
									</div>

									<div
										className="d-flex justify-content-center align-items-center fullscreen_sensor"
										onClick={() => {
											if (val.measurement) {
												linkToSensorsId(val)
											}
										}}
										style={{
											pointerEvents: val.measurement ? "all" : "none",
											cursor: val.measurement ? "pointer" : "not-allowed"
										}}
									>
										{val.measurement ? (
											<CgArrowsExpandRight className="fullscreen_icon_sensor" />
										) : (
											<CgBlock className="fullscreen_icon_sensor" />
										)}
									</div>
								</div>
								<div
									style={{
										backgroundColor: "white",
										borderRadius: "5px"
									}}
								>
									<SensorChart
										sensor={sensorsMeasurements[val.id]}
										selectedSite={selectedSite}
									/>
								</div>

								<div className="mt-3">
									<Info sensorPosition={val} />
								</div>
							</div>
						}
						delay={100}
					>
						<div className="tags_unclustered_html_markers_dashboard">
							<img
								src={sensorIcon}
								alt="marker"
								style={{
									filter: "brightness(0) invert(1)",
									padding: "4px",
									objectFit: "contain",
									margin: "auto",
									width: "90%",
									height: "auto",
									display: "block",
									visibility: "hidden"
								}}
							/>
						</div>
					</PopupStickOnClick>
				)

				const div = document.createElement("div")
				div.className = "connectorsMarkerDashboard"
				div.style.cursor = "pointer"

				ReactDOM.render(popupStickNew, div)

				const marker = new mapboxgl.Marker(div, {
					draggable: false
				}).setLngLat([val.gpsData.longitude, val.gpsData.latitude])
				marker.addTo(map)
				setSensorMarkers(prev => [...prev, marker])
			})
	})

	map.on("zoomend", e => {
		// Remove old custom markers if needed
		const markerEl = document.getElementsByClassName("tags_unclustered_html_markers_dashboard")

		if (markerEl) {
			Array.from(markerEl).forEach(div => div.remove())
		}

		if (sensorMarkers && sensorMarkers.length !== 0) {
			sensorMarkers.forEach(marker => marker.remove())
		}
	})

	map.on("mouseenter", "cluster-pins", () => {
		map.getCanvas().style.cursor = "pointer"
	})

	map.on("mouseleave", "cluster-pins", () => {
		map.getCanvas().style.cursor = ""
	})
}

export const SensorChart = ({ sensor, selectedSite }) => {
	if (!sensor || !selectedSite) return null

	const getMinMax = (data, key) => {
		const values = data.map(entry => entry.measurement[key])
		const min = Math.min(...values)
		const max = Math.max(...values)

		if (min === max) {
			return { min: min - 1, max: max + 1 }
		}
		return { min, max }
	}

	const { min: tempMin, max: tempMax } = getMinMax(sensor, "temperature")
	const { min: humMin, max: humMax } = getMinMax(sensor, "humidity")
	const { min: co2Min, max: co2Max } = getMinMax(sensor, "co2")

	const normalize = (value, min, max) => {
		if (min === max) return 0.5
		return (value - min) / (max - min)
	}
	//! this is returning nan
	const temperatureData = sensor.map(entry => ({
		x: new Date(entry.measurement_time).getTime(),
		y: normalize(entry.measurement.temperature, tempMin, tempMax)
	}))

	const humidityData = sensor.map(entry => ({
		x: new Date(entry.measurement_time).getTime(),
		y: normalize(entry.measurement.humidity, humMin, humMax)
	}))

	const co2Data = sensor.map(entry => ({
		x: new Date(entry.measurement_time).getTime(),
		y: normalize(entry.measurement.co2, co2Min, co2Max)
	}))

	const now = Date.now()
	const fourHoursAgo = now - 1 * 60 * 60 * 1000

	const chartOptions = {
		chart: {
			type: "area",
			height: 350,
			stacked: false,
			zoom: {
				type: "x",
				enabled: true,
				autoScaleYaxis: true
			},
			toolbar: { autoSelected: "zoom" }
		},
		dataLabels: { enabled: false },
		markers: { size: 0 },
		fill: {
			type: "gradient",
			gradient: {
				shadeIntensity: 1,
				inverseColors: false,
				opacityFrom: 0.5,
				opacityTo: 0,
				stops: [0, 90, 100]
			}
		},
		xaxis: {
			type: "datetime",
			min: fourHoursAgo,
			max: now
		},
		yaxis: [
			{ labels: { show: false }, title: { text: "" } },
			{ opposite: true, labels: { show: false }, title: { text: "" } }
		],
		tooltip: {
			shared: true,
			x: { format: "dd MMM HH:mm" },
			y: {
				formatter: (value, { seriesIndex }) => {
					if (seriesIndex === 0) {
						return (value * (tempMax - tempMin) + tempMin).toFixed(2) + "°C"
					}
					if (seriesIndex === 1) {
						return (value * (humMax - humMin) + humMin).toFixed(2) + "%"
					}
					if (seriesIndex === 2) {
						return (value * (co2Max - co2Min) + co2Min).toFixed(2) + " ppm"
					}
					return value
				}
			}
		}
	}

	return (
		<div>
			<ReactApexChart
				options={chartOptions}
				series={[
					{ name: "Temperature", data: temperatureData },
					{ name: "Humidity", data: humidityData },
					{ name: "CO₂", data: co2Data }
				]}
				type="area"
				height={350}
			/>
		</div>
	)
}

export function addSingleSensorToMap(params) {
	const {
		map,
		selectedCustomer,
		selectedSite,
		currentFloorPlan,
		sensor,
		sensors,
		sensorMarkers,
		setSensorMarkers
	} = params

	if (!map || !selectedCustomer || !selectedSite || !currentFloorPlan) return

	sensorMarkers && sensorMarkers.forEach(marker => marker.remove())
	// setSensorMarkers()

	var _sensors = sensors
		? sensors.filter(val => val.floorPlanId === currentFloorPlan.id && val.id === sensor.id)
		: []

	const newMarkers =
		_sensors &&
		_sensors
			.map(val => {
				if (!val.gpsData) return null

				const popupStickNew = (
					<PopupStickOnClick
						component={
							<div
								style={{
									background: "#ECF1F4",
									boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
									borderRadius: "8px",
									fontFamily: "Poppins",
									fontStyle: "normal",
									color: "#4A4A68",
									padding: "1rem",
									width: "400px"
								}}
							>
								<div className="d-flex justify-content-between d-flex align-items-center mb-4">
									<div className="d-flex align-items-center">
										<span
											className="mr-3"
											style={{ color: "#4A4A68", fontSize: "16px", fontWeight: 700 }}
										>
											{" "}
											Temperature #1
										</span>
										<div
											style={{
												backgroundColor: "green",
												width: "14px",
												height: "14px",
												borderRadius: "50%"
											}}
										/>
									</div>
								</div>

								<div className="mt-3">
									<Info sensorPosition={val} />
								</div>
							</div>
						}
						placement="top"
						onMouseEnter={() => {}}
						delay={500}
					>
						<div className="d-flex justify-content-center align-items-center">
							<img
								src={sensorIcon}
								alt="marker"
								style={{
									padding: "4px",
									objectFit: "contain",
									margin: "auto",
									width: "90%",
									height: "auto",
									display: "block"
								}}
							/>
						</div>
					</PopupStickOnClick>
				)

				const div = document.createElement("div")
				div.className = "connectorsMarkerDashboard"
				div.style.cursor = "pointer"

				ReactDOM.render(popupStickNew, div)

				const marker = new mapboxgl.Marker(div, {
					draggable: false
				})

				marker.setLngLat([val.gpsData.longitude, val.gpsData.latitude]).addTo(map)
				return marker
			})

			.filter(val => val)

	setSensorMarkers(newMarkers)
	return
}

export function Info(props) {
	const { sensorPosition } = props

	return (
		<div className="d-flex" style={{ padding: "0px" }}>
			<div
				className={
					sensorPosition?.description && sensorPosition?.description !== ""
						? "flex-fill col-4 mr-2"
						: "flex-fill col-12 mr-3"
				}
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Sensor
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					UUID: {sensorPosition?.uuid}
				</div>
			</div>

			<div
				className={
					sensorPosition?.description && sensorPosition?.description !== ""
						? "flex-fill col-4 mr-2"
						: "flex-fill col-12"
				}
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Temperature
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					{Math.floor(sensorPosition.measurement.temperature)}ºC
				</div>
			</div>

			<div
				className={
					sensorPosition?.description && sensorPosition?.description !== ""
						? "flex-fill col-4 mr-2"
						: "flex-fill col-12 ml-3"
				}
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Humidity
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					{Math.floor(sensorPosition.measurement.humidity)}%
				</div>
			</div>

			{sensorPosition?.description && sensorPosition?.description !== "" && (
				<div
					className="flex-fill col-8"
					style={{
						background: "#FFFFFF",
						borderRadius: "4px",
						overflowY: "auto", // Enable vertical scrolling
						maxHeight: "75px" // Set the maximum height to display 2 lines of text
					}}
				>
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "300",
							lineHeight: "160%",
							fontSize: "10px",
							color: "#4A4A68"
						}}
					>
						Description
					</div>
					<hr className="m-0" />
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "600",
							lineHeight: "160%",
							fontSize: "11px",
							color: "#4A4A68"
						}}
					>
						{sensorPosition.description}
					</div>
				</div>
			)}
		</div>
	)
}
