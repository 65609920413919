import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { DesignStudioSlice } from "../../../../_redux/admin/designStudio/DesignStudioSlice"
import * as actionsInventory from "../../../../_redux/admin/inventory/InventoryActions"
import { useFetchNodesPositionByStatus } from "../_utils/fetchNodesPositionByStatus"
import { useFetchNodesByStatus } from "../_utils/fetchNodesByStatus"

const actionsDesignStudio = DesignStudioSlice.actions

export const useFetchNodesData = (
	selectedSite,
	currentFloorPlan,
	collectionChanged,
	setNodesCounts,
	setAllTotalCount,
	collectionName,
	nodeType
) => {
	const [isFetching, setIsFetching] = useState(false)
	const dispatch = useDispatch()

	const fetchModels = async () => {
		dispatch(actionsInventory.getModels({ technology: "Wirepas" }))
	}

	useEffect(() => {
		if (!selectedSite || !currentFloorPlan) return

		setIsFetching(true)

		const fetchData = async () => {
			try {
				const allData = await Promise.all([
					useFetchNodesPositionByStatus(
						"assigned",
						setNodesCounts,
						setAllTotalCount,
						collectionName,
						selectedSite,
						currentFloorPlan
					),
					useFetchNodesPositionByStatus(
						"planned",
						setNodesCounts,
						setAllTotalCount,
						collectionName,
						selectedSite,
						currentFloorPlan
					),
					useFetchNodesPositionByStatus(
						"placed",
						setNodesCounts,
						setAllTotalCount,
						collectionName,
						selectedSite,
						currentFloorPlan
					),

					useFetchNodesByStatus(
						"approved",
						nodeType,
						setNodesCounts,
						setAllTotalCount,
						selectedSite,
						currentFloorPlan
					),
					useFetchNodesByStatus(
						"online",
						nodeType,
						setNodesCounts,
						setAllTotalCount,
						selectedSite,
						currentFloorPlan
					),
					useFetchNodesByStatus(
						"lost",
						nodeType,
						setNodesCounts,
						setAllTotalCount,
						selectedSite,
						currentFloorPlan
					),
					useFetchNodesByStatus(
						"removed",
						nodeType,
						setNodesCounts,
						setAllTotalCount,
						selectedSite,
						currentFloorPlan
					),
					fetchModels()
				])

				const cleanedData = allData.flat().filter(item => item !== undefined)
				nodeType === "sensor"
					? dispatch(actionsDesignStudio.addSensorsDesignStudio(cleanedData))
					: dispatch(actionsDesignStudio.addAnchorsDesignStudio(cleanedData))
			} catch (error) {
				console.error("Error fetching data:", error.message || error)
			} finally {
				setIsFetching(false)
			}
		}

		fetchData()
	}, [selectedSite, currentFloorPlan, collectionChanged])

	return { isFetching }
}

export default useFetchNodesData
