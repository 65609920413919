import React, { useState, useEffect } from "react"
import { Card, CardBody } from "../../../_partials/Card"
import Chart from "react-apexcharts"
import dayjs from "dayjs" // Ensure you have dayjs installed (npm install dayjs)

const VolatileGasesChart = ({ chartOptions, sensorData = [], count }) => {
	const [graphData, setGraphData] = useState({
		labels: [],
		series: [],
		colors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]
	})

	// Function to group data by day and calculate the average
	const processPieChartDataByDay = data => {
		if (!Array.isArray(data) || data.length === 0) return { labels: [], series: [] }

		const groupedData = {}

		// Group by day
		data.forEach(({ x, y }) => {
			if (!x || typeof y !== "number") return

			const dateKey = dayjs(x).format("YYYY-MM-DD") // Format timestamp as "YYYY-MM-DD"

			if (!groupedData[dateKey]) {
				groupedData[dateKey] = { sum: 0, count: 0 }
			}

			groupedData[dateKey].sum += y
			groupedData[dateKey].count++
		})

		// Convert to arrays (calculate average & format to 2 decimal places)
		const labels = Object.keys(groupedData)
		const series = labels.map(date =>
			parseFloat((groupedData[date].sum / groupedData[date].count).toFixed(2))
		)

		return { labels, series }
	}

	useEffect(() => {
		setTimeout(() => {
			const processedData = processPieChartDataByDay(sensorData)
			setGraphData({
				...processedData,
				colors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8"]
			})
		}, 1000)
	}, [sensorData])

	return (
		<Card>
			<div className="title_bar_main_div">
				<div className="d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<div className="floor_sensor_icon">
							<img src={chartOptions?.icon} alt="icon" style={{ width: "16px" }} />
						</div>
						<div>
							<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
								{chartOptions.name}
							</span>
						</div>
					</div>
					<div className="floor_sensor_icon_count">
						<span style={{ color: "#8c8ca1", fontSize: "13px", fontWeight: 700 }}>
							{count}
						</span>
					</div>
				</div>
			</div>
			<CardBody style={{ padding: "0px" }}>
				<Chart
					options={{
						chart: { type: "pie" },
						labels: graphData.labels, // Dates as labels
						colors: graphData.colors,
						tooltip: {
							y: {
								formatter: function (value) {
									return value.toFixed(2) // Format to 2 decimal places
								}
							}
						},
						legend: {
							show: false // ✅ Hides the legend
						},
						noData: {
							text: "No data to display."
						},
						responsive: [{ breakpoint: 480, options: { legend: { show: false } } }]
					}}
					series={graphData.series.length ? graphData.series : []} // Prevent empty series issue
					type="pie"
					height="100%"
				/>
			</CardBody>
		</Card>
	)
}

export default VolatileGasesChart
