import React, { useState, useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import firebase from "firebase/compat/app"
import { storage } from "../../../../../firebase"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import imageCompression from "browser-image-compression"
import { OverlayTrigger, Tooltip, Carousel } from "react-bootstrap"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import "@turf/angle"
//partials + assets
import { profileSlice } from "../../../../redux/profile/profileSlice"
import * as poiActions from "../../../_redux/admin/designStudio/DesignStudioActions"
import { Button } from "../../../../_partials/Button"
import { BiEditAlt } from "react-icons/bi"
import dragSvg from "../../../../assets/designStudio/drag.svg"

//are being reused
import { uuidv4 } from "../../../Admin/design-studio/UIHelpers"
import MapControlsButtons from "../../../Admin/design-studio/MapControlButtons/MapControlsButtons"
import { sensorUnplaced } from "../../../_redux/admin/designStudio/DesignStudioSlice"
import { addGeoJsonLayerToMap } from "../../../Admin/design-studio/_helpers/ActionHelpers"
import "../../../Admin/design-studio/DesignStudio.css"
import MapDimensions from "../../../Admin/design-studio/MapControlButtons/MapDimensions"
import ChangeFloorControlNew from "../../../Admin/design-studio/MapControlButtons/ChangeFloorControlNew"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import PopupStickOnHoverManageInfrastructure from "../map-infra-helpers/PopupStickOnHoverManageInfrastructure"
import {
	startButtonProgress,
	finishButtonProgress,
	addNodesToMap
} from "../map-infra-helpers/NodesHelpers"
import EditNodeModal from "../map-infra-helpers/EditNodeModal"
import EditActiveNodeModal from "../map-infra-helpers/EditActiveNodeModal"
import BigImageModal from "../map-infra-helpers/BigImageModal"
import WebcamModal from "../map-infra-helpers/WebcamModal"
import NodesTable from "../map-infra-helpers/NodesTable"
import DeleteModal from "../map-infra-helpers/DeleteModal"
import { RenderUuidDiv } from "../_partials/RenderUuidDiv"
//TODO check if could be reused
import DrawerSensors from "./SensorsControls/DrawerSensors"
import DrawerListSensors from "./SensorsControls/DrawerListSensors"
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const { actions } = profileSlice

export function SensorsCard({ setCollectionChanged }) {
	const mapcontainerref = useRef()
	const dispatch = useDispatch()

	const {
		selectedCustomer,
		selectedCustomerId,
		currentFloorPlan,
		selectedSite,
		floorPlanData,
		roomTypes,
		user,
		sensorsPositionData,
		sensorsPositionToUpdate,
		sensorsData,
		modelsInventory
	} = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			selectedCustomerId: state.profile?.currentCustomer?.id,
			selectedSite: state.profile?.currentSite, // new selectedLocation
			currentFloorPlan: state.profile?.currentFloorPlan, // new selectedLevel
			floorPlanData: state.basePage?.floorPlans,
			roomTypes: state.profile?.currentCustomer?.layerTypes,
			user: state.auth.user,
			sensorsPositionData: state.designStudio?.sensorsPosition,
			sensorsPositionToUpdate: state.designStudio?.sensorsPositionToUpdate,
			sensorsData: state.designStudio.sensors || [],
			modelsInventory: state.inventory.modelsInventory
		}),
		shallowEqual
	)

	//━━━━━━━━ States ━━━━━━━━\\
	const [poiIdToDelete, setPoiIdToDelete] = useState()
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [urlsToDelete, setUrlsToDelete] = useState([])
	const [imagesModal2, setImagesModal2] = useState([])
	const [showModal2, setShowModal2] = useState(false) // show edit modal
	const [poiToUpdate, setPoiToUpdate] = useState()
	const [showEdit, setShowEdit] = useState(false)
	const [markers, setMarkers] = useState()
	const [sensorsPositioning, setSensorsPositioning] = useState(sensorsPositionData) // sensors data (old pointsOfInterest state)
	const [urlsModal2, setUrlsModal2] = useState([])
	const [progress, setProgress] = useState(0)
	const [allImagesInStorage, setAllImagesInStorage] = useState([])
	const [checked, setChecked] = useState(false)
	const [map, setMap] = useState()
	const [floorsControl, setFloorsControl] = useState()
	const [showWebcamModal, setShowWebcamModal] = useState(false)
	const [urlsWebcam, setUrlsWebcam] = useState([])
	const [showWebcamUpdateModal, setShowWebcamUpdateModal] = useState(false)
	const [urlsWebcamToUpdate, setUrlsWebcamToUpdate] = useState([])
	const [showSensorsOnline, setShowSensorsOnline] = useState(true)
	const [showSensorsRemoved, setShowSensorsRemoved] = useState(true)
	const [showSensorsLost, setShowSensorsLost] = useState(true)
	const [showAnchorsUid, setShowAnchorsUid] = useState(false)
	const [sensorsMarkers, setSensorsMarkers] = useState()
	const [oldSensorDoc, setOldSensorDoc] = useState()
	const [showActiveModal, setShowActiveModal] = useState(false)
	const [activeSensors, setActiveSensors] = useState() // sensors data arriving from the WNT Wirepas
	const [activeSensorToUpdate, setActiveSensorToUpdate] = useState(null) // active sensor to update
	const [showWebcamActiveUpdateModal, setShowWebcamActiveUpdateModal] = useState(false)
	const [urlsActiveWebcamToUpdate, setUrlsActiveWebcamToUpdate] = useState([])
	const [showAddPhotosActiveModal, setShowAddPhotosActiveModal] = useState(false) // show add photos active sensors modal
	const [imagesActiveAddPhotos, setImagesActiveAddPhotos] = useState([])
	const [urlsModal2Active, setUrlsModal2Active] = useState([])
	const [initialData, setInitialData] = useState([])
	const [selectedRows, setSelectedRows] = useState([])
	// const [showDeleteSeveral, setShowDeleteSeveral] = useState(false)
	const [showApproveModal, setShowApproveModal] = useState(false)
	const [dragMarker, setDragMarker] = useState(false)
	const [showBigImageModal, setShowBigImageModal] = useState(false)
	const [selectedBigImage, setSelectedBigImage] = useState()
	const [showBigImageModalActive, setShowBigImageModalActive] = useState(false)
	const [selectedBigImageActive, setSelectedBigImageActive] = useState()
	const [selectedImageIndexActive, setSelectedImageIndexActive] = useState([])
	const [selectedImageIndexPosition, setSelectedImageIndexPosition] = useState([])
	// ↓ drawer options control state
	const [drawerControl, setDrawerControl] = useState()
	const [openDrawerBar, setOpenDrawerBar] = useState(false)
	const [drawerOptions, setDrawerOptions] = useState({
		openSearch: false
	})
	const [goTo, setGoTo] = useState()
	// ↓ drawer options control state
	const [drawerListControl, setDrawerListControl] = useState()
	const [openDrawerListBar, setOpenDrawerListBar] = useState(false)
	const [drawerListOptions, setDrawerListOptions] = useState({
		openList: false
		//! maybe here i can have bool for filter
	})
	const [drawerListMarkers, setDrawerListMarkers] = useState([])

	// ━━━━━━━━ useEffects ━━━━━━━━\\

	useEffect(() => {
		if (!selectedCustomerId) {
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: { message: `Please Select Customer` }
			})
			return
		}
	}, [selectedCustomerId])

	useEffect(() => {
		if (!selectedSite) return

		dispatch(
			poiActions.fetchSensorsPosition({
				siteId: selectedSite?.id
			})
		)
	}, [selectedSite])

	// ━━━━━━━━ Set fetched SensorsData to state ━━━━━━━━
	useEffect(() => {
		sensorsPositionData && setSensorsPositioning(sensorsPositionData)
	}, [sensorsPositionData])

	// ━━━━━━━━ Set fetched Sensors to update to state, (after click to edit Sensor) ━━━━━━━━
	useEffect(() => {
		sensorsPositionToUpdate && setPoiToUpdate(sensorsPositionToUpdate)
		sensorsPositionToUpdate && setOldSensorDoc(sensorsPositionToUpdate)
	}, [sensorsPositionToUpdate])

	// ━━━━━━━━ Set fetched Active Sensors to update to state, (after click to edit Active sensor) ━━━━━━━━
	useEffect(() => {
		activeSensorToUpdate && setOldSensorDoc(activeSensorToUpdate)
		activeSensorToUpdate?.imagesUrls && setUrlsModal2Active(activeSensorToUpdate?.imagesUrls)
	}, [activeSensorToUpdate])

	// ━━━━━━━━ Map ━━━━━━━━
	useEffect(() => {
		const map = new mapboxgl.Map({
			container: mapcontainerref.current,
			style: "mapbox://styles/mapbox/streets-v11"
		})

		// Map Controls Buttons
		const showSensorsMarkersBtnOnline = new MapControlsButtons({
			className: "mapbox-gl-show-sensors-online",
			title: "Show active Sensors",
			eventHandler: clickToShowSensorsOnline
		})

		const showSensorsMarkersBtnRemoved = new MapControlsButtons({
			className: "mapbox-gl-show-sensors-removed",
			title: "Show removed Sensors",
			eventHandler: clickToShowSensorsRemoved
		})

		const showSensorsMarkersBtnLost = new MapControlsButtons({
			className: "mapbox-gl-show-sensors-lost",
			title: "Show lost Sensors",
			eventHandler: clickToShowSensorsLost
		})

		const showAnchorsMarkersBtnUid = new MapControlsButtons({
			className: "mapbox-gl-show-anchors-uid",
			title: "Show Anchors Uid",
			eventHandler: clickToShowAnchorsUid
		})

		const makeDraggableBtn = new MapControlsButtons({
			className: "mapbox-gl-drag-sensors",
			title: "Drag markers",
			eventHandler: clickToDragMarkers
		})

		map.on("load", function (e) {
			// Map controls
			map.addControl(
				new mapboxgl.NavigationControl({
					showCompass: false
				}),
				"top-left"
			)
			map.addControl(new MapDimensions(), "bottom-left")
			map.addControl(showSensorsMarkersBtnOnline, "top-left")
			map.addControl(showSensorsMarkersBtnRemoved, "top-left")
			map.addControl(showSensorsMarkersBtnLost, "top-left")
			map.addControl(showAnchorsMarkersBtnUid, "top-left")
			map.addControl(makeDraggableBtn, "top-left")

			setMap(map)
		})

		// disable zoom when doubleClick map
		map.doubleClickZoom.disable()
	}, [])

	// ━━━━━━━━ Mapbox Custom Controls functions ━━━━━━━━\\
	const clickToDragMarkers = () => {
		setDragMarker(prevState => !prevState)
	}

	const clickToShowSensorsOnline = () => {
		setShowSensorsOnline(prevState => !prevState)
	}
	const clickToShowSensorsRemoved = () => {
		setShowSensorsRemoved(prevState => !prevState)
	}
	const clickToShowSensorsLost = () => {
		setShowSensorsLost(prevState => !prevState)
	}

	const clickToShowAnchorsUid = () => {
		setShowAnchorsUid(prevState => !prevState)
	}

	//━━━━━━━━ Change Floors Control Button ━━━━━━━━\\
	useEffect(() => {
		if (!map) return

		if (floorsControl) map.removeControl(floorsControl)

		const _floorsControl = new ChangeFloorControlNew({
			dispatch: dispatch,
			selectedSite: selectedSite,
			selectedFloorPlan: currentFloorPlan,
			floorPlans: floorPlanData,
			handleClick
		})
		map.addControl(_floorsControl, "bottom-left")
		setFloorsControl(_floorsControl)
	}, [floorPlanData, selectedSite, currentFloorPlan, map])

	// ━━━━━━━━ Select new floorPlan (control floors buttons)
	const handleClick = floorId => {
		if (!floorId) return

		const foundedFloorPlan = floorPlanData && floorPlanData.find(f => f.id === floorId)

		foundedFloorPlan && dispatch(actions.floorPlanSelected(foundedFloorPlan))
	}

	// ━━━━━━━━ Set fetched Active Sensors Data to state ━━━━━━━━
	useEffect(() => {
		if (!sensorsData || sensorsData.length === 0) return
		sensorsData && setActiveSensors(sensorsData)
	}, [sensorsData])

	// ━━━━━━━━━━━━━ Drawer Sensors ━━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map) return

		if (drawerControl) map.removeControl(drawerControl)

		const filteredSensors = sensorsPositioning?.filter(poi => poi.status !== "created")

		const _drawerControl = new DrawerSensors({
			selectedSite,
			openDrawerBar,
			setOpenDrawerBar,
			drawerOptions,
			setDrawerOptions,
			map,
			dispatch,
			activeSensors,
			positionSensors: filteredSensors,
			floorPlans: floorPlanData,
			floorPlan: currentFloorPlan,
			setGoTo,
			setShowSensorsOnline
		})

		map.addControl(_drawerControl, "top-right")
		setDrawerControl(_drawerControl)
	}, [
		map,
		selectedSite,
		openDrawerBar,
		drawerOptions,
		sensorsPositioning,
		activeSensors,
		floorPlanData,
		currentFloorPlan
	])

	// ━━━━━━━━━━━━━ Drawer List Sensors ━━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map) return

		if (drawerListControl) map.removeControl(drawerListControl)

		const div = document.getElementsByClassName("hide-search-drawer-bar")
		if (div && div.length > 0) {
			if (drawerListOptions.openList) {
				div[0].style.display = "none"
			} else {
				div[0].style.display = "block"
			}
		}

		const _drawerListControl = new DrawerListSensors({
			selectedSite,
			drawerListOptions, // → all drawer list options object
			map,
			sensorsPositionData: sensorsPositioning,
			drawerListMarkers,
			setDrawerListMarkers,
			handleDrawerListMarkerPositionChange,
			modelsInventory
		})

		map.addControl(_drawerListControl, "top-right")
		setDrawerListControl(_drawerListControl)
	}, [selectedSite, drawerListOptions, map, modelsInventory])

	// ━━━━━━━━━━━━━ Drawer List Sensors Handle Placed Marker Position Change ━━━━━━━━━━━━━ \\
	const handleDrawerListMarkerPositionChange = marker => {
		if (!currentFloorPlan || !selectedSite.id || !user) return

		const oldPoi = sensorsPositioning.find(poi => poi.uuid === marker.uuid)

		const updatedPoi = {
			...oldPoi,
			gpsData: new firebase.firestore.GeoPoint(marker.latitude, marker.longitude),
			markerOfPOIsId: oldPoi.id,
			floorPlanId: currentFloorPlan.id,
			status: marker.status
		}

		if (!oldPoi) return
		// dispatch(
		// 	poiActions.saveSensorSingleMarkers({
		// 		siteId: selectedSite.id,
		// 		marker: updatedPoi,
		// 		oldSensorDoc: oldPoi,
		// 		user: user,
		// 		network_address: selectedSite.netWirepas?.toString(),
		// 		wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId
		// 	})
		// )

		dispatch(
			poiActions.updateAnchor({
				oldAnchorDoc: oldPoi,
				network_address: selectedSite?.netWirepas?.toString() || null,
				wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId || null,
				nodes: [updatedPoi.id],
				status: marker.status,
				siteId: selectedSite.id,
				nodeType: "sensor",
				latitude: marker.latitude || null,
				longitude: marker.longitude || null,
				floorPlanId: currentFloorPlan.id || null,
				callersUserId: user.id,
				callersTenantId: user.tenantId || null,
				callersEmail: user.email,
				databaseId: selectedCustomer.databaseId || null
			})
		)

		// Update POIs state
		let newPointsOfInterest = [...sensorsPositioning]
		const poiIndex = newPointsOfInterest.findIndex(e => e.id === updatedPoi.id)

		newPointsOfInterest[poiIndex] = {
			...newPointsOfInterest[poiIndex],
			floorPlanId: currentFloorPlan.id,
			gpsData: new firebase.firestore.GeoPoint(marker.latitude, marker.longitude),
			edit: true,
			status: marker.status
		}

		setSensorsPositioning(newPointsOfInterest)

		const markerToRemove = drawerListMarkers.find(
			drawerMarker => drawerMarker.uuid === marker.uuid
		)
		if (markerToRemove) {
			markerToRemove.remove()
		}

		setCollectionChanged(true)
		return
	}

	//━━━━━━━━ GeoJson areas on map ━━━━━━━━\\
	useEffect(() => {
		//O "filter" é a variavel do floorPlan selecionado
		if (map && floorPlanData) {
			// Ã¢â€ â€œ O "filter" é a variavel com todas as zonas do floorPlan selecionado e estÃƒÂ¡ sempre c/ a data actualizada.
			const filter = floorPlanData.find(floor => floor.id === currentFloorPlan?.id)

			// Ã¢â€ â€œ map animation duration at start
			const animationOptions = {
				duration: 0,
				animate: false
			}

			// Ã¢â€ â€œ If exists is this floorPlan mapOrientation it displays orientation from db
			if (Boolean(filter?.mapOrientation) === true) {
				const lat = filter.mapOrientation.center.latitude
				const lng = filter.mapOrientation.center.longitude
				map.setZoom(filter.mapOrientation.zoom)
				map.setBearing(filter.mapOrientation.bearing)
				map.setPitch(filter.mapOrientation.pitch)
				map.setCenter([lng, lat])
				const newData = {
					...filter.mapOrientation,
					center: { lng: lng, lat: lat }
				}
			} else {
				// Ã¢â€ â€œ Onde é centrado o mapa por default
				filter?.coords?.topLeftCorner?.coords?.longitude &&
					map.fitBounds(
						[
							[
								filter?.coords?.topLeftCorner?.coords?.longitude,
								filter?.coords?.topLeftCorner?.coords?.latitude
							],
							[
								filter?.coords?.bottomRightCorner?.coords?.longitude,
								filter?.coords?.bottomRightCorner?.coords?.latitude
							]
						],
						{ duration: 0 }
					)
			}

			// Codigo para por a imagem no mapa (NÃƒÂ£o esqueÃƒÂ§er de remover a layer e a source antiga antes de por as novas)
			if (map) {
				let layer = map.getLayer("floorPlanLayer")
				if (typeof layer !== "undefined") {
					map.removeLayer("floorPlanLayer")
				}
				let source = map.getSource("floorPlanSource")
				if (typeof source !== "undefined") {
					map.removeSource("floorPlanSource")
				}
			}
			if (map && filter) {
				if (filter?.imageUrl) {
					// Add a data source containing GeoJSON data. (floorPlan/planta on background)
					map.addSource("floorPlanSource", {
						type: "image",
						url: filter?.imageUrl,
						coordinates: [
							[
								filter.coords.topLeftCorner?.coords?.longitude,
								filter.coords.topLeftCorner?.coords?.latitude
							],
							[
								filter.coords.topRightCorner?.coords?.longitude,
								filter.coords.topRightCorner?.coords?.latitude
							],
							[
								filter.coords.bottomRightCorner?.coords?.longitude,
								filter.coords.bottomRightCorner?.coords?.latitude
							],
							[
								filter.coords.bottomLeftCorner?.coords?.longitude,
								filter.coords.bottomLeftCorner?.coords?.latitude
							]
						]
					})
					map.addLayer(
						{
							id: "floorPlanLayer",
							source: "floorPlanSource",
							type: "raster",
							paint: {
								"raster-opacity": 0.8
							}
						},
						"state-label"
					)
				}

				let finalFeatures = []

				if (filter?.geoJson) {
					filter.geoJson.features.forEach((feature, index) => {
						let currentType = roomTypes?.find(type => type.id === feature.properties.type)
						let coordinates = []
						feature.geometry.coordinates.forEach(coords => {
							coordinates.push([coords.longitude, coords.latitude])
						})
						finalFeatures.push({
							type: filter.geoJson.features[index].type,
							properties: {
								...filter.geoJson.features[index].properties,
								color: currentType ? currentType.color : "#e9ecef",
								outline: currentType ? currentType.color : "#dee2e6",
								opacity: 0.4
							},
							geometry: {
								type: filter.geoJson.features[index].geometry.type,
								coordinates: [coordinates]
							}
						})
					})

					let finalLayer = {
						type: "FeatureCollection",
						features: finalFeatures
					}
					if (finalFeatures.length === 0) {
						dispatch({
							type: "SNACKBAR_WARNING",
							payload: { message: `Note: The selected floor doesn't have Areas created.` }
						})
					}

					addGeoJsonLayerToMap({
						map: map,
						geoJson: finalLayer
					})
				}
			}
		}
	}, [currentFloorPlan, floorPlanData, map])

	// ━━━━━━━━━━━ Sensors ━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !selectedSite || !currentFloorPlan) return
		if (!activeSensors || activeSensors.length <= 0) return

		addNodesToMap({
			map,
			selectedSite,
			selectedCustomer,
			user,
			currentFloorPlan,
			nodesData: activeSensors,
			markers: sensorsMarkers,
			setMarkers: setSensorsMarkers,
			showOnline: showSensorsOnline,
			showRemoved: showSensorsRemoved,
			showLost: showSensorsLost,
			showAnchorsUid: showAnchorsUid,
			setShowActiveModal,
			setActiveNodeToUpdate: setActiveSensorToUpdate,
			setShowBigImageModalActive,
			setSelectedBigImageActive,
			setSelectedImageIndexActive,
			removePopupFunction,
			setActiveNodes: setActiveSensors,
			defaultSrc: "/markers/sensor.svg",
			dragMarker,
			dragSvg,
			nodeType: "sensor",
			poiActions,
			firebase,
			dispatch
		})
	}, [
		map,
		selectedSite,
		currentFloorPlan,
		activeSensors,
		showSensorsOnline,
		showSensorsRemoved,
		showSensorsLost,
		showAnchorsUid,
		dragMarker
	])

	// Change color of the showSensorsOnline marker on map when clicked
	useEffect(() => {
		if (!map) return

		const showSensorsButton = document.getElementsByClassName("mapbox-gl-show-sensors-online")
		const showSensorsRemovedButton = document.getElementsByClassName(
			"mapbox-gl-show-sensors-removed"
		)
		const showSensorsLostButton = document.getElementsByClassName("mapbox-gl-show-sensors-lost")
		const showAnchorsUidButton = document.getElementsByClassName("mapbox-gl-show-anchors-uid")

		if (showSensorsOnline) {
			if (showSensorsButton && showSensorsButton[0]) {
				showSensorsButton.forEach(val => {
					val.style.backgroundColor = "#2649AA"
				})
			}
		} else {
			if (showSensorsButton && showSensorsButton[0]) {
				showSensorsButton.forEach(val => {
					val.style.backgroundColor = "#132659"
				})
			}
		}
		if (showSensorsRemoved) {
			if (showSensorsRemovedButton && showSensorsRemovedButton[0]) {
				showSensorsRemovedButton.forEach(val => {
					val.style.backgroundColor = "#ff0000"
				})
			}
		} else {
			if (showSensorsRemovedButton && showSensorsRemovedButton[0]) {
				showSensorsRemovedButton.forEach(val => {
					val.style.backgroundColor = "#b00000"
				})
			}
		}
		if (showSensorsLost) {
			if (showSensorsLostButton && showSensorsLostButton[0]) {
				showSensorsLostButton.forEach(val => {
					val.style.backgroundColor = "#A43912"
				})
			}
		} else {
			if (showSensorsLostButton && showSensorsLostButton[0]) {
				showSensorsLostButton.forEach(val => {
					val.style.backgroundColor = "#5f2009"
				})
			}
		}

		const removeClass = showAnchorsUid
			? "mapbox-gl-show-anchors-uid-image-show-uuid"
			: "mapbox-gl-show-anchors-uid-image-dont-show-uuid"
		const addClass = showAnchorsUid
			? "mapbox-gl-show-anchors-uid-image-dont-show-uuid"
			: "mapbox-gl-show-anchors-uid-image-show-uuid"
		if (showAnchorsUid) {
			if (showAnchorsUidButton && showAnchorsUidButton[0]) {
				showAnchorsUidButton.forEach(val => {
					val.classList.remove(removeClass)
					val.classList.add(addClass)
				})
			}
		} else {
			if (showAnchorsUidButton && showAnchorsUidButton[0]) {
				showAnchorsUidButton.forEach(val => {
					val.classList.remove(removeClass)
					val.classList.add(addClass)
				})
			}
		}
	}, [map, showSensorsOnline, showSensorsRemoved, showSensorsLost, showAnchorsUid])

	// ━━━━━━━━ Add Sensors Position Markers ━━━━━━━━
	useEffect(() => {
		markers &&
			markers.forEach(m => {
				m && m.remove()
			})

		let allMarkers = []

		sensorsPositioning &&
			sensorsPositioning.forEach(poi => {
				if (
					map &&
					poi.floorPlanId === currentFloorPlan?.id &&
					!poi.approved &&
					poi.status !== "created" &&
					poi.status !== "assigned" &&
					poi.status !== "unassigned"
				) {
					let marker = document.createElement("div")

					marker.id = poi.uuid
					marker.style.cursor = !dragMarker ? "pointer" : "grab"
					marker.style.backgroundSize = "34px"
					marker.style.backgroundPosition = "center"
					marker.style.opacity = "1"

					// se o marker estiver no centro é considerado como novo, p o user saber q ainda n moveu o marker.
					let markerOnCenter = false
					const { lng, lat } = map.getCenter()

					if (poi?.gpsData?.latitude === lat || poi?.gpsData?.longitude === lng) {
						markerOnCenter = true
					}

					marker.className =
						poi.status === "placed"
							? "sensorMarkersDesignStudio_placed"
							: poi.new || markerOnCenter
							? "sensorMarkersDesignStudio_new"
							: "sensorMarkersDesignStudio"

					const popupStickNew = !dragMarker ? (
						<PopupStickOnHoverManageInfrastructure
							component={
								<div
									style={{
										background: "#ECF1F4",
										boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
										borderRadius: "8px",
										fontFamily: "Poppins",
										fontStyle: "normal",
										color: "#4A4A68",
										paddingBottom: "2px",
										width: "276px"
									}}
								>
									<Carousel
										indicators={false}
										controls={poi?.imagesUrls?.length > 1}
										interval={1500}
									>
										{poi?.imagesUrls?.length > 0 &&
											poi.imagesUrls.map((arrayUrl, i) => (
												<Carousel.Item key={i} interval={1000}>
													<img
														src={arrayUrl}
														alt="sensors position"
														style={{
															borderRadius: "5px 5px 0px 0px",
															height: "90px",
															width: "276px",
															objectFit: "cover",
															objectPosition: "center",
															cursor: "pointer" // Add this to change the cursor to a pointer
														}}
														onClick={e => {
															setSelectedBigImage(poi)
															setSelectedImageIndexPosition(i)
															setShowBigImageModal(true)
															removePopupFunction("popup_stick_active_sensors") // remove popup when click
														}}
													/>
												</Carousel.Item>
											))}{" "}
									</Carousel>
									<div className="d-flex justify-content-between align-items-center popup_title">
										<span>{poi.uuid}</span>
										<div className="d-flex">
											<div className="mr-2">
												<OverlayTrigger
													placement="top"
													overlay={<Tooltip>Unplace</Tooltip>}
												>
													<Button
														className="sensor_position_popup_edit_btn"
														color="primary"
														startIcon={
															<svg
																width="18"
																height="18"
																viewBox="0 0 25 26"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M22.2228 11.3097H20.8705C20.8684 9.51709 20.1554 7.79854 18.8878 6.531C17.6203 5.26346 15.9018 4.55043 14.1092 4.54834V3.19608C16.2603 3.19842 18.3227 4.054 19.8438 5.57508C21.3649 7.09617 22.2204 9.15853 22.2228 11.3097ZM18.8421 15.3665H21.5466V16.7187H18.8421V18.7471C18.8416 19.1056 18.699 19.4493 18.4455 19.7028C18.192 19.9563 17.8483 20.0989 17.4898 20.0994H15.4614V22.8039H14.1092V20.0994H10.0524V22.8039H8.70012V20.0994H6.67172C6.31321 20.099 5.96949 19.9564 5.71598 19.7029C5.46247 19.4494 5.31987 19.1056 5.31946 18.7471V16.7187H2.61493V15.3665H5.31946V11.3097H2.61493V9.9574H5.31946V7.929C5.31982 7.57047 5.4624 7.22672 5.71592 6.9732C5.96944 6.71968 6.31319 6.5771 6.67172 6.57674H8.70012V3.87221H10.0524V6.57674H12.7569V7.92839L6.67172 7.929V18.7471H17.4898V12.6619H18.8421V15.3665ZM8.70012 16.7187H15.4614V9.9574H8.70012V16.7187ZM14.1092 15.3665H10.0524V11.3097H14.1092V15.3665ZM17.4898 11.3097H18.8421C18.8406 10.0549 18.3415 8.85193 17.4542 7.96466C16.5669 7.07739 15.364 6.57826 14.1092 6.57674V7.929C15.0055 7.93006 15.8647 8.28657 16.4985 8.92034C17.1323 9.55411 17.4888 10.4134 17.4898 11.3097Z"
																	fill="#E65019"
																/>
																<path
																	d="M24.2063 1.4576C24.5027 1.75401 24.5027 2.23459 24.2063 2.53101L2.19495 24.5424C1.89853 24.8388 1.41795 24.8388 1.12154 24.5424C0.825128 24.246 0.825128 23.7654 1.12154 23.469L23.1329 1.4576C23.4293 1.16119 23.9099 1.16119 24.2063 1.4576Z"
																	fill="#E65019"
																	stroke="#FBE6DE"
																/>
															</svg>
														}
														onClick={() => {
															handleShowDeleteModal(poi)
															removePopupFunction("popup_stick_active_sensors")
														}}
													/>
												</OverlayTrigger>
											</div>

											<div>
												<OverlayTrigger
													placement="top"
													overlay={<Tooltip>Edit</Tooltip>}
												>
													<Button
														className="sensor_active_popup_edit_btn"
														color="primary"
														startIcon={
															<BiEditAlt
																style={{
																	fontSize: "17px"
																}}
															/>
														}
														onClick={() => {
															setShowEdit(true)
															// setShowCreatePOIs(false)
															updatePointOfInterest(poi.uuid)
															removePopupFunction("popup_stick_active_sensors")
														}}
													/>
												</OverlayTrigger>
											</div>
										</div>
									</div>
									<Info sensorPosition={poi} />
								</div>
							}
							placement="top"
							onMouseEnter={() => {}}
							delay={500}
							className="popup_stick_active_sensors"
						>
							<div
								style={{
									width: "38px",
									height: "38px"
								}}
							>
								{showAnchorsUid && RenderUuidDiv({ uuid: poi.uuid })}{" "}
								<img
									src="/markers/sensor.svg"
									alt="marker"
									className="iconImageSensorsPosition"
								/>
							</div>
						</PopupStickOnHoverManageInfrastructure>
					) : (
						<PopupStickOnHoverManageInfrastructure
							component={<div></div>}
							placement="top"
							onMouseEnter={() => {}}
							delay={500}
							className="popup_stick_active_sensors"
						>
							<div
								style={{
									width: "34px",
									height: "34px"
								}}
							>
								{showAnchorsUid && RenderUuidDiv({ uuid: poi.uuid })}{" "}
								<img
									src={dragSvg}
									alt="drag marker"
									className="iconImageSensorsPositionDrag"
								/>
							</div>
						</PopupStickOnHoverManageInfrastructure>
					)

					// Render the marker with popup sticky
					ReactDOM.render(popupStickNew, marker)

					// Create the marker
					const markerWithImage =
						poi?.gpsData &&
						new mapboxgl.Marker(marker, {
							draggable: dragMarker
						}).setLngLat([poi?.gpsData?.longitude, poi?.gpsData?.latitude])

					// Add marker to map
					poi?.gpsData &&
						markerWithImage /*.setPopup(popup)*/
							.addTo(map)

					// Clicking on the marker closes the showEdit box
					poi?.gpsData &&
						markerWithImage.getElement().addEventListener("dblclick", () => {
							setShowEdit(false)
							// setShowCreatePOIs(false)
						})

					// When dragging the marker make sure that the popover doesn't get displayed
					poi?.gpsData &&
						markerWithImage.on("drag", () => {
							const popupElement = document.getElementById("popover")

							if (popupElement) {
								popupElement.style.display = "none"
							}
						})

					poi?.gpsData &&
						markerWithImage.on("dragend", val => {
							const lngLat = val.target.getLngLat()

							// Update POIs state
							let newPointsOfInterest = [...sensorsPositioning]
							const poiIndex = newPointsOfInterest.findIndex(e => e.id === poi.id)

							newPointsOfInterest[poiIndex] = {
								...newPointsOfInterest[poiIndex],
								floorPlanId: currentFloorPlan.id,
								gpsData: new firebase.firestore.GeoPoint(lngLat.lat, lngLat.lng),
								edit: true
							}

							setSensorsPositioning(newPointsOfInterest)

							//  Save markers on dragend
							const newMarkerPosition = {
								...poi,
								gpsData: new firebase.firestore.GeoPoint(lngLat.lat, lngLat.lng),
								markerOfPOIsId: poi.id,
								floorPlanId: currentFloorPlan.id
							}
							// dispatch(
							// 	poiActions.saveSensorSingleMarkers({
							// 		siteId: selectedSite.id,
							// 		marker: newMarkerPosition,
							// 		oldSensorDoc: poi,
							// 		user: user
							// 	})
							// )
							dispatch(
								poiActions.updateAnchor({
									oldAnchorDoc: poi,
									network_address: selectedSite?.netWirepas?.toString() || null,
									wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId || null,
									nodes: [poi.id],
									status: poi.status,
									siteId: selectedSite.id,
									nodeType: "sensor",
									latitude: lngLat.lat || null,
									longitude: lngLat.lng || null,
									floorPlanId: currentFloorPlan.id || null,
									callersUserId: user.id,
									callersTenantId: user.tenantId || null,
									callersEmail: user.email,
									databaseId: selectedCustomer.databaseId || null
								})
							)
						})

					allMarkers.push(markerWithImage)
				}
			})

		setMarkers(allMarkers)

		// Change color of the dragMarker button on map when clicked
		const showSensorsButton = document.getElementsByClassName("mapbox-gl-drag-sensors")

		if (dragMarker) {
			if (showSensorsButton && showSensorsButton[0]) {
				showSensorsButton.forEach(el => {
					el.style.backgroundColor = "#6d6d6d"
				})
			}
		} else {
			if (showSensorsButton && showSensorsButton[0]) {
				showSensorsButton.forEach(el => {
					el.style.backgroundColor = "#FFFFFF"
				})
			}
		}
	}, [map, currentFloorPlan, sensorsPositioning, selectedSite, user, dragMarker, showAnchorsUid])

	//━━━━━━━━ Functions ━━━━━━━━\\
	// maxNumber of photos added by user
	const maxNumber = 10

	// ━━━━━━━━ Open and close Modal 2
	const handleCloseModal2 = () => setShowModal2(false)
	const handleShowModal2 = () => {
		setShowModal2(true)
		poiToUpdate?.imagesUrls && setImagesModal2(poiToUpdate?.imagesUrls)
	}

	// ━━━━━━━━ Open and close Delete Modal 3
	const handleCloseDeleteModal = () => {
		setShowDeleteModal(false)
		setPoiIdToDelete(null)
	}
	const handleCloseDeleteModalAndDeletePOI = () => {
		unplaceNodes(poiIdToDelete?.id)
		handleCloseDeleteModal()
	}

	// ━━━━━━━━ Saves poi.id to be deleted
	const handleShowDeleteModal = poi => {
		setShowDeleteModal(true)
		setPoiIdToDelete(poi)
	}

	const storageRef = storage.ref()

	// ━━━━━━━━ Set images to state if sensor to edit
	useEffect(() => {
		if (!poiToUpdate) return
		setImagesModal2(poiToUpdate?.imagesUrls)
		setUrlsModal2(poiToUpdate?.imagesUrls)
	}, [poiToUpdate])

	// ━━━━━━━━ Show uploaded images for that specific POI that we are editing
	const onChangeModal2 = (imageList, addUpdateIndex) => {
		const urlsDeleted =
			imagesModal2 && imagesModal2.filter(urls => !imageList.some(val => val === urls))

		urlsDeleted && setUrlsToDelete(prevState => [...prevState, ...urlsDeleted])
		setImagesModal2(imageList)
	}

	// ━━━━━━━━ Show uploaded images for that specific Active Sensor that we are editing
	const onChangeModal3 = (imageList, addUpdateIndex) => {
		const urlsDeleted =
			imagesActiveAddPhotos &&
			imagesActiveAddPhotos.filter(urls => !imageList.some(val => val === urls))

		urlsDeleted && setUrlsToDelete(prevState => [...prevState, ...urlsDeleted])

		setImagesActiveAddPhotos(imageList)
	}

	// ━━━━━━━━ Save Updated Active sensor ━━━━━━━━
	const handleSubmitUpdateActiveSensor = async () => {
		// if uploaded new images on sensor edit
		const getImagesArray = async () => {
			if (Array.isArray(imagesActiveAddPhotos) && imagesActiveAddPhotos.length > 0) {
				const filteredArr = imagesActiveAddPhotos.filter(item => typeof item !== "string")

				if (filteredArr.length === 0) {
					return []
				}

				let res = await Promise.all(
					filteredArr.map(async image => await compressUpload3(image))
				)

				return res
			} else {
				return []
			}
		}

		const imagesArray = await getImagesArray()

		// Compress, upload and return array of urlsWebcam
		const getPhotosTakenArray = async () => {
			if (urlsActiveWebcamToUpdate && urlsActiveWebcamToUpdate.length !== 0) {
				let res = await Promise.all(
					urlsActiveWebcamToUpdate.map(
						async image => await compressUploadWithoutDotFile(image)
					)
				)

				return res
			} else {
				return []
			}
		}

		const photoArray = await getPhotosTakenArray()

		// Filter urls that were deleted
		const urlsFilteredFromFirestore =
			(urlsModal2Active &&
				urlsModal2Active.length > 0 &&
				urlsModal2Active.filter(urls => !urlsToDelete.some(val => val === urls))) ||
			[]

		// Join newUrlsArray with urlsFilteredFromFirestore
		const newImageArray = [...urlsFilteredFromFirestore, ...imagesArray, ...photoArray]

		// Save in firestore
		// dispatch(
		// 	poiActions.updateActiveSensorPosition({
		// 		data: {
		// 			...activeSensorToUpdate,
		// 			id: activeSensorToUpdate.uuid
		// 		},
		// 		siteId: selectedSite.id,
		// 		user: user,
		// 		newImagesUrlsArray: newImageArray ? newImageArray : [],
		// 		oldSensorDoc: {
		// 			...oldSensorDoc,
		// 			id: oldSensorDoc.uuid.toString()
		// 		},
		// 		customerId: selectedCustomerId,
		// 		wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId
		// 	})
		// )
		dispatch(
			poiActions.updateAnchor({
				oldAnchorDoc: [activeSensorToUpdate?.uuid?.toString()],
				network_address: selectedSite.netWirepas?.toString() || null,
				wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId || null,
				nodes: [activeSensorToUpdate?.uuid?.toString()],
				status: activeSensorToUpdate.status,
				description: activeSensorToUpdate.description || null,
				siteId: selectedSite.id,
				nodeType: "sensor",
				latitude: activeSensorToUpdate.gpsData.latitude || null,
				longitude: activeSensorToUpdate.gpsData.longitude || null,
				floorPlanId: currentFloorPlan.id || null,
				callersUserId: user.id,
				callersTenantId: user.tenantId || null,
				callersEmail: user.email,
				databaseId: selectedCustomer.databaseId || null,
				imagesUrls: newImageArray ? newImageArray : []
			})
		)
			.then(() => {
				// Change save/edit button
				const btnUploadFile = document.getElementById("buttonToProgress_editPoi")
				btnUploadFile &&
					finishButtonProgress(
						btnUploadFile,
						"button__progress_editPoi",
						"button__text_editPoi",
						100
					)
			})
			.then(() => {
				setTimeout(() => {
					setShowActiveModal(false)
				}, [500])
			})
			.then(() => {
				const newSensorsPositioning = activeSensors.map(anchor => {
					if (anchor.uuid === activeSensorToUpdate?.uuid) {
						return {
							...anchor,
							id: activeSensorToUpdate?.uuid?.toString(),
							status: activeSensorToUpdate.status,
							description: activeSensorToUpdate.description || null,
							imagesUrls: newImageArray
								? newImageArray
								: activeSensorToUpdate.imagesUrls || []
						}
					}
					return anchor
				})

				setActiveSensors(newSensorsPositioning)

				setProgress(0)
				setImagesActiveAddPhotos([])
				setUrlsModal2Active([])
				setUrlsToDelete([])
				setUrlsActiveWebcamToUpdate([])
				handleGetAll()
				setActiveSensorToUpdate(null)
				setOldSensorDoc()
				// setCollectionChanged(true)
			})

		// Delete from storage the removed images on Sensor edit
		const imageNamesArray = []

		allImagesInStorage &&
			allImagesInStorage.map(image => {
				urlsToDelete &&
					urlsToDelete.filter(poiUrls => {
						if (image.url === poiUrls) {
							imageNamesArray.push(image.url)
						}
						return null
					})
			})

		imageNamesArray.length > 0 && handleDeleteFromStorage(imageNamesArray) // delete from storage
	}

	// ━━━━━━━━ Save Updated Sensor ━━━━━━━━
	const handleSubmitUpdatePOI = async () => {
		const getImagesArray = async () => {
			if (Array.isArray(imagesModal2) && imagesModal2.length > 0) {
				const filteredArr = imagesModal2.filter(item => typeof item !== "string")

				if (filteredArr.length === 0) {
					return []
				}

				let res = await Promise.all(
					filteredArr.map(async image => await compressUpload3(image))
				)

				return res
			} else {
				return []
			}
		}

		const imagesArray = await getImagesArray()

		// Compress, upload and return array of urlsWebcam
		const getPhotosTakenArray = async () => {
			if (urlsWebcamToUpdate && urlsWebcamToUpdate.length !== 0) {
				let res = await Promise.all(
					urlsWebcamToUpdate.map(async image => await compressUploadWithoutDotFile(image))
				)

				return res
			} else {
				return []
			}
		}

		const photoArray = await getPhotosTakenArray()

		// Filter urls that were deleted
		const urlsFilteredFromFirestore =
			(urlsModal2 &&
				urlsModal2.length > 0 &&
				urlsModal2.filter(urls => !urlsToDelete.some(val => val === urls))) ||
			[] // Ensure urlsFilteredFromFirestore is always an array

		// Join newUrlsArray with urlsFilteredFromFirestore
		const newImageArray = [...urlsFilteredFromFirestore, ...imagesArray, ...photoArray]

		// Save in firestore
		// dispatch(
		// 	poiActions.updateSensorPosition({
		// 		data: {
		// 			...poiToUpdate,
		// 			id: poiToUpdate?.uuid
		// 		},
		// 		siteId: selectedSite.id,
		// 		user: user,
		// 		newImagesUrlsArray: newImageArray ? newImageArray : [],
		// 		oldSensorDoc: {
		// 			...oldSensorDoc,
		// 			id: oldSensorDoc?.uuid?.toString()
		// 		},
		// 		customerId: selectedCustomerId
		// 	})
		// )
		dispatch(
			poiActions.updateAnchor({
				oldAnchorDoc: [poiToUpdate?.uuid?.toString()],
				network_address: selectedSite.netWirepas?.toString() || null,
				wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId || null,
				nodes: [poiToUpdate?.uuid?.toString()],
				status: poiToUpdate.status,
				description: poiToUpdate.description || null,
				siteId: selectedSite.id,
				nodeType: "sensor",
				latitude: poiToUpdate.gpsData.latitude || null,
				longitude: poiToUpdate.gpsData.longitude || null,
				floorPlanId: currentFloorPlan.id || null,
				callersUserId: user.id,
				callersTenantId: user.tenantId || null,
				callersEmail: user.email,
				databaseId: selectedCustomer.databaseId || null,
				imagesUrls: newImageArray ? newImageArray : []
			})
		)
			.then(() => {
				// Change save/edit button
				const btnUploadFile = document.getElementById("buttonToProgress_editPoi")
				btnUploadFile &&
					finishButtonProgress(
						btnUploadFile,
						"button__progress_editPoi",
						"button__text_editPoi",
						100
					)
			})
			.then(() => {
				setTimeout(() => {
					setShowEdit(false)
				}, [500])
			})
			// .then(() => {
			// 	dispatch(
			// 		poiActions.fetchSensorsPosition({
			// 			siteId: selectedSite?.id
			// 		})
			// 	)
			// })
			.then(() => {
				const newSensorsPositioning = sensorsPositioning.map(sensor => {
					if (sensor.uuid === poiToUpdate?.uuid) {
						return {
							...sensor,
							id: poiToUpdate?.uuid?.toString(),
							status: poiToUpdate.status,
							description: poiToUpdate.description || null,
							imagesUrls: newImageArray ? newImageArray : poiToUpdate.imagesUrls || []
						}
					}
					return sensor
				})

				setSensorsPositioning(newSensorsPositioning)

				setProgress(0)
				setImagesModal2([])
				setUrlsModal2([])
				setUrlsToDelete([])
				setUrlsWebcamToUpdate([])
				handleGetAll()
				setPoiToUpdate()
				setOldSensorDoc()
				setCollectionChanged(true)
			})

		// Delete from storage the removed images on Sensor edit
		const imageNamesArray = []

		allImagesInStorage &&
			allImagesInStorage.map(image => {
				urlsToDelete &&
					urlsToDelete.filter(poiUrls => {
						if (image.url === poiUrls) {
							imageNamesArray.push(image.url)
						}
						return null
					})
			})

		imageNamesArray.length > 0 && handleDeleteFromStorage(imageNamesArray) // delete from storage
	}

	// ━━━━━━━━ Save Approved Active sensors ━━━━━━━━
	const saveApprovedSensors = placedData => {
		setCollectionChanged(true)
		placedData &&
			placedData.forEach(val => {
				dispatch(sensorUnplaced({ id: val.id }))
			})
	}

	// ━━━━━━━━ Compress image on Sensors edit
	const compressUpload3 = async image => {
		var options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true
		}

		const randomNumber = uuidv4()

		return new Promise(function (resolve, reject) {
			imageCompression(image.file, options)
				.then(function (compressedFile) {
					// write upload logic
					const uploadTask = storageRef
						.child(
							`Sites/${selectedSite?.id}/sensorsImages/${compressedFile.name + randomNumber}`
						)
						.put(compressedFile)

					uploadTask.on(
						"state_changed",
						snapshot => {
							const progress = Math.round(
								(snapshot.bytesTransferred / snapshot.totalBytes) * 100
							)
							setProgress(progress)
						},
						error => {
							setProgress(0)
							reject()
						},
						() => {
							uploadTask.snapshot.ref.getDownloadURL().then(urlDownloaded => {
								resolve(urlDownloaded)
							})
						}
					)
				})
				.catch(function (error) {
					console.log(error.message)
				})
		})
	}

	// ━━━━━━━━ Compress image on taken picture without image.file.
	const compressUploadWithoutDotFile = async image => {
		var options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true
		}

		let imageUrl = image
		const randomNumber = uuidv4()

		return new Promise(function (resolve, reject) {
			imageCompression
				.getFilefromDataUrl(imageUrl, randomNumber)
				.then(function (compressedFile) {
					// write upload logic
					const uploadTask = storageRef
						.child(`Sites/${selectedSite?.id}/sensorsImages/${compressedFile.name}`)
						.put(compressedFile)

					uploadTask.on(
						"state_changed",
						snapshot => {
							const progress = Math.round(
								(snapshot.bytesTransferred / snapshot.totalBytes) * 100
							)
							setProgress(progress)
						},
						error => {
							setProgress(0)
							reject()
						},
						() => {
							uploadTask.snapshot.ref.getDownloadURL().then(urlDownloaded => {
								resolve(urlDownloaded)
							})
						}
					)
				})
				.catch(function (error) {
					console.log(error.message)
				})
		})
	}

	// ━━━━━━━━ List/get all imagens from storage db ━━━━━━━━
	const handleGetAll = () => {
		const imagesRef = storageRef.child(`Sites/${selectedSite?.id}/sensorsImages`)
		imagesRef.listAll().then(data => {
			let allImagesInStorageArray = []
			data.items.forEach(itemRef => {
				itemRef.getDownloadURL().then(url => {
					allImagesInStorageArray.push({
						name: itemRef.name,
						url: url
					})
				})
			})

			setAllImagesInStorage(allImagesInStorageArray)
			return allImagesInStorageArray
		})
	}

	// ━━━━━━━━ Get all images in storage
	useEffect(() => {
		handleGetAll()
	}, [])

	// ━━━━━━━━ Delete from storage handler
	const handleDeleteFromStorage = array => {
		array &&
			array.map(item => {
				const foundMatchOfImageUrl =
					allImagesInStorage && allImagesInStorage.find(image => image?.url === item)

				return storageRef
					.child(`Sites/${selectedSite.id}/sensorsImages/${foundMatchOfImageUrl.name}`)
					.delete()
					.then(() => {})
			})
	}

	//   ━━━━━━ Fetch Sensors to update ━━━━━━
	const updatePointOfInterest = id => {
		if (sensorsPositioning) {
			dispatch(
				poiActions.fetchToUpdateSensorAction({
					siteId: selectedSite.id,
					id: id
				})
			)
		}
	}

	//todo can go Handler
	const removePopupFunction = stringOfElement => {
		// remove popup when click
		const el = document.getElementsByClassName(stringOfElement)
		if (el) {
			el && el.forEach(val => (val.style.display = "none"))
		}
	}

	// ━━━━━━━━ Unplace Nodes
	const unplaceNodes = async (id = null) => {
		let selectedData = []

		if (id) {
			selectedData.push(id)
		} else {
			selectedData = selectedRows
		}

		//different actions so it doenst cause confusion
		// dispatch(
		// 	poiActions.unplaceSensors({
		// 		siteId: selectedSite.id,
		// 		ids: selectedData,
		// 		user: user
		// 	})
		// ).then(() => {
		// 	setCollectionChanged(true)
		// })

		// setIdsToUnplace(selectedData)

		dispatch(
			poiActions.updateAnchor({
				nodes: selectedData,
				status: "assigned",
				siteId: selectedSite.id,
				nodeType: "sensor",
				oldAnchorDoc: selectedData,
				callersUserId: user.id,
				databaseId: selectedCustomer.databaseId || null
			})
		).then(() => {
			const updatedSensorsPositioning =
				sensorsPositioning &&
				sensorsPositioning.map(poi => {
					if (selectedData.includes(poi.id)) {
						return {
							...poi,
							status: "assigned"
						}
					}
					return poi // Return the original object if it's not in selectedData
				})
			setSensorsPositioning(updatedSensorsPositioning)

			//updates drag and drop table
			setCollectionChanged(true)
			// setShowDeleteSeveral(false)
		})
	}

	// ━━━━━━━━ placedOnSite Node on edit
	const handleChangeCheckbox = event => {
		setChecked(event.target.checked)
		setPoiToUpdate({
			...poiToUpdate,
			status: event.target.checked ? "placed" : "planned"
		})
	}

	// ━━━━━━━━ Open and close Webcam Modal
	const handleCloseWebcamModal = () => {
		setShowWebcamModal(false)
	}

	// ━━━━━━━━ Open and close Webcam Update Modal
	const handleCloseWebcamUpdateModal = () => {
		setShowWebcamUpdateModal(false)
	}

	// ━━━━━━━━ Create Customm Toolbar for CardHeader with 'Positioning State' button
	const customCardHeaderToolbar = (
		<OverlayTrigger
			placement="bottom"
			overlay={<Tooltip id="positioning-tooltip">Open Sensors List</Tooltip>}
		>
			<Button
				text="Position Sensors"
				color="primary"
				variant="contained"
				onClick={() =>
					setDrawerListOptions(prevState => ({
						openList: !prevState.openList
					}))
				}
			/>
		</OverlayTrigger>
	)

	return (
		<Card>
			<CardHeader
				title={
					<>
						<div className="p-5">Sensors</div>
					</>
				}
				toolbar={customCardHeaderToolbar}
			/>
			<CardBody padding="0px">
				<div ref={mapcontainerref} style={{ height: "600px" }} id="mapbox-sensors-container" />
				<div className="mt-5">
					<NodesTable
						initialData={initialData}
						setInitialData={setInitialData}
						nodesPositioning={sensorsPositioning}
						selectedRows={selectedRows}
						setSelectedRows={setSelectedRows}
						setShowDeleteSeveral={setShowDeleteModal}
						showApproveModal={showApproveModal}
						setShowApproveModal={setShowApproveModal}
						saveApprovedNodes={saveApprovedSensors}
						columns={[
							{ field: "uuid", headerName: "UUID", width: 150 },
							{
								field: "status",
								headerName: "Sensor Status",
								width: 200
							},
							{
								field: "sinks",
								headerName: "Sinks",
								width: 200
							},
							{
								field: "description",
								headerName: "Description",
								width: 200,

								renderCell: params => (
									<div
										style={{
											height: "100%",
											overflowX: "auto"
										}}
									>
										{params.value}
									</div>
								)
							},
							{ field: "floorPlanName", headerName: "Floorplan", width: 200 }
						]}
						unplaceIcon="/media/svg/icons/General/unplace-sensor.svg"
						nodeType="sensor"
						// disableApprove={true}
					/>
				</div>
			</CardBody>

			{poiToUpdate && (
				<EditNodeModal
					showEdit={showEdit}
					setShowEdit={setShowEdit}
					showModal2={showModal2}
					setImagesModal2={setImagesModal2}
					setUrlsToDelete={setUrlsToDelete}
					setOldNodeDoc={setOldSensorDoc}
					poiToUpdate={poiToUpdate}
					setPoiToUpdate={setPoiToUpdate}
					setShowWebcamUpdateModal={setShowWebcamUpdateModal}
					urlsWebcamToUpdate={urlsWebcamToUpdate}
					imagesModal2={imagesModal2}
					onChangeModal2={onChangeModal2}
					maxNumber={maxNumber}
					handleShowModal2={handleShowModal2}
					handleCloseModal2={handleCloseModal2}
					setShowModal2={setShowModal2}
					urlsToDelete={urlsToDelete}
					checked={checked}
					handleChangeCheckbox={handleChangeCheckbox}
					setUrlsModal2={setUrlsModal2}
					startButtonProgress={startButtonProgress}
					handleSubmitUpdatePOI={handleSubmitUpdatePOI}
					activeNodes={activeSensors}
					nodesPositioning={sensorsPositioning}
					title="Sensor"
				/>
			)}

			<EditActiveNodeModal
				showActiveModal={showActiveModal}
				setShowActiveModal={setShowActiveModal}
				activeNodeToUpdate={activeSensorToUpdate}
				setActiveNodeToUpdate={setActiveSensorToUpdate}
				setShowWebcamActiveUpdateModal={setShowWebcamActiveUpdateModal}
				showAddPhotosActiveModal={showAddPhotosActiveModal}
				setShowAddPhotosActiveModal={setShowAddPhotosActiveModal}
				maxNumber={maxNumber}
				imagesActiveAddPhotos={imagesActiveAddPhotos}
				setImagesActiveAddPhotos={setImagesActiveAddPhotos}
				onChangeModal3={onChangeModal3}
				urlsToDelete={urlsToDelete}
				setUrlsToDelete={setUrlsToDelete}
				setUrlsModal2Active={setUrlsModal2Active}
				startButtonProgress={startButtonProgress}
				handleSubmitUpdateActiveNode={handleSubmitUpdateActiveSensor}
				setOldNodeDoc={setOldSensorDoc}
				urlsActiveWebcamToUpdate={urlsActiveWebcamToUpdate}
				setUrlsActiveWebcamToUpdate={setUrlsActiveWebcamToUpdate}
				title="Sensor"
			/>

			<DeleteModal
				show={showDeleteModal}
				onHide={handleCloseDeleteModal}
				onDelete={handleCloseDeleteModalAndDeletePOI}
				poi={poiIdToDelete}
				title="sensor"
			/>

			{/* reuse for webcam, active nodes and update nodes */}
			<WebcamModal
				show={showWebcamModal}
				onHide={handleCloseWebcamModal}
				urlsWebcam={urlsWebcam}
				setUrlsWebcam={setUrlsWebcam}
				title="Sensor"
			/>
			<WebcamModal
				show={showWebcamUpdateModal}
				onHide={handleCloseWebcamUpdateModal}
				urlsWebcam={urlsWebcamToUpdate}
				setUrlsWebcam={setUrlsWebcamToUpdate}
				title="Sensor"
			/>
			<WebcamModal
				show={showWebcamActiveUpdateModal}
				onHide={setShowWebcamActiveUpdateModal}
				urlsWebcam={urlsActiveWebcamToUpdate}
				setUrlsWebcam={setUrlsActiveWebcamToUpdate}
				title="Sensor"
			/>

			{/* reuse the component but for active nodes */}
			<BigImageModal
				show={showBigImageModal}
				handleClose={() => {
					setShowBigImageModal(false)
				}}
				node={selectedBigImage}
				selectedImageIndexPosition={selectedImageIndexPosition}
			/>
			<BigImageModal
				show={showBigImageModalActive}
				handleClose={() => {
					setShowBigImageModalActive(false)
				}}
				node={selectedBigImageActive}
				selectedImageIndexActive={selectedImageIndexActive}
			/>
		</Card>
	)
}

function Info(props) {
	const { sensorPosition } = props

	return (
		<div className="d-flex" style={{ padding: "0px 10px 8px 10px" }}>
			<div
				className={
					sensorPosition?.description && sensorPosition?.description !== ""
						? "flex-fill col-4 mr-2"
						: "flex-fill col-12"
				}
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Sensor
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					{sensorPosition.status === "placed" ? "Placed" : "Planned"}
				</div>
			</div>

			{sensorPosition?.description && sensorPosition?.description !== "" && (
				<div
					className="flex-fill col-8"
					style={{
						background: "#FFFFFF",
						borderRadius: "4px",
						overflowY: "auto", // Enable vertical scrolling
						maxHeight: "75px" // Set the maximum height to display 2 lines of text
					}}
				>
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "300",
							lineHeight: "160%",
							fontSize: "10px",
							color: "#4A4A68"
						}}
					>
						Description
					</div>
					<hr className="m-0" />
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "600",
							lineHeight: "160%",
							fontSize: "11px",
							color: "#4A4A68"
						}}
					>
						{sensorPosition.description}
					</div>
				</div>
			)}
		</div>
	)
}
