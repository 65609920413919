import React, { useState, useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { convertDateToString } from "./_utils/convertDateToString"
import MapWidgetSensors from "./MapWidgetSensors/MapWidgetSensors"
import TopRowInfo from "./TopRowInfo/TopRowInfo"
import FloorSensorOverview from "./FloorSensorOverview/FloorSensorOverview"
import { fetchAllSensorsFromToday, fetchAllSensors } from "../_redux/liveData/liveDataActions"
import { liveDataSlice } from "../_redux/liveData/liveDataSlice"
import { firestore } from "../../../firebase"
import { collection, query, where, onSnapshot } from "firebase/firestore"
import { ERRORS } from "../../_enums/errors"
import { QUERY_CONSTRAINTS, COLLECTIONS } from "../../_enums/firestoreConstants"
import { PAGES } from "../../_enums/pages"
import RenderMainLivePageTitle from "./_partials/RenderMainLivePageTitle"
import RenderMainPageHeader from "./_partials/RenderMainPageHeader"

const LIVE_PAGE = "live"
const HISTORY_PAGE = "history"

const SensorsMainPage = () => {
	const dispatch = useDispatch()

	const { selectedSite, sensors, sensorsMeasurements } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			sensors: state.liveData?.sensors || [],
			sensorsMeasurements: state.liveData?.sensorsMeasurements || {}
		}),
		shallowEqual
	)

	const [sensorsPage, setSensorsPage] = useState(LIVE_PAGE)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (!selectedSite) return

		let isMounted = true
		const callFetchAllSensors = async () => {
			setIsLoading(true)
			try {
				await dispatch(fetchAllSensors({ siteId: selectedSite.id }))
			} catch (error) {
				console.error(ERRORS.FETCH_ERRORS.SENSORS, error)
			} finally {
				if (isMounted) {
					setIsLoading(false)
				}
			}
		}

		callFetchAllSensors()

		return () => {
			isMounted = false
		}
	}, [selectedSite])

	//TODO dont forget to remove default sensors array from api fetch
	//TODO change from today to last 24h
	//? I think this should change and only run once because with the snapshot
	//? we already are getting the update measurments
	useEffect(() => {
		if (!selectedSite || sensors?.length === 0) return

		const callFetchAllSensorsFromToday = async () => {
			setIsLoading(true)
			try {
				await dispatch(
					fetchAllSensorsFromToday({
						siteId: selectedSite.id,
						sensors: sensors.filter(sensor => sensor.id).map(sensor => sensor.id)
					})
				)
			} catch (error) {
				console.error(ERRORS.FETCH_ERRORS.SENSORS, error)
			} finally {
				setIsLoading(false)
			}
		}

		callFetchAllSensorsFromToday()
	}, [sensors])

	//TODO could be refactored
	useEffect(() => {
		if (!selectedSite || sensors?.length === 0) return

		const nodesRef = collection(
			firestore,
			`${COLLECTIONS.SITES}/${selectedSite.id}/${COLLECTIONS.NODES}`
		)
		const q = query(
			nodesRef,
			where(
				QUERY_CONSTRAINTS.WHERE.NODE_TYPE,
				QUERY_CONSTRAINTS.OPERATORS.EQUAL,
				QUERY_CONSTRAINTS.VALUES.SENSOR
			)
		)

		setIsLoading(true)
		let isFirstSnapshot = true

		let isMounted = true

		const unsubscribe = onSnapshot(q, snapshot => {
			if (!isMounted) return

			if (isFirstSnapshot) {
				setIsLoading(false)
				isFirstSnapshot = false
				return
			}

			snapshot.docChanges().forEach(change => {
				const updatedSensor = {
					id: change.doc.id,
					...change.doc.data()
				}

				const existingSensorIndex = sensors.findIndex(sensor => sensor.id === updatedSensor.id)
				let updatedSensors

				if (existingSensorIndex !== -1) {
					updatedSensors = [...sensors]
					updatedSensors[existingSensorIndex] = updatedSensor
				} else {
					updatedSensors = [...sensors, updatedSensor]
				}

				dispatch(liveDataSlice.actions.sensorsFetched(updatedSensors))

				const newMeasurement = {
					measurement: { ...updatedSensor.measurement },
					measurement_time: convertDateToString(updatedSensor.measurement_time)
				}

				const updatedSensorMeasurements = {
					...sensorsMeasurements,
					[updatedSensor.id]: [
						...(sensorsMeasurements[updatedSensor.id] || []),
						newMeasurement
					]
				}

				dispatch(liveDataSlice.actions.sensorsMeasurementsFetched(updatedSensorMeasurements))

				if (isMounted) {
					setIsLoading(false)
				}
			})
		})

		return () => {
			isMounted = false
			setIsLoading(false)
			unsubscribe()
		}
	}, [sensorsMeasurements])

	return (
		<div>
			{!selectedSite && <Redirect exact={true} to={PAGES.DASHBOARD} />}

			<RenderMainPageHeader
				LIVE_PAGE={LIVE_PAGE}
				HISTORY_PAGE={HISTORY_PAGE}
				sensorsPage={sensorsPage}
				setSensorsPage={setSensorsPage}
			/>

			{sensorsPage === LIVE_PAGE && (
				<div>
					<RenderMainLivePageTitle />
					<TopRowInfo isLoading={isLoading} selectedSite={selectedSite} />
					<MapWidgetSensors />
				</div>
			)}
			<div>{sensorsPage === HISTORY_PAGE && <FloorSensorOverview />}</div>
		</div>
	)
}

export default SensorsMainPage
