import React from "react"
import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles(theme => ({
	title: {
		color: "#1C1C3B",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "160%",
		textTransform: "capitalize",
		padding: "1rem 0"
	},
	description: {
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%",
		padding: "1rem 0"
	},
	inputLabel: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%",
		padding: "1rem 0"
	},
	inputSmallLabel: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "11px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%",
		padding: "1rem 0"
	},
	label: {
		color: "#4A4A68",
		fontFamily: "Poppins",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "170%",
		padding: "1rem 0"
	},
	container: {
		padding: "1rem",
		backgroundColor: "#FFFFFF",
		filter: "drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.10))"
	},
	historyHeaderInfo: {
		borderRadius: "4px",
		textAlign: "center",
		fontSize: "13px",
		fontStyle: "normal",
		fontWeight: "500",
		lineHeight: "normal",
		letterSpacing: "0.52px",
		textTransform: "capitalize",
		padding: "4px 8px",
		marginRight: "4px"
	},
	historyMainDivList: {
		width: "-webkit-fill-available",
		display: "flex",
		color: "#4A4A68",
		borderRadius: "5px",
		maxHeight: "130px",
		padding: "8px 16px 16px 24px"
	},
	historyMessageDiv: {
		width: "inherit",
		padding: "8px 16px",
		display: "block"
	},
	datePicker: {
		height: "45px",
		width: "100%",
		borderRadius: "4px",
		overflow: "hidden",
		cursor: "pointer",
		"& .MuiInput-root": {
			"& .MuiInputBase-input": {
				cursor: "pointer"
			}
		},
		"& .MuiSelect-filled": {
			backgroundColor: "#ECF1F4",
			border: 0
		},
		"& .MuiSelect-root": {
			padding: "16px 12px"
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#FFFFFF",
			borderRadius: "5px"
		},
		"&:hover .MuiOutlinedInput-notchedOutline": {
			borderColor: "#8C8CA1",
			borderRadius: "5px"
		},
		"& .MuiInput-underline:before": {
			borderBottom: "none"
		},
		"& .MuiInput-underline:after": {
			borderBottom: "none"
		},
		"&.MuiFilledInput-underline:before": {
			borderBottom: "none"
		},
		"&.MuiFilledInput-underline:after": {
			borderBottom: "none"
		}
	}
}))
