import React, { useState, useEffect } from "react"
import { useSelector, shallowEqual } from "react-redux"
import MapInfraTopRowInfo from "../map-infra-helpers/MapInfraTopRowInfo"
import { SensorsCard } from "./SensorsCard"
import useFetchNodesData from "../map-infra-helpers/_hooks/FetchNodesData"

const SensorsPositioningPage = () => {
	const { selectedSite, currentFloorPlan } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			currentFloorPlan: state.profile?.currentFloorPlan
		}),
		shallowEqual
	)

	const [nodesCounts, setNodesCounts] = useState({
		assigned: 0,
		planned: 0,
		placed: 0,
		approved: 0,
		online: 0,
		lost: 0,
		removed: 0
	})

	const [allTotalCount, setAllTotalCount] = useState({
		assigned: 0,
		planned: 0,
		placed: 0,
		approved: 0,
		online: 0,
		lost: 0,
		removed: 0
	})

	const [collectionChanged, setCollectionChanged] = useState(false)

	useEffect(() => {
		setCollectionChanged(false)
	}, [collectionChanged])

	const { isFetching } = useFetchNodesData(
		selectedSite,
		currentFloorPlan,
		collectionChanged,
		setNodesCounts,
		setAllTotalCount,
		"SensorsPosition",
		"sensor"
	)

	return (
		<div className="mt-3" data-testid="sensors-positioning-page">
			<MapInfraTopRowInfo
				isFetching={isFetching}
				anchorCounts={nodesCounts}
				allTotalCount={allTotalCount}
				nodeType="sensors"
				defaultSrc="/markers/sensor.svg"
			/>
			<SensorsCard setCollectionChanged={setCollectionChanged} />
		</div>
	)
}

export default SensorsPositioningPage
