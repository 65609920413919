import React from "react"
export const RenderUuidDiv = ({ uuid }) => {
	return (
		<span
			style={{
				position: "absolute",
				color: "#4A4A68",
				top: "-35px",
				left: "50%",
				textAlign: "center",
				minWidth: "60px",
				transform: "translateX(-50%)",
				background: "#FFFFFF",
				padding: "4px",
				borderRadius: "2px",
				fontSize: "12px",
				whiteSpace: "nowrap",
				pointerEvents: "none"
			}}
		>
			{"UUID: " + uuid}
			<div
				style={{
					content: "''",
					position: "absolute",
					bottom: "-6px",
					left: "50%",
					transform: "translateX(-50%)",
					width: "0",
					height: "0",
					borderLeft: "6px solid transparent",
					borderRight: "6px solid transparent",
					borderTop: "6px solid #FFFFFF"
				}}
			></div>
		</span>
	)
}
