import React from "react"
import ReactDOM from "react-dom"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers"

export default class Options {
	constructor(props) {
		this.props = props
		this.centerSet = false
	}

	onAdd(map) {
		this.map = map
		this.container = document.createElement("div")
		this.container.className = "mapboxgl-ctrl"
		this.container.style.cursor = "pointer"
		this.content = <OptionsComponent map={map} {...this.props} />
		ReactDOM.render(this.content, this.container)
		return this.container
	}

	onRemove() {
		this.container?.parentNode?.removeChild(this.container)
		this.map = undefined
	}
}

class OptionsComponent extends React.Component {
	constructor(props) {
		super(props)
		this.handleClick = this.handleClick.bind(this)
	}

	// When button clicked, reset the map to the initial center, zoom level and bearing
	handleClick() {
		if (Boolean(this.props.selectedFloorPlan.mapOrientation) === true) {
			const lat = this.props.selectedFloorPlan?.mapOrientation?.center?.latitude
			const lng = this.props.selectedFloorPlan?.mapOrientation?.center?.longitude

			lng &&
				lat &&
				this.props.map.flyTo({
					center: [lng, lat],
					bearing: this.props.selectedFloorPlan?.mapOrientation?.bearing,
					pitch: this.props.selectedFloorPlan?.mapOrientation?.pitch,
					zoom: this.props.selectedFloorPlan?.mapOrientation?.zoom
				})
		} else {
			//if there is no mapOrientation, the center will fit the initial bounds of the map
			this.props.map.fitBounds([
				[
					this.props.selectedFloorPlan?.coords?.topLeftCorner?.coords?.longitude,
					this.props.selectedFloorPlan?.coords?.topLeftCorner?.coords?.latitude
				],
				[
					this.props.selectedFloorPlan?.coords?.bottomRightCorner?.coords?.longitude,
					this.props.selectedFloorPlan?.coords?.bottomRightCorner?.coords?.latitude
				]
			])
		}
	}

	render() {
		return (
			<div className="fullscreen-button">
				<SVG
					src={toAbsoluteUrl("/media/svg/icons/Map/Position.svg")}
					onClick={this.handleClick}
				></SVG>
			</div>
		)
	}
}
