import { createSlice } from "@reduxjs/toolkit"

const initialCustomersState = {
	currentCustomer: null,
	currentSite: null,
	currentLevel: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const profileSlice = createSlice({
	name: "profile",
	initialState: initialCustomersState,
	reducers: {
		customerSelected: (state, action) => {
			state.currentCustomer = action.payload
			state.lastError = state.error
			state.error = null
		},
		addCustomerVendor: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.currentCustomer.vendors = state.currentCustomer.vendors
				? [...state.currentCustomer.vendors, action.payload]
				: state.currentCustomer.vendors
		},
		updateCustomerVendor: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.currentCustomer.vendors = action.payload
		},
		addCustomerRole: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.currentCustomer.roles = state.currentCustomer.roles
				? [...state.currentCustomer.roles, action.payload]
				: state.currentCustomer.roles
		},
		updateCustomerRole: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.currentCustomer.roles = action.payload
		},
		updateCustomerAssetType: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.currentCustomer.assetTypes = action.payload
		},
		siteSelected: (state, action) => {
			state.currentSite = action.payload
			state.lastError = state.error
			state.error = null
		},
		floorPlanSelected: (state, action) => {
			state.currentFloorPlan = action.payload
			state.lastError = state.error
			state.error = null
		},
		levelSelected: (state, action) => {
			state.currentLevel = action.payload
			state.lastError = state.error
			state.error = null
		},
		userProfileFetched: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.userProfile = action.payload.userProfile
		},
		layerTypesFetched: (state, action) => {
			state.error = null
			state.actionsLoading = false
			state.currentCustomer.layerTypes = action.payload
		}
	}
})
