import React from "react"
import ReactDOM from "react-dom"
import { IconButton } from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import NewSearchSensors from "./NewSearchSensors"
import HeatmapFilter from "./HeatmapFilter"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"

export default class DrawerSensors {
	constructor(props) {
		this.props = props
	}

	onAdd(map) {
		this.map = map
		this.container = document.createElement("div")
		this.container.className = "mapboxgl-ctrl"

		const icon = this.props.openDrawerBar ? (
			<ExpandLessIcon fontSize="default" style={{ color: "#2C3E50" }} />
		) : (
			<ExpandMoreIcon fontSize="default" style={{ color: "#2C3E50" }} />
		)

		this.content = (
			<div
				className="container_drawer_bar_content"
				style={{ display: !this.props.selectedSite ? "none" : "flex" }}
			>
				{this.props.drawerOptions.openSearch && (
					<div className="search_wrapper">
						<NewSearchSensors
							drawerOptions={this.props.drawerOptions}
							map={this.props.map}
							dispatch={this.props.dispatch}
							activeAnchors={this.props.sensors}
							positionAnchors={this.props.positionAnchors}
							floorPlans={this.props.floorPlans}
							floorPlan={this.props.floorPlan}
							setGoTo={this.props.setGoTo}
							setShowAnchorsOnline={this.props.setShowAnchorsOnline}
						/>
					</div>
				)}
				{this.props.openDrawerBar && this.props.drawerOptions.openHeatmap && (
					<div className="heatmap_filter_wrapper">
						<HeatmapFilter
							setDrawerOptions={this.props.setDrawerOptions}
							heatmapFilter={this.props.heatmapFilter}
							setHeatmapFilter={this.props.setHeatmapFilter}
						/>
					</div>
				)}

				<div className="wrapper_drawer_bar_content">
					{/* For now hdie search bar */}
					{/* {!this.props.drawerOptions.openSearch ? (
						<OverlayTrigger
							trigger={["hover", "focus"]}
							placement="left"
							overlay={<Tooltip id="tooltip">Search</Tooltip>}
						>
							<div className="drawer_icon">
								<IconButton
									className="livemap_drawer_open_search"
									style={{
										background: this.props.drawerOptions.openSearch ? "#DDE9FD" : null,
										padding: "8px",
										fontSize: "12px"
									}}
									onClick={() => {
										this.props.setOpenDrawerBar(true)
										this.props.setDrawerOptions(prevState => ({
											openSearch: !prevState.openSearch,
											openCreate: false,
											openHeatmap: false
										}))
									}}
								>
									<SearchIcon
										style={{
											fontSize: "20px",
											color: this.props.drawerOptions.openSearch ? "#0277DA" : "#2C3E50"
										}}
									/>
								</IconButton>
							</div>
						</OverlayTrigger>
					) : (
						<div className="drawer_icon">
							<IconButton
								className="livemap_drawer_open_search"
								style={{
									background: this.props.drawerOptions.openSearch ? "#DDE9FD" : null,
									padding: "8px",
									fontSize: "12px"
								}}
								onClick={() => {
									this.props.setOpenDrawerBar(true)
									this.props.setDrawerOptions(prevState => ({
										openSearch: !prevState.openSearch,
										openCreate: false,
										openHeatmap: false
									}))
								}}
							>
								<SearchIcon
									style={{
										fontSize: "20px",
										color: this.props.drawerOptions.openSearch ? "#0277DA" : "#2C3E50"
									}}
								/>
							</IconButton>
						</div>
					)} */}

					{/* <div
						id="drawer_class"
						className={
							this.props.openDrawerBar ? "expanded_drawer_anchors" : "collapsed_drawer"
						}
					>
						<div> */}
					{!this.props.drawerOptions.openHeatmap ? (
						<OverlayTrigger
							trigger={["hover", "focus"]}
							placement="left"
							overlay={<Tooltip className="drawer_tooltips">Heatmap</Tooltip>}
						>
							<div className="drawer_icon">
								<IconButton
									className="livemap_drawer_open_general_filter"
									style={{
										background: this.props.drawerOptions.openHeatmap ? "#DDE9FD" : null,
										padding: "10px",
										fontSize: "10px"
									}}
									onClick={() => {
										this.props.setDrawerOptions(prevState => ({
											openFilter: false,
											openSearch: false,
											openPath: false,
											openOccupancy: false,
											openSatellite: false,
											openSlider: false,
											open3d: false,
											openHeatmap: !prevState.openHeatmap
										}))
									}}
								>
									<SVG
										src={toAbsoluteUrl("/media/crowdkeep/Heatmap.svg")}
										style={{
											fontSize: "10px",
											width: "18px",
											height: "18px"
										}}
									/>
								</IconButton>
							</div>
						</OverlayTrigger>
					) : (
						<div className="drawer_icon">
							<IconButton
								className="livemap_drawer_open_general_filter"
								style={{
									background: this.props.drawerOptions.openHeatmap ? "#DDE9FD" : null,
									padding: "10px",
									fontSize: "10px"
								}}
								onClick={() => {
									this.props.setDrawerOptions(prevState => ({
										openFilter: false,
										openSearch: false,
										openPath: false,
										openOccupancy: false,
										openSatellite: false,
										openSlider: false,
										open3d: false,
										openHeatmap: !prevState.openHeatmap
									}))
								}}
							>
								<SVG
									src={toAbsoluteUrl("/media/crowdkeep/Heatmap.svg")}
									style={{
										fontSize: "10px",
										width: "18px",
										height: "18px",
										filter: this.props.drawerOptions.openHeatmap
											? "invert(37%) sepia(95%) saturate(1594%) hue-rotate(187deg) brightness(83%) contrast(108%)"
											: "invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
									}}
								/>
							</IconButton>
						</div>
					)}
					{/* </div>
					</div> */}
					{/* <div
						className="expand_button_drawer"
						onClick={() => {
							this.props.setDrawerOptions({
								openSearch: false,
								openHeatmap: false
							})

							this.props.setOpenDrawerBar(prev => !prev)
						}}
					>
						<div style={{ width: "42px", background: "#BDC3C7", height: "1px" }} />
						{icon}
					</div> */}
				</div>
			</div>
		)

		ReactDOM.render(this.content, this.container)
		return this.container
	}

	onRemove() {
		this.container.parentNode && this.container.parentNode.removeChild(this.container)
		this.map = undefined
	}
}
