// SensorsDetailsPage.jsx
import React, { useState, useEffect } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { useParams, Redirect } from "react-router-dom"
import SensorsTitle from "../SensorsTitle/SensorsTitle"
import TopRowInfoDetails from "./TopRowInfoDetails/TopRowInfoDetails"
import MapWidgetDetail from "./MapWidgetDetail/MapWidgetDetail"
import { Card, CardBody } from "../../../_partials/Card"
import Chart from "react-apexcharts"

const SensorsDetailsPage = () => {
	const { id } = useParams()

	const { selectedCustomer, currentFloorPlan, selectedSite, sensors, sensorsMeasurements } =
		useSelector(
			state => ({
				selectedCustomer: state.profile?.currentCustomer,
				selectedSite: state.profile?.currentSite,
				currentFloorPlan: state.profile?.currentFloorPlan,
				sensors: state.liveData?.sensors,
				sensorsMeasurements: state.liveData?.sensorsMeasurements || {}
			}),
			shallowEqual
		)

	const foundedSensor = sensors && sensors.find(val => val.id === id)
	const foundedMeasurements = foundedSensor && sensorsMeasurements[foundedSensor.id]

	if (!foundedSensor) {
		return (
			<div>
				<Redirect exact={true} to="/sensors" />
				Sensor not found.
			</div>
		)
	}

	const transformData = ({ data, key }) => {
		return data.map(item => ({
			x: new Date(item.measurement_time).getTime(),
			y: item.measurement?.[key]
		}))
	}

	return (
		<div>
			<div
				className="d-flex align-items-sm-start align-items-center justify-content-between mb-4"
				style={{ width: "100%", minHeight: "8rem" }}
			>
				<div
					className="col-6 d-flex align-items-sm-end align-items-center p-0"
					style={{ minHeight: "5rem" }}
				>
					<SensorsTitle />
				</div>
			</div>

			<div style={{ marginBottom: "2rem" }}>
				<TopRowInfoDetails sensors={sensors} />
			</div>

			<div className="row" style={{ marginBottom: "2rem" }}>
				<div className="col col-md-6">
					<MapWidgetDetail
						sensor={foundedSensor}
						currentFloorPlan={currentFloorPlan}
						selectedCustomer={selectedCustomer}
						selectedSite={selectedSite}
						sensors={sensors}
						sensorsMeasurements={sensorsMeasurements}
					/>
				</div>
				<div className="col col-md-6">
					<SensorDetailCard title="Sensor" showIcon={false} bgColor={"#FFFFFF"}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								textAlign: "right",
								paddingTop: "10px"
							}}
						>
							<span
								style={{
									fontSize: "32px",
									fontWeight: 700,
									color: "#4A4A68",
									marginRight: "5px"
								}}
							>
								Status: {foundedSensor.status}
							</span>
							<span
								style={{
									fontSize: "20px",
									fontWeight: 600,
									color: "#4A4A68",
									marginRight: "5px"
								}}
							>
								UUID: {foundedSensor.uuid}
							</span>
						</div>
					</SensorDetailCard>
					<SensorDetailCard
						title="Temperature"
						icon="/icons/temperature--celsius.png"
						bgColor={"#E7E5FF"}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								textAlign: "right",
								paddingTop: "10px"
							}}
						>
							<span
								style={{
									fontSize: "32px",
									fontWeight: 700,
									color: "#4A4A68",
									marginRight: "5px"
								}}
							>
								{Math.floor(foundedSensor.measurement.temperature.toFixed(0) * (9 / 5)) +
									32}
								ºF
							</span>
							<span
								style={{
									fontSize: "20px",
									fontWeight: 600,
									color: "#4A4A68",
									marginRight: "5px"
								}}
							>
								{foundedSensor.measurement.temperature.toFixed(0)}ºC
							</span>
						</div>
					</SensorDetailCard>
					<SensorDetailCard
						title="Humidity"
						icon="/icons/humidity--alt.png"
						bgColor={"#DDE9FD"}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								textAlign: "right",
								paddingTop: "10px"
							}}
						>
							<span
								style={{
									fontSize: "32px",
									fontWeight: 700,
									color: "#4A4A68",
									marginRight: "5px"
								}}
							>
								{foundedSensor.measurement.humidity.toFixed(0)}%
							</span>
						</div>
					</SensorDetailCard>
				</div>
			</div>
			<div className="row " style={{ marginBottom: "2rem" }}>
				{foundedSensor.measurement?.temperature && (
					<div className="col col-md-12">
						<FloorSensorChart
							chartOptions={{
								name: "Temperature",
								type: "line",
								icon: "/icons/temperature--celsius.png",
								key: "temperature",
								color: "#8680FF",
								currentMeasurement: foundedSensor.measurement?.temperature
							}}
							sensorData={transformData({ data: foundedMeasurements, key: "temperature" })}
							// average={averageTemperature}	// Not being used
						/>
					</div>
				)}
				{foundedSensor.measurement?.humidity && (
					<div className="col col-md-12 mt-5">
						<FloorSensorChart
							chartOptions={{
								name: "Humidity",
								type: "line",
								icon: "/icons/humidity--alt.png",
								key: "humidity",
								color: "#6CA4FF",
								currentMeasurement: foundedSensor.measurement?.humidity
							}}
							sensorData={transformData({ data: foundedMeasurements, key: "humidity" })}
							// average={averageHumidity} // Not being used
						/>
					</div>
				)}
				{foundedSensor.measurement?.co2 && (
					<div className="col col-md-12 mt-5">
						<FloorSensorChart
							chartOptions={{
								name: "CO2",
								type: "line",
								icon: "/icons/co2.png",
								key: "co2",
								color: "#6CA4FF",
								currentMeasurement: foundedSensor.measurement?.co2
							}}
							sensorData={transformData({ data: foundedMeasurements, key: "co2" })}
							// average={averageCO2} // Not being used
						/>
					</div>
				)}

				{foundedSensor.measurement?.luminosity && (
					<div className="col col-md-12 mt-5">
						<FloorSensorChart
							chartOptions={{
								name: "Luminosity",
								type: "line",
								icon: "/icons/humidity--alt.png",
								key: "luminosity",
								color: "#6CA4FF",
								currentMeasurement: foundedSensor.measurement?.luminosity
							}}
							sensorData={transformData({ data: foundedMeasurements, key: "luminosity" })}
							// average={averageLuminosity} // Not being used
						/>
					</div>
				)}

				{foundedSensor.measurement?.motion && (
					<div className="col col-md-6 mt-5">
						<MotionChart
							chartOptions={{
								name: "Motion (mock data)",
								type: "heatmap",
								icon: "/icons/motion.png",
								color: "#0F2A5E",
								currentMeasurement: foundedSensor.measurement?.motion
							}}
							sensorData={transformData({ data: foundedMeasurements, key: "motion" })}
							// average={averageMotion} // Not being used
						/>
					</div>
				)}
				{foundedSensor.measurement?.noiseLevel && (
					<div className="col col-md-6 mt-5">
						<NoiseSensorChart
							chartOptions={{
								name: "Noise Level (mock data)",
								type: "radialBar",
								icon: "/icons/noise.png",
								currentMeasurement: foundedSensor.measurement?.noiseLevel
							}}
						/>
					</div>
				)}
				{foundedSensor.measurement?.volatilGasses && (
					<div className="col col-md-6 mt-5">
						<VolatileSensorGasesChart
							chartOptions={{
								name: "Volatile Gases (mock data)",
								type: "pie",
								icon: "/icons/skill-level--intermediate.png",
								currentMeasurement: foundedSensor.measurement?.volatilGasses
							}}
							// count // Not being used
						/>
					</div>
				)}
				{foundedSensor.measurement?.pressure && (
					<div className="col col-md-12 mt-5">
						<SensorBarometricPressureChart
							chartOptions={{
								name: "Barometric Pressure (mock data)",
								type: "line",
								icon: "/icons/sort--ascending.png",
								currentMeasurement: foundedSensor.measurement?.pressure
							}}
							sensorData={transformData({ data: foundedMeasurements, key: "pressure" })}
							// average={averagePressure} // Not being used
							// count={pressureData.length} // Not being used
						/>
					</div>
				)}
			</div>
		</div>
	)
}

const FloorSensorChart = ({ chartOptions, sensorData, average }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [graphData, setGraphData] = useState({
		series: []
	})

	useEffect(() => {
		setIsLoading(true)
		setGraphData({
			series: [
				{
					name: chartOptions.name,
					data: sensorData
				}
			]
		})
		setIsLoading(false)
	}, [sensorData, chartOptions.name])

	const options = {
		chart: {
			toolbar: {
				tools: {
					download: false
				}
			},
			zoom: {
				enabled: false
			}
		},
		grid: {
			show: true
		},
		noData: {
			text: isLoading ? "Loading data..." : "No data to display."
		},
		legend: {
			show: false
		},
		colors: [chartOptions.color],
		dataLabels: {
			enabled: false
		},

		tooltip: {
			shared: false,
			x: {
				format: "MMM dd, HH:mm"
			}
		},
		stroke: {
			curve: "straight"
		},
		xaxis: {
			type: "datetime",
			labels: {
				format: "HH:mm"
			},
			tooltip: {
				enabled: false
			}
		},
		yaxis: {
			labels: {
				formatter: function (val) {
					return showAverage(chartOptions, val)
				}
			}
		},

		responsive: [
			{
				breakpoint: 900,
				options: {
					chart: {
						width: "100%",
						height: "100%"
					}
				}
			}
		]
	}

	const showAverage = (chartOptions, value) => {
		if (chartOptions.name === "Temperature") {
			return `${value}ºF`
		} else if (chartOptions.name === "Humidity") {
			return `${value}%`
		} else if (chartOptions.name === "CO2") {
			return `${value}ppm`
		} else if (chartOptions.name === "Luminosity") {
			return `${value}xl`
		} else {
			return undefined
		}
	}

	return (
		<Card isLoading={isLoading} style={{ height: "100%" }}>
			<div className="title_bar_main_div">
				<div className="d-flex justify-content-between align-items-center">
					<div className="d-flex align-items-center">
						<div className="floor_sensor_icon">
							<img src={chartOptions.icon} alt="icon" style={{ width: "16px" }} />
						</div>
						<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
							{chartOptions.name}
						</span>
					</div>
					<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
						Current Measurement:{" "}
						{showAverage(chartOptions, chartOptions.currentMeasurement?.toFixed(0))}
					</span>
				</div>
			</div>
			<CardBody style={{ padding: "0px", position: "relative" }}>
				{/* {!isLoading && (
					<span
						style={{
							position: "absolute",
							marginLeft: "auto",
							marginRight: "auto",
							color: chartOptions.color,
							fontWeight: "bold",
							fontSize: "18px",

							top: "70%",
							left: "0",
							right: "0",
							textAlign: "center",
							zIndex: 10
						}}
					>
						{showAverage(chartOptions, average)}
					</span>
				)} */}
				<Chart options={options} series={graphData.series} type="line" height={200} />
			</CardBody>
		</Card>
	)
}

const MotionChart = ({ chartOptions, sensorData, count, average }) => {
	var options = {
		chart: {
			toolbar: {
				tools: {
					download: false
				}
			},
			zoom: {
				enabled: false
			},
			type: "heatmap",
			sparkline: {
				enabled: true
			}
		},
		grid: {
			show: false // Remove grid from background
		},

		legend: {
			show: false // Remove legend
		},

		dataLabels: {
			enabled: false
		},
		colors: ["#0F2A5E"],
		tooltip: {
			enabled: true
			// y: {
			// 	formatter: function (value) {
			// 		return Math.round(value)
			// 	}
			// }
		},
		stroke: {
			curve: "smooth"
		},
		yaxis: {
			show: false
		},
		fill: {
			type: "gradient",
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.7,
				opacityTo: 0.9,
				stops: [0, 90, 100]
			}
		},
		responsive: [
			{
				breakpoint: 900,
				options: {
					chart: {
						width: "100%",
						height: "100%"
					}
				}
			}
		]
		// If this is uncommented we have a bug on UI when timeframe is "month"
		// responsive: [
		//   {
		//     breakpoint: 480,

		//   },
		// ],
	}

	var series = [
		{
			name: "Fri",
			data: formatData([
				0, 0, 0, 0, 20, 40, 45, 45, 30, 20, 5, 5, 15, 35, 35, 20, 45, 40, 25, 5, 20, 25, 40, 5
			])
		},
		{
			name: "Thu",
			data: formatData([
				0, 0, 15, 10, 40, 25, 35, 25, 30, 10, 5, 5, 5, 20, 30, 30, 10, 15, 15, 5, 55, 20, 20, 5
			])
		},
		{
			name: "Wed",
			data: formatData([
				0, 0, 5, 10, 35, 25, 30, 30, 5, 20, 0, 0, 15, 10, 20, 5, 40, 25, 20, 30, 45, 20, 15, 5
			])
		},
		{
			name: "Tue",
			data: formatData([
				0, 0, 4, 0, 28, 28, 12, 28, 44, 28, 28, 8, 0, 8, 16, 32, 20, 36, 36, 44, 28, 32, 4, 4
			])
		},
		{
			name: "Mon",
			data: formatData([
				0, 4, 0, 4, 4, 0, 4, 12, 16, 24, 16, 8, 16, 4, 20, 32, 48, 44, 36, 36, 90, 28, 8, 16
			])
		}
	]

	function formatData(data) {
		let newData = []
		let categories = [
			"7:00-7.30",
			"7:30-8.00",
			"8:00-8.30",
			"8:30-9.00",
			"9:00-9.30",
			"9:30-10.00",
			"10:00-10.30",
			"10:30-11.00",
			"11:00-11.30",
			"11:30-12.00",
			"12:00-12.30",
			"12:30-1.00",
			"1:00-1.30",
			"1.30-2.00",
			"2:00-2.30",
			"2:30-3.00",
			"3:00-3.30",
			"3:30-4.00",
			"4:00-4.30",
			"4:30-5.00",
			"5:00-5.30",
			"5:30-6.00",
			"6:00-6.30",
			"6:30-7.00"
		]

		for (var i = 0; i < categories.length; i++) {
			newData.push({
				x: categories[i],
				y: data[i]
			})
		}

		return newData
	}

	const titleBar = (
		<div className="title_bar_main_div">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<div className="floor_sensor_icon">
						<img src={chartOptions?.icon} alt="icon" style={{ width: "16px" }} />
					</div>
					<div>
						<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
							{chartOptions.name}
						</span>
					</div>
				</div>
				<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
					Current Measurement: {chartOptions.currentMeasurement?.toFixed(0)}
				</span>
			</div>
		</div>
	)

	return (
		<Card isLoading={series ? false : true} style={{ height: "100%" }}>
			{titleBar}
			<CardBody style={{ padding: "0px" }}>
				<Chart options={options} series={series} type="heatmap" height={200} />
			</CardBody>
		</Card>
	)
}

const NoiseSensorChart = ({ chartOptions, count }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [graphData, setGraphData] = useState({
		labels: [],
		series: [],
		colors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]
	})

	function getRandomArray(size) {
		let randomArray = []
		for (let i = 0; i < size; i++) {
			// Generate a random number between 0 and 70
			let randomNumber = Math.floor(Math.random() * 71)
			randomArray.push(randomNumber)
		}
		return randomArray
	}

	function generateData(count, yrange) {
		var i = 0
		var series = []
		while (i < count) {
			var x = "w" + (i + 1).toString()
			var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

			series.push({
				x: x,
				y: y
			})
			i++
		}
		return series
	}

	useEffect(() => {
		let isMounted = true

		setIsLoading(true)
		setTimeout(() => {
			if (isMounted) {
				setGraphData({
					series: [76],
					labels: getRandomArray(10),
					colors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]
				})

				setIsLoading(false)
			}
		}, 1000)

		return () => {
			isMounted = false
		}
	}, [])

	const titleBar = (
		<div className="title_bar_main_div">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<div className="floor_sensor_icon">
						<img src={chartOptions?.icon} alt="icon" style={{ width: "16px" }} />
					</div>
					<div>
						<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
							{chartOptions.name}
						</span>
					</div>
				</div>
				<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
					Current Measurement: {chartOptions.currentMeasurement.toFixed(0)}
				</span>
			</div>
		</div>
	)

	function getChartOptions(graphData, chartType, isLoading) {
		return {
			chart: {
				// offsetY: -20,
				sparkline: {
					enabled: true
				},
				toolbar: {
					tools: {
						download: false
					}
				},
				zoom: {
					enabled: false
				}
			},
			plotOptions: {
				radialBar: {
					startAngle: -90,
					endAngle: 90,
					// track: {
					// 	background: "#e7e7e7",
					// 	strokeWidth: "97%",
					// 	margin: 5, // margin is in pixels
					// 	dropShadow: {
					// 		enabled: true,
					// 		top: 2,
					// 		left: 0,
					// 		color: "#999",
					// 		opacity: 1,
					// 		blur: 2
					// 	}
					// },
					dataLabels: {
						name: {
							show: false
						},
						value: {
							offsetY: -2,
							fontSize: "14px"
						}
					}
				}
			},
			fill: {
				type: "gradient",
				gradient: {
					shade: "light",
					shadeIntensity: 0.4,
					inverseColors: false,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 53, 91]
				}
			},
			grid: {
				padding: {
					top: +10
				}
			},
			noData: {
				text: isLoading ? "Loading data..." : "No data to display."
			},
			legend: {
				show: false // Remove legend
			},

			dataLabels: {
				enabled: false
			},
			colors: ["#1065EF"],
			tooltip: {
				enabled: true
				// y: {
				// 	formatter: function (value) {
				// 		return Math.round(value)
				// 	}
				// }
			},
			stroke: {
				curve: "smooth"
			},

			responsive: [
				{
					breakpoint: 900,
					options: {
						chart: {
							width: "100%",
							height: "100%"
						}
					}
				}
			]
			// If this is uncommented we have a bug on UI when timeframe is "month"
			// responsive: [
			//   {
			//     breakpoint: 480,

			//   },
			// ],
		}
	}

	return (
		<Card isLoading={isLoading} style={{ height: "100%" }}>
			{titleBar}
			<CardBody style={{ padding: "10px" }}>
				<Chart
					options={getChartOptions(graphData, chartOptions?.type, isLoading)}
					series={graphData?.series || []}
					type={chartOptions && chartOptions?.type}
					height={200}
				/>
			</CardBody>
		</Card>
	)
}

const VolatileSensorGasesChart = ({ chartOptions, count }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [graphData, setGraphData] = useState({
		labels: [],
		series: [],
		colors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]
	})

	function getRandomArray(size) {
		let randomArray = []
		for (let i = 0; i < size; i++) {
			// Generate a random number between 0 and 70
			let randomNumber = Math.floor(Math.random() * 71)
			randomArray.push(randomNumber)
		}
		return randomArray
	}

	function generateData(count, yrange) {
		var i = 0
		var series = []
		while (i < count) {
			var x = "w" + (i + 1).toString()
			var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

			series.push({
				x: x,
				y: y
			})
			i++
		}
		return series
	}

	useEffect(() => {
		let isMounted = true

		setIsLoading(true)
		setTimeout(() => {
			if (isMounted) {
				setGraphData({
					series: [44, 55, 13, 43, 22],
					labels: getRandomArray(10),
					colors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]
				})

				setIsLoading(false)
			}
		}, 1000)

		return () => {
			isMounted = false
		}
	}, [])

	const titleBar = (
		<div className="title_bar_main_div">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<div className="floor_sensor_icon">
						<img src={chartOptions?.icon} alt="icon" style={{ width: "16px" }} />
					</div>
					<div>
						<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
							{chartOptions.name}
						</span>
					</div>
				</div>
				<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
					Current Measurement: {chartOptions.currentMeasurement.toFixed(0)}
				</span>
			</div>
		</div>
	)

	function getChartOptions(graphData, chartType, isLoading) {
		return {
			chart: {
				toolbar: {
					tools: {
						download: false
					}
				},
				zoom: {
					enabled: false
				}
			},
			grid: {
				show: false // Remove grid from background
			},
			noData: {
				text: isLoading ? "Loading data..." : "No data to display."
			},
			legend: {
				show: false // Remove legend
			},

			dataLabels: {
				enabled: true,
				style: {
					fontSize: "10px"
				}
			},
			colors: ["#412F8A", "#8680FF", "#A791FF", "#D3CDFD", "#E1DFF8"],
			tooltip: {
				enabled: true
				// y: {
				// 	formatter: function (value) {
				// 		return Math.round(value)
				// 	}
				// }
			},
			stroke: {
				show: false
			},

			yaxis: {
				show: false
			},

			responsive: [
				{
					breakpoint: 900,
					options: {
						chart: {
							width: "100%",
							height: "100%"
						}
					}
				}
			]
			// If this is uncommented we have a bug on UI when timeframe is "month"
			// responsive: [
			//   {
			//     breakpoint: 480,

			//   },
			// ],
		}
	}

	return (
		<Card isLoading={isLoading} style={{ height: "100%" }}>
			{titleBar}
			<CardBody style={{ padding: "0px" }}>
				<Chart
					options={getChartOptions(graphData, chartOptions?.type, isLoading)}
					series={graphData?.series || []}
					type={chartOptions && chartOptions?.type}
					height={200}
				/>
			</CardBody>
		</Card>
	)
}

const SensorBarometricPressureChart = ({ chartOptions, sensorData, count, average }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [graphData, setGraphData] = useState({
		labels: [],
		series: [],
		colors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]
	})

	function getRandomArray(size) {
		let randomArray = []
		for (let i = 0; i < size; i++) {
			// Generate a random number between 0 and 70
			let randomNumber = Math.floor(Math.random() * 71)
			randomArray.push(randomNumber)
		}
		return randomArray
	}

	// const data = generateMockData()

	useEffect(() => {
		let isMounted = true

		setIsLoading(true)
		setTimeout(() => {
			if (isMounted) {
				setGraphData({
					series: [
						{
							name: "node 111",
							data: sensorData
						}
					],
					// labels: getRandomArray(10),
					colors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]
				})

				setIsLoading(false)
			}
		}, 1000)

		return () => {
			isMounted = false
		}
	}, [sensorData])

	const titleBar = (
		<div className="title_bar_main_div">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<div className="floor_sensor_icon">
						<img src={chartOptions?.icon} alt="icon" style={{ width: "16px" }} />
					</div>
					<div>
						<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
							{chartOptions.name}
						</span>
					</div>
				</div>
				<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
					Current Measurement: {chartOptions.currentMeasurement.toFixed(0)}
				</span>
			</div>
		</div>
	)

	function getChartOptions(graphData, chartType, isLoading) {
		return {
			chart: {
				toolbar: {
					tools: {
						download: false
					}
				},
				zoom: {
					enabled: false
				}
			},
			grid: {
				show: false // Remove grid from background
			},
			noData: {
				text: isLoading ? "Loading data..." : "No data to display."
			},
			legend: {
				show: false // Remove legend
			},
			// colors: ["#2673F0"],
			dataLabels: {
				enabled: false
			},
			tooltip: {
				shared: false,
				x: {
					format: "MMM dd, HH:mm"
				}
			},
			stroke: {
				curve: "straight"
			},
			// xaxis: {
			// 	// tickPlacement: "between",
			// 	// tickAmount: 12
			// 	max: 12
			// },
			xaxis: {
				type: "datetime",
				labels: {
					format: "HH:mm"
				},
				tooltip: {
					enabled: false
				}
			},
			yaxis: {
				show: false
			},

			responsive: [
				{
					breakpoint: 900,
					options: {
						chart: {
							width: "100%",
							height: "100%"
						}
					}
				}
			]
			// If this is uncommented we have a bug on UI when timeframe is "month"
			// responsive: [
			//   {
			//     breakpoint: 480,

			//   },
			// ],
		}
	}

	return (
		<Card isLoading={isLoading} style={{ height: "100%" }}>
			{titleBar}
			<CardBody style={{ padding: "0px" }}>
				<Chart
					options={getChartOptions(graphData, chartOptions?.type, isLoading)}
					series={graphData?.series || []}
					type={chartOptions && chartOptions?.type}
					height={200}
				/>
			</CardBody>
		</Card>
	)
}

const SensorDetailCard = ({ children, title, showIcon = true, icon, bgColor }) => {
	return (
		<Card
			margin={false}
			style={{
				display: "flex",
				height: "120px",
				backgroundColor: bgColor,
				fontFamily: "Poppins",
				fontStyle: "normal",
				color: "#4A4A68",
				padding: "10px",
				marginBottom: "10px"
			}}
		>
			<div className="title_bar_main_div">
				<div className="d-flex justify-content-between">
					<div className="d-flex">
						{showIcon && (
							<div className="sensor_icon_count_detail">
								<img src={icon} alt="icon" style={{ width: "30px", height: "30px" }} />
							</div>
						)}
						<span
							className="ml-3"
							style={{
								fontSize: "28px",
								fontWeight: 700,
								color: "#4A4A68",
								paddingTop: "5px"
							}}
						>
							{title}
						</span>
					</div>
					{children}
				</div>
			</div>
		</Card>
	)
}

export default SensorsDetailsPage
