import * as requestFromServer from "./managePeopleCrud"
import { basePageSlice, callTypes } from "../../../redux/basePage/basePageSlice"
import moment from "moment"
import {
	compressUploadImage,
	fetchDownloadURL,
	handleDeleteFromStorageSinglePicture
} from "./managePeopleHelpers"

const { actions } = basePageSlice
// TODO: refactor
export const createPerson = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	const person = {
		firstName: queryParams.firstName || null,
		lastName: queryParams.lastName || null,
		sns: queryParams.sns || null,
		startDate: queryParams.startDate ? moment(queryParams.startDate).format("MM/DD/YYYY") : null,
		birthdate: queryParams.birthdate ? moment(queryParams.birthdate).format("MM/DD/YYYY") : null,
		gender: queryParams.gender || null,
		disabled: false,
		phoneNumber: queryParams.phoneNumber || null,
		email: queryParams.email || null,
		address: queryParams.address || null,
		idCard: queryParams.idCard || null,
		nationality: queryParams.nationality || null,
		passport: queryParams.passport || null,
		picture: queryParams.picture ? queryParams.picture : queryParams.picture === "" ? "" : null,
		role: queryParams.role || null,
		uuid: queryParams.uuid || null,
		firstEmergencyContact: queryParams.firstEmergencyContact || null,
		secondEmergencyContact: queryParams.secondEmergencyContact || null,
		pictureToUpload: queryParams.pictureToUpload || null,
		randomNumberUnique: queryParams.randomNumberUnique || null,
		visitor: queryParams.visitor || null
	}

	return requestFromServer
		.createPerson({ ...queryParams, person })
		.then(response => response.json())
		.then(result => {
			if (result?.people_created && result?.people_created[0]) {
				// if picture was uploaded, compress it and upload it.
				if (person && person.pictureToUpload && person.randomNumberUnique) {
					compressUploadImage(
						queryParams.customerId,
						person.pictureToUpload,
						person.randomNumberUnique
					).then(pictureUrl => {
						dispatch(
							actions.personCreated({
								...result?.people_created[0],
								id: result?.people_created[0].docId,
								picture: queryParams.picture || null,
								pictureUrl: pictureUrl || null
							})
						)
						dispatch({
							type: "SNACKBAR_SUCCESS",
							payload: {
								message: "Person created successfully"
							}
						})
					})
				} else {
					dispatch(
						actions.personCreated({
							...result?.people_created[0],
							id: result?.people_created[0].docId,
							picture: null,
							pictureUrl: null
						})
					)
					dispatch({
						type: "SNACKBAR_SUCCESS",
						payload: {
							message: "Person created with success"
						}
					})
				}

				queryParams.onHide && queryParams.onHide()
				return result?.people_created
			} else if (result?.people_failed.length > 0) {
				const errorMessage = result?.people_failed?.[0]?.errorMessages?.[0]

				if (errorMessage) {
					dispatch({
						type: "SNACKBAR_ERROR",
						payload: {
							message: errorMessage
						}
					})
					dispatch(
						actions.personNotCreated({
							id: result?.people_failed[0].docId,
							...result?.people_failed[0]
						})
					)
				}
			} else {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: {
						message: "Error creating person"
					}
				})
				dispatch(
					actions.personNotCreated({
						id: result?.people_failed[0].docId,
						...result?.people_failed[0]
					})
				)
			}
		})

		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Error creating person"
				}
			})
			console.log("Create person error: ", error)
			error.clientMessage = "Can't create person"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}
// TODO: refactor
export const editPerson = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	const person = {
		firstName: queryParams.firstName || null,
		lastName: queryParams.lastName || null,
		sns: queryParams.sns || null,
		startDate: queryParams.startDate ? moment(queryParams.startDate).format("MM/DD/YYYY") : null,
		birthdate: queryParams.birthdate ? moment(queryParams.birthdate).format("MM/DD/YYYY") : null,
		gender: queryParams.gender || null,
		disabled: false,
		phoneNumber: queryParams.phoneNumber || null,
		email: queryParams.email || null,
		address: queryParams.address || null,
		idCard: queryParams.idCard || null,
		nationality: queryParams.nationality || null,
		passport: queryParams.passport || null,
		picture: queryParams.picture ? queryParams.picture : queryParams.picture === "" ? "" : null,
		role: queryParams.role || null,
		uuid: queryParams.uuid || null,
		firstEmergencyContact: queryParams.firstEmergencyContact || null,
		secondEmergencyContact: queryParams.secondEmergencyContact || null,
		pictureToUpload: queryParams.pictureToUpload || null,
		randomNumberUnique: queryParams.randomNumberUnique || null,
		personImageFileName: queryParams.personImageFileName || null,
		deleteOldPic: queryParams.deleteOldPic || null
	}

	return requestFromServer
		.editPerson({ ...queryParams, person })
		.then(response => response.json())
		.then(result => {
			if (result?.people_updated && result?.people_updated[0]) {
				// delete the picture from storage
				if (person.personImageFileName && queryParams.deleteOldPic) {
					handleDeleteFromStorageSinglePicture(
						queryParams.customerId,
						person.personImageFileName
					)
				}

				// if picture was uploaded, compress it and upload it.
				if (person && person.pictureToUpload && person.randomNumberUnique) {
					compressUploadImage(
						queryParams.customerId,
						person.pictureToUpload,
						person.randomNumberUnique
					).then(pictureUrl => {
						dispatch(
							actions.personEdited({
								...result?.people_updated[0],
								id: result?.people_updated[0].docId,
								picture: queryParams.picture || null,
								pictureUrl: pictureUrl || null
							})
						)
						dispatch({
							type: "SNACKBAR_SUCCESS",
							payload: {
								message: "Person edited successfully"
							}
						})
					})
				} else if (person && person.personImageFileName) {
					if (!person.picture) {
						dispatch(
							actions.personEdited({
								...result?.people_updated[0],
								id: result?.people_updated[0].docId,
								personalData: {
									...result?.people_updated[0].personalData,
									picture: null
								},
								pictureUrl: null
							})
						)
						dispatch({
							type: "SNACKBAR_SUCCESS",
							payload: {
								message: "Person edited successfully"
							}
						})
					} else {
						person.picture &&
							fetchDownloadURL(person.picture).then(url => {
								dispatch(
									actions.personEdited({
										...result?.people_updated[0],
										id: result?.people_updated[0].docId,
										personalData: {
											...result?.people_updated[0].personalData,
											picture:
												person?.picture && !queryParams.deleteOldPic
													? person.picture
													: null
										},
										pictureUrl: url ? url : null
									})
								)
								dispatch({
									type: "SNACKBAR_SUCCESS",
									payload: {
										message: "Person edited successfully"
									}
								})
							})
					}
				} else {
					dispatch(
						actions.personEdited({
							...result?.people_updated[0],
							id: result?.people_updated[0].docId,
							picture: queryParams.picture || null
						})
					)
					dispatch({
						type: "SNACKBAR_SUCCESS",
						payload: {
							message: "Person edited successfully"
						}
					})
				}
				queryParams?.onHide()
				return result?.people_updated
			} else if (result?.people_failed.length > 0) {
				const errorMessage = result?.people_failed?.[0]?.errorMessages?.[0]

				if (errorMessage) throw new Error(errorMessage)
			} else {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: { message: "Error editing person" }
				})
			}
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: `Error editing person, ${error.message}`
				}
			})
			console.log("Edit person error: ", error)
			error.clientMessage = "Can't edit person"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const editPeopleGeofencing = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	const peopleToEdit = []
	queryParams.ids.forEach(person => {
		queryParams.people.forEach(p => {
			if (p.id === person) {
				peopleToEdit.push({
					...p,
					docId: person,
					geofencing: p.geofencing || []
				})
			}
		})
	})

	return requestFromServer
		.editPeopleGeofencing({ ...queryParams, peopleToEdit })
		.then(response => response.json())
		.then(result => {
			if (result?.people_updated && result?.people_updated[0]) {
				dispatch(
					actions.personEdited({
						id: result?.people_updated[0].docId,
						...result?.people_updated[0]
					})
				)

				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: {
						message: "Geofences people successfully"
					}
				})
			} else {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: {
						message: "Error geofencing person"
					}
				})
				dispatch(actions.personEdited({}))
			}
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Error geofencing person"
				}
			})
			console.log("Edit person error: ", error)
			error.clientMessage = "Can't geofence selected people"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const editPeopleUuid = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	const peopleToEdit = []
	queryParams.ids.forEach(person => {
		queryParams.people.forEach(p => {
			if (p.id === person) {
				peopleToEdit.push({
					...p,
					docId: person,
					geofencing: p.geofencing || [],
					name: p.personalData.name,
					phoneNumber: p.personalData.phoneNumber || null,
					email: p.personalData.email || null,
					nationality: p.personalData.nationality || null,
					idCard: p.personalData.idCard || null,
					sns: p.personalData.sns || null,
					firstName: p.personalData.firstName || null,
					lastName: p.personalData.lastName || null,
					passport: p.personalData.passport || null,
					driverLicense: p.personalData.driverLicense || null,
					birthdate: p.personalData.birthdate ? p.personalData.birthdate : null,
					companyId: p.companyId || null,
					company: "company",
					role: p.role || null,
					//! important clear uuid because is an unassign action
					uuid: null,
					emergencyName: p.emergencyName || null,
					emergencyNumber: p.emergencyNumber || null,
					supervisorName: p.supervisorName || null,
					supervisorNumber: p.supervisorNumber || null,
					startDate: p.startDate
						? moment(p.startDate.seconds * 1000).format("MM/DD/YYYY")
						: null,
					helmetId: p.helmetId || null
				})
			}
		})
	})

	return requestFromServer
		.editPeopleUuid({ ...queryParams, peopleToEdit })
		.then(response => response.json())
		.then(result => {
			if (result?.people_updated && result?.people_updated[0]) {
				dispatch(
					actions.personEdited({
						id: result?.people_updated[0].docId,
						...result?.people_updated[0]
					})
				)

				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: {
						message: "Unassigned people sucessfully"
					}
				})
			} else {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: {
						message: "Error unassigning people, try again"
					}
				})
				dispatch(actions.personEdited({}))
			}
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "Error 2 unassigning people"
				}
			})
			console.log("Edit person error: ", error)
			error.clientMessage = "Can't unassign selected people"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const uploadPeople = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	const currentDate = moment().format("MM/DD/YYYY")

	let people = queryParams.data.map(val => {
		const idCardString = val && val?.idCard && val?.idCard.toString()

		return {
			firstName: val.firstName || null,
			lastName: val.lastName || null,
			sns: val.sns || null,
			startDate: val.startDate ? moment(val.startDate).format("MM/DD/YYYY") : null,
			birthdate: val.birthdate ? moment(val.birthdate).format("MM/DD/YYYY") : null,
			gender: val.gender || null,
			disabled: false,
			phoneNumber: val.phoneNumber || null,
			email: val.email || null,
			address: val.address || null,
			idCard: val.idCard || null,
			nationality: val.nationality || null,
			passport: val.passport || null,
			role: val.role || null,
			uuid: val.uuid || null,
			firstEmergencyContact: val.firstEmergencyContact || null,
			secondEmergencyContact: val.secondEmergencyContact || null
		}
	})

	return requestFromServer
		.uploadPeople({ ...queryParams, people })
		.then(response => response.json())
		.then(result => {
			if (!result) throw Error("Can't make a request for server")

			if (result?.people_created && result?.people_created[0]) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: {
						message: "People uploaded with success"
					}
				})
				result.people_created.forEach(val => {
					val?.docId &&
						dispatch(
							actions.personCreated({
								...val,
								id: val?.docId
							})
						)
				})
				queryParams.onHide()
			} else {
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: {
						message: "Error uploading people"
					}
				})
				queryParams.onHide()
			}
		})

		.catch(error => {
			console.log("Upload people error: ", error)
			error.clientMessage = "Can't upload people"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "An error occured uploading people"
				}
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
			queryParams.onHide()
		})
}

export const deletePerson = queryParams => dispatch => {
	// dispatch(actions.startCall({ callType: callTypes.action }))
	// return requestFromServer
	// 	.deletePerson(queryParams)
	// 	.then(() => {
	// 		dispatch(actions.personDeleted(queryParams.person.id))
	// 		// updatePeopleBQ(queryParams.customerId)
	// 		dispatch({
	// 			type: "SNACKBAR_SUCCESS",
	// 			payload: "Person deleted with success"
	// 		})
	// 	})
	// 	.catch(error => {
	// 		console.log("Delete person error: ", error)
	// 		error.clientMessage = "Can't delete person"
	// 		dispatch(actions.catchError({ error, callType: callTypes.action }))
	// 	})
	return null
}

export const deletePeople = queryParams => dispatch => {
	// dispatch(actions.startCall({ callType: callTypes.action }))

	// return requestFromServer
	// 	.deletePeople(queryParams)
	// 	.then(() => {
	// 		queryParams.peopleToDelete.forEach(val => {
	// 			dispatch(actions.personDeleted(val.id))
	// 		})
	// 		// updatePeopleBQ(queryParams.customerId)
	// 		dispatch({
	// 			type: "SNACKBAR_SUCCESS",
	// 			payload: "Selected people deleted with success"
	// 		})
	// 	})
	// 	.catch(error => {
	// 		console.log("Delete people error: ", error)
	// 		error.clientMessage = "Can't delete people"
	// 		dispatch(actions.catchError({ error, callType: callTypes.action }))
	// 	})
	return null
}

export const disablePeople = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	let people = queryParams.peopleToDisable.map(data => {
		return {
			docId: data.id,
			disabled: data.disabled
		}
	})

	return requestFromServer
		.disablePeople({ ...queryParams, people })
		.then(response => response.json())
		.then(result => {
			if (!result) throw Error("Can't make a request for server")

			if (result?.people_updated && result?.people_updated[0]) {
				dispatch(actions.peopleDisabled())
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: {
						message: "Success"
					}
				})
			} else {
				dispatch(actions.peopleDisabled())
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: {
						message: "Error, please try again"
					}
				})
			}
		})

		.catch(error => {
			console.log("Enable/Disable people error: ", error)
			error.clientMessage = "Error, please try again"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: {
					message: "An error occured, please try again"
				}
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}
