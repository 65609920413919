import React, { useMemo, useState, useEffect } from "react"
import objectPath from "object-path"
import SVG from "react-inlinesvg"
import { useHtmlClassService } from "../../_core/MetronicLayout"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { shallowEqual, useSelector } from "react-redux"
import { storage } from "../../../../firebase"
import "./Brand.css"

export function Brand({ handleOpenDrawer, handleHideDrawer, mainDrawerOpened, matches }) {
	const uiService = useHtmlClassService()

	const layoutProps = useMemo(() => {
		return {
			brandClasses: uiService.getClasses("brand", true),
			asideSelfMinimizeToggle: objectPath.get(uiService.config, "aside.self.minimize.toggle"),
			headerLogo: uiService.getLogo(),
			headerStickyLogo: uiService.getStickyLogo()
		}
	}, [uiService])

	const { currentCustomer, currentSite } = useSelector(
		state => ({
			currentSite: state.profile?.currentSite,
			currentCustomer: state.profile?.currentCustomer
		}),
		shallowEqual
	)

	const [companyNameValue, setCompanyNameValue] = useState("")
	const [companyImagePath, setCompanyImagePath] = useState("")
	const [companyImageUrl, setCompanyImageUrl] = useState()

	const [siteNameValue, setSiteNameValue] = useState("")
	const [siteImagePath, setSiteImagePath] = useState("")
	const [siteImageUrl, setSiteImageUrl] = useState()

	useEffect(() => {
		let isMounted = true

		if (!currentCustomer) return

		if (isMounted) {
			setCompanyNameValue(currentCustomer?.customerInfo?.name)
			setCompanyImagePath(currentCustomer?.customerInfo?.logoPath)
		}

		return () => {
			isMounted = false
		}
	}, [currentCustomer])

	useEffect(() => {
		let isMounted = true

		if (!currentSite) return

		const matchedSite = currentCustomer?.sites?.find(site => site.id === currentSite?.id)

		if (isMounted) {
			setSiteNameValue(matchedSite?.name)
			setSiteImagePath(matchedSite?.logoPath)
			setSiteImageUrl(matchedSite?.logoUrl)
		}

		return () => {
			isMounted = false
		}
	}, [currentSite, currentCustomer])

	useEffect(() => {
		let isMounted = true

		if (companyImagePath) {
			fetchDownloadURL(companyImagePath)
				.then(url => {
					if (isMounted) {
						setCompanyImageUrl(url)
					}
				})
				.catch(error => {
					console.error(error)
					return
				})
		}

		return () => {
			isMounted = false
		}
	}, [companyImagePath])

	async function fetchDownloadURL(path) {
		try {
			const url = await storage.ref(path).getDownloadURL()
			return url
		} catch (error) {
			console.error(error)
			throw error
		}
	}

	const getUserPic = () => {
		if (!currentCustomer?.[0]?.data_url) {
			return `${companyImageUrl}`
		}
	}

	const getSitePic = () => {
		if (!currentSite?.siteInfo?.imageUrls?.[0]) {
			return `${companyImageUrl}`
		}
		return `${currentSite?.siteInfo?.imageUrls?.[0]}`
	}

	return (
		<>
			<div
				style={{
					padding: "0 16px",
					marginTop: "1rem",
					alignSelf: !matches && "center",
					flexDirection: !mainDrawerOpened ? "column" : "row"
				}}
				className={`brand ${layoutProps.brandClasses}`}
				id="kt_brand"
			>
				{matches && mainDrawerOpened && (
					<div className="brand-text">
						{siteImageUrl ? (
							<img style={{ width: "16%" }} src={`${getSitePic()}`} alt="logo" />
						) : companyImageUrl ? (
							<img style={{ width: "16%" }} src={`${getUserPic()}`} alt="logo" />
						) : (
							<span className="svg-icon svg-icon-xl mr-4">
								<SVG src={toAbsoluteUrl("/media/svg/icons/Files/Crowdkeep-logo.svg")} />
							</span>
						)}
						<span
							style={{
								fontSize: "16px",
								fontStyle: "normal",
								fontWeight: "700",
								lineHeight: "160%",
								marginLeft: companyImageUrl && "20px",
								alignContent: companyImageUrl && "center"
							}}
							className="to-hide-text"
						>
							{currentSite ? siteNameValue : companyNameValue}
						</span>
					</div>
				)}
				{layoutProps.asideSelfMinimizeToggle && (
					<>
						<button
							className="brand-toggle btn btn-sm px-0"
							id="kt_aside_toggle"
							onClick={() => {
								if (!matches) {
									handleHideDrawer()
									return
								}
								handleOpenDrawer()
							}}
						>
							<span className="svg-icon svg-icon-xl">
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-double-left.svg")}
								/>
							</span>
						</button>
						{!mainDrawerOpened && (
							<div
								style={{
									marginTop: "1rem",
									textAlign: "center"
								}}
							>
								{siteImageUrl ? (
									<img style={{ width: "90%" }} src={`${getSitePic()}`} alt="logo" />
								) : companyImageUrl ? (
									<img style={{ width: "90%" }} src={`${getUserPic()}`} alt="logo" />
								) : (
									<span className="svg-icon svg-icon-xl mr-4">
										<SVG
											src={toAbsoluteUrl("/media/svg/icons/Files/Crowdkeep-logo.svg")}
										/>
									</span>
								)}
							</div>
						)}
					</>
				)}
			</div>
		</>
	)
}
