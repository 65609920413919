import React from "react"
import { Box, MenuItem, Select } from "@mui/material"
import { NavigationMenuItem } from "../NavigationMenuItem.js"
import { useLocation } from "react-router"
import { useHistory } from "react-router-dom"

export function NavigationMenu({ menuItemsConfig, validRoutes, defaultRoute, isMobile = false }) {
	const location = useLocation()
	const history = useHistory()

	const handleChange = event => {
		history.push(event.target.value)
	}

	return (
		<Box
			sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
			data-testid="navigation-menu"
		>
			{isMobile ? (
				<Select
					value={location.pathname}
					onChange={handleChange}
					sx={{ minWidth: 120, backgroundColor: "white", borderRadius: 1 }}
				>
					{menuItemsConfig?.map(item => (
						<MenuItem key={item.route} value={item.route}>
							{item.label}
						</MenuItem>
					))}
				</Select>
			) : (
				menuItemsConfig?.map(item => (
					<NavigationMenuItem
						key={item.route}
						route={item.route}
						icon={item.icon}
						label={item.label}
						validRoutes={validRoutes}
						defaultRoute={defaultRoute}
					/>
				))
			)}
		</Box>
	)
}
