import React, { Suspense, lazy, useEffect, useState } from "react"
import { Redirect, Switch, Route } from "react-router-dom"
import { LayoutSplashScreen, ContentRoute } from "./layout"
import { DashboardPage } from "./pages/DashboardPage"
import { OperationsTab } from "./pages/OperationsTab"
import { ReportingPage } from "./modules/Reporting/ReportingPage"
import { PlatformUsersCard } from "./pages/platform-users/PlatformUsersCard"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import SnackBar from "./globalUI/snackBar/SnackBar"
import * as actions from "./redux/basePage/basePageActions"
import { basePageSlice } from "./redux/basePage/basePageSlice"
import ReactGa from "react-ga"
import { firestore, messaging, storage } from "../firebase"
import { collection, query, where, onSnapshot } from "firebase/firestore"

import { MapTestPage } from "./modules/Test/MapTestPage"
import Demo from "./Demo"
import { Modal } from "react-bootstrap"
import { Bold } from "./_partials/typography/Bold"
import { IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { ContactTracingPage } from "./modules/Admin/contact-tracing/ContactTracingPage"
import { enUS, enGB, pt, fr, de } from "date-fns/locale"
import { setDefaultLocale } from "react-datepicker"
import { getToken } from "firebase/messaging"
import SensorsMainPage from "./modules/Sensors/SensorsMainPage"
import { SensorsTab } from "./pages/SensorsTab"
import SensorsDetailsPage from "./modules/Sensors/SensorsDetailsPage/SensorsDetailsPage"
import { CommunicationPage } from "./modules/Communication/CommunicationPage"
import PersonDetailedPage from "./modules/Dashboard/LiveData/LiveMap/PersonDetailedPage"

const AdminPage = lazy(() => import("./modules/Admin/AdminPage"))
const UserProfilepage = lazy(() => import("./modules/UserProfile/UserProfilePage"))

export default function BasePage() {
	const dispatch = useDispatch()

	const {
		userClaims,
		selectedCustomer,
		selectedSite,
		user,
		globalOptions,
		messagingSwitch,
		notificationSwitch
	} = useSelector(
		state => ({
			userClaims: state.auth.claims,
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			user: state.auth?.user,
			globalOptions: state.basePage?.globalOptions,
			messagingSwitch: state.notifications?.messagingSwitch,
			notificationSwitch: state.notifications?.notificationSwitch
		}),
		shallowEqual
	)

	useEffect(() => {
		const supportedLocales = {
			"en-US": enUS,
			"en-GB": enGB,
			pt: pt,
			fr: fr,
			de: de
		}
		const browserLocale = navigator.language
		const defaultLocale = supportedLocales[browserLocale] || enUS
		setDefaultLocale(defaultLocale)
	}, [])

	useEffect(() => {
		if (globalOptions) return
		dispatch(actions.fetchGlobalOptions())
	}, [])

	useEffect(() => {
		dispatch(
			actions.setRolesAndAssetTypes({
				customer: selectedCustomer,
				roles: [],
				types: globalOptions?.defaultAssetTypes
			})
		)
	}, [selectedCustomer, globalOptions])

	useEffect(() => {
		dispatch(
			actions.setTagSeenTimeout({
				selectedSite,
				globalOptions
			})
		)
	}, [selectedSite, globalOptions])

	useEffect(() => {
		if (!selectedCustomer || !selectedSite) return

		const peopleRef = collection(firestore, `Customers/${selectedCustomer.id}/People`)

		// Using the `or` operator with Firestore queries isn't directly supported; however, we can perform the equivalent
		// by using a `where` clause to check for both `siteId` values. This method assumes that documents should match
		// either of the `siteId` values.
		const q = query(peopleRef, where("siteId", "in", [selectedSite.id, false]))

		const unsubscribe = onSnapshot(q, snapshot => {
			const data = snapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))

			updatePeopleAndAssetsWithImages(data)
				.then(response => {
					dispatch(basePageSlice.actions.peopleFetched(response || []))
				})
				.catch(error => {
					console.error("Error updating people and assets with images:", error)
				})
		})

		return () => {
			unsubscribe()
		}
	}, [selectedCustomer, selectedSite, firestore])

	useEffect(() => {
		if (!selectedCustomer || !selectedSite) return

		const assetsRef = collection(firestore, `Customers/${selectedCustomer.id}/Assets`)

		const q = query(assetsRef, where("siteId", "in", [selectedSite.id, false, null]))

		const unsubscribe = onSnapshot(q, snapshot => {
			const data = snapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))

			updatePeopleAndAssetsWithImages(data)
				.then(response => {
					dispatch(basePageSlice.actions.assetsFetched(response || []))
				})
				.catch(error => {
					console.error("Error updating assets with images:", error)
				})
		})

		return () => {
			unsubscribe()
		}
	}, [selectedCustomer, selectedSite])

	useEffect(() => {
		const siteIdPayload = { siteId: selectedSite?.id }
		const maxRetries = 3
		let retryCount = 0

		const fetchAllData = async () => {
			try {
				let floorPlanRes

				do {
					floorPlanRes = await dispatch(actions.fetchFloorPlans(siteIdPayload))

					if (floorPlanRes.length === 0) {
						retryCount++
						await new Promise(resolve => setTimeout(resolve, 1000)) // Optional delay
					} else {
						break
					}
				} while (floorPlanRes.length === 0 && retryCount < maxRetries)

				if (floorPlanRes.length === 0) {
					console.error("Max retries reached. Floor plans data is empty.")
				}

				await dispatch(actions.fetchAreas(siteIdPayload))
				await dispatch(actions.fetchPointsOfInterest(siteIdPayload))
			} catch (error) {
				console.error("Error fetching data:", error)
			}
		}

		fetchAllData()
	}, [selectedCustomer, selectedSite])

	useEffect(() => {
		ReactGa.initialize("UA-163381689-7")
		ReactGa.pageview(window.location.pathname + window.location.search)
	}, [])

	const [notificationModal, setNotificationModal] = useState()

	function onHide() {
		setNotificationModal()
	}

	useEffect(() => {
		if (!selectedCustomer) return

		handleTopicSubscriptions({
			notificationsEnabled: notificationSwitch,
			messagesEnabled: messagingSwitch,
			sites: selectedCustomer?.sites || [],
			userId: user?.id
		})
	}, [selectedCustomer, notificationSwitch, messagingSwitch])

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<SnackBar />

			<Demo>
				{!selectedCustomer && userClaims?.profileLvl >= 4 && (
					<>
						<Modal
							show={notificationModal && true}
							onHide={onHide}
							contentClassName="bg-transparent"
							backdrop={false}
							size="sm"
						>
							<Modal.Body
								style={{
									padding: "0",
									backgroundColor: "#ECF1F4",
									borderRadius: "10px",
									overflow: "hidden"
								}}
							>
								<div className="pl-7 pr-7 pb-7 pt-2">
									<div className="d-flex align-items-center justify-content-between">
										<div>
											<Bold>{notificationModal?.title}</Bold>
										</div>
										<div>
											<IconButton onClick={onHide} style={{ left: "12px" }}>
												<CloseIcon />
											</IconButton>
										</div>
									</div>

									<div>{notificationModal?.body}</div>
								</div>
							</Modal.Body>
						</Modal>
						<Switch>
							{<Redirect exact from="/" to="/dashboard" />}

							<ContentRoute path="/dashboard" component={DashboardPage} />
							<ContentRoute path="/sensors" component={SensorsTab} />
							<ContentRoute path="/communication" component={CommunicationPage} />
							<ContentRoute path="/dashboard-person/:id" component={DashboardPage} />
							<ContentRoute path="/person/:id" component={PersonDetailedPage} />

							<Route path="/user-profile" component={UserProfilepage} />

							{(userClaims?.isSuper || userClaims?.isAdmin) && (
								<Route path="/admin" component={AdminPage} />
							)}
							{userClaims?.isSuper && <Route path="/operations" component={OperationsTab} />}
							<ContentRoute path="/reporting" component={ReportingPage} />
							<Redirect to="error/error-v1" />
						</Switch>
					</>
				)}

				{selectedCustomer && (
					<>
						<Modal
							show={notificationModal && true}
							onHide={onHide}
							contentClassName="bg-transparent"
							backdrop={false}
							size="sm"
						>
							<Modal.Body
								style={{
									padding: "0",
									backgroundColor: "#ECF1F4",
									borderRadius: "10px",
									overflow: "hidden"
								}}
							>
								<div className="pl-7 pr-7 pb-7 pt-2">
									<div className="d-flex align-items-center justify-content-between">
										<div>
											<Bold>{notificationModal?.title}</Bold>
										</div>
										<div>
											<IconButton onClick={onHide} style={{ left: "12px" }}>
												<CloseIcon />
											</IconButton>
										</div>
									</div>

									<div>{notificationModal?.body}</div>
								</div>
							</Modal.Body>
						</Modal>
						<Switch>
							{<Redirect exact from="/" to="/dashboard" />}
							{userClaims?.isSuper && (
								<ContentRoute path="/maptest" component={MapTestPage} />
							)}
							{userClaims?.profileLvl >= 4 && (
								<ContentRoute path="/audit-tool" component={ContactTracingPage} />
							)}
							<ContentRoute path="/dashboard" component={DashboardPage} />
							<ContentRoute path="/sensor/:id" component={SensorsDetailsPage} />
							<ContentRoute path="/sensors" component={SensorsTab} />

							<Route path="/user-profile" component={UserProfilepage} />
							<ContentRoute path="/dashboard-person/:id" component={DashboardPage} />
							<ContentRoute path="/person/:id" component={PersonDetailedPage} />

							<ContentRoute path="/reporting" component={ReportingPage} />
							<ContentRoute path="/communication" component={CommunicationPage} />

							<Route path="/user-profile" component={UserProfilepage} />
							{(userClaims?.isSuper || userClaims?.isAdmin) && (
								<Route path="/admin" component={AdminPage} />
							)}
							{userClaims?.isSuper && <Route path="/operations" component={OperationsTab} />}
							{userClaims?.isSuper && (
								<ContentRoute path="/platform-users" component={PlatformUsersCard} />
							)}

							<Redirect to="error/error-v1" />
						</Switch>
					</>
				)}
			</Demo>
		</Suspense>
	)
}

async function updatePeopleAndAssetsWithImages(data) {
	if (!data) return null

	const images = await Promise.allSettled(
		data.map(async element => {
			let picturePath = element?.personalData?.picture || element?.picture

			if (!picturePath) return Promise.resolve(null)

			return storage
				.ref(picturePath)
				.getDownloadURL()
				.catch(err => console.log("Error: ", err))
		})
	)

	if (images) {
		return data.map((val, index) => {
			return {
				...val,
				pictureUrl: images[index]?.status === "fulfilled" ? images[index].value : null
			}
		})
	}
}

async function handleTopicSubscriptions({ notificationsEnabled, messagesEnabled, sites, userId }) {
	const permission = await Notification.requestPermission()
	if (permission !== "granted") {
		console.error("Unable to get permission to notify.")
		return
	}

	const currentToken = await getToken(messaging, {
		vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY
	}).catch(error => {
		console.error(`An error occurred while retrieving token. ${error}`)
	})

	if (!currentToken) {
		console.error("No registration token available.")
		return
	}

	var promises = []

	// Subscribe to geofencing for all sites
	//TODO This should be selectable per site and per group in a setting page
	sites.forEach(site => {
		const siteId = site.id
		if (!siteId) return

		var topicName = `${siteId}-geofencing`

		promises.push(
			topicFetch({
				operation: notificationsEnabled ? "subscribe" : "unsubscribe",
				currentToken,
				topicName
			})
		)
	})

	// Subscribe to messages
	var topicName = `${userId}-messages`
	promises.push(
		topicFetch({
			operation: messagesEnabled ? "subscribe" : "unsubscribe",
			currentToken,
			topicName
		})
	)

	return Promise.all(promises)
}

function topicFetch({ operation, currentToken, topicName }) {
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		operation: operation,
		messagingToken: currentToken,
		topicName: topicName
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/topic-subscriptions`,
		requestOptions
	).catch(err => {
		console.error(err)
	})
}
