import React, { useState, useEffect } from "react"
import "./TopRowInfo.css"
import ActiveSensorsChart from "./ActiveSensorsChart"
import { shallowEqual, useSelector } from "react-redux"
import { ModalProgressBar } from "../../../_partials/ModalProgressBar"
import { useCountDb } from "../../../_hooks/useCountDb"
import { COLLECTIONS, QUERY_CONSTRAINTS } from "../../../_enums/firestoreConstants"

const TopRowInfo = ({ isLoading, selectedSite }) => {
	const { sensors } = useSelector(
		state => ({
			sensors: state.liveData?.sensors
		}),
		shallowEqual
	)

	const [countedOnlineSensors, setCountedOnlineSensors] = useState(0)
	const [countedOfflineSensors, setCountedOfflineSensors] = useState(0)

	useEffect(() => {
		let isMounted = true

		const fetchData = async () => {
			try {
				const onlineSensorsCount = await useCountDb({
					collectionPath: `${COLLECTIONS.SITES}/${selectedSite?.id}/${COLLECTIONS.NODES}`,
					queryParams: {
						where: [
							[
								QUERY_CONSTRAINTS.WHERE.NODE_TYPE,
								QUERY_CONSTRAINTS.OPERATORS.EQUAL,
								QUERY_CONSTRAINTS.VALUES.SENSOR
							],
							[
								QUERY_CONSTRAINTS.WHERE.STATUS,
								QUERY_CONSTRAINTS.OPERATORS.EQUAL,
								QUERY_CONSTRAINTS.VALUES.ONLINE
							]
						]
					}
				})

				if (isMounted) {
					setCountedOnlineSensors(onlineSensorsCount)
				}

				const offlineSensorsCount = await useCountDb({
					collectionPath: `${COLLECTIONS.SITES}/${selectedSite?.id}/${COLLECTIONS.NODES}`,
					queryParams: {
						where: [
							[
								QUERY_CONSTRAINTS.WHERE.NODE_TYPE,
								QUERY_CONSTRAINTS.OPERATORS.EQUAL,
								QUERY_CONSTRAINTS.VALUES.SENSOR
							],
							[
								QUERY_CONSTRAINTS.WHERE.STATUS,
								QUERY_CONSTRAINTS.OPERATORS.NOT_EQUAL,
								QUERY_CONSTRAINTS.VALUES.ONLINE
							]
						]
					}
				})
				if (isMounted) {
					setCountedOfflineSensors(offlineSensorsCount)
				}
			} catch (error) {
				console.error(error)
			}
		}

		if (selectedSite?.id) {
			fetchData()
		}

		return () => {
			isMounted = false
		}
	}, [selectedSite?.id, sensors])

	return (
		<div
			className="row"
			style={{ minHeight: "10rem", marginBottom: "2rem" }}
			data-testid="top_row_div"
			id="sensors_top_row_info_main_div"
		>
			{isLoading && <ModalProgressBar variant="query" />}

			<div className="h-100 d-flex col-md-4 col-sm-6 col-xs-12 col flex-column justify-content-lg-between justify-content-md-between">
				<GlobalPeopleInfo
					value={countedOnlineSensors}
					text="Active Sensors"
					icon="/media/crowdkeep/sensor2.svg"
					color="#DDE9FD"
				/>
				<GlobalPeopleInfo
					value={countedOfflineSensors}
					text="Inactive/Faulty Sensors"
					icon="/media/crowdkeep/inactive sensor.svg"
					color="#DDE9FD"
				/>
			</div>
			<div className="h-100 d-flex col-md-4 col-sm-6 col-xs-12 col flex-column justify-content-lg-between justify-content-md-between">
				<GlobalPeopleInfo
					value={0}
					text="Alarms Triggered"
					icon="/media/crowdkeep/warning--alt.svg"
					color="#FBE6DE"
				/>
				<GlobalPeopleInfo
					value={0}
					text="Lorem Ipsum"
					icon="/media/crowdkeep/warning--alt.svg"
					color="#FBE6DE"
				/>
			</div>

			<div className="h-100 col-md-4 col-sm-12 col-xs-12 col-12 col">
				<ActiveSensorsChart />
			</div>
		</div>
	)
}

export default TopRowInfo

export function GlobalPeopleInfo({ value, text, icon, color }) {
	return (
		<div
			id="sensors_GlobalPeopleInfo_div"
			className="d-flex justify-content-between align-items-start"
			style={{
				backgroundColor: color,
				borderRadius: "5px",
				padding: "15px",
				height: "80px"
			}}
		>
			<div
				className="d-flex flex-column"
				style={{
					fontFamily: "Poppins",
					fontStyle: "normal",
					color: "#4A4A68"
				}}
			>
				<span
					style={{
						fontSize: "28px",
						lineHeight: "40px",
						fontWeight: "700"
					}}
				>
					{value}
				</span>
				<span
					className="top_row_info_text"
					style={{
						fontSize: "12px",
						lineHeight: "18px",
						fontWeight: "500"
					}}
				>
					{text}
				</span>
			</div>
			<img alt="Person Info Icon" src={icon} style={{ width: "24px" }} />
		</div>
	)
}
