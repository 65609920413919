import React, { useState, useEffect } from "react"
import { Card, CardBody } from "../../../_partials/Card"
import Chart from "react-apexcharts"

const NoiseSensorChart = ({ chartOptions, count, sensorData }) => {
	const [graphData, setGraphData] = useState({
		series: [0], // Default value
		minY: 0,
		maxY: 100,
		colors: ["#1065EF"]
	})

	useEffect(() => {
		if (!sensorData || sensorData.length === 0) {
			return
		}

		// Extract y values
		const yValues = sensorData.map(data => data.y)

		// Calculate min, max, and average
		const minY = Math.min(...yValues)
		const maxY = Math.max(...yValues)
		const avgY = yValues.reduce((sum, value) => sum + value, 0) / yValues.length

		// Update graph data
		setGraphData({
			series: [parseFloat(avgY.toFixed(2))], // Round to 2 decimal places
			minY,
			maxY,
			colors: ["#1065EF"]
		})
	}, [sensorData])

	const titleBar = (
		<div className="title_bar_main_div">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<div className="floor_sensor_icon">
						<img src={chartOptions?.icon} alt="icon" style={{ width: "16px" }} />
					</div>
					<div>
						<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
							{chartOptions.name}
						</span>
					</div>
				</div>
				<div className="floor_sensor_icon_count">
					<span style={{ color: "#8c8ca1", fontSize: "13px", fontWeight: 700 }}>{count}</span>
				</div>
			</div>
		</div>
	)

	return (
		<Card>
			{titleBar}
			<CardBody style={{ padding: "0px" }}>
				<Chart
					options={getChartOptions(graphData)}
					series={graphData?.series || [0]}
					type="radialBar"
					height="1050px" // Increased height to fill the card
				/>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginTop: "5px",
						padding: "0 10px"
					}}
				>
					<span style={{ fontSize: "12px", color: "#8c8ca1" }}>
						{graphData.minY.toFixed(2)}
					</span>
					<span style={{ fontSize: "12px", color: "#8c8ca1" }}>
						{graphData.maxY.toFixed(2)}
					</span>
				</div>
			</CardBody>
		</Card>
	)
}

export default NoiseSensorChart

function getChartOptions(graphData) {
	return {
		chart: {
			type: "radialBar",
			offsetY: 0, // Centered better inside the card
			sparkline: { enabled: true },
			toolbar: { tools: { download: false } }
		},
		plotOptions: {
			radialBar: {
				startAngle: -90,
				endAngle: 90,
				track: {
					background: "#e7e7e7",
					strokeWidth: "97%",
					margin: 0,
					dropShadow: { enabled: false } // ✅ Removed the shadow
				},
				dataLabels: {
					name: { show: false },
					value: {
						offsetY: -5,
						fontSize: "18px", // Reduced font size
						formatter: val => val.toFixed(2) // Ensure rounding
					}
				}
			}
		},
		colors: graphData?.colors || ["#1065EF"],
		labels: ["Average Noise Level"],
		noData: { text: "No data to display." },
		yaxis: {
			min: graphData.minY || 0,
			max: graphData.maxY || 100
		}
	}
}
