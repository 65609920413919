import { collection, query, where, getDocs } from "firebase/firestore"
import { firestore as db } from "../../../../../../firebase"

export const useFetchNodesByStatus = async (
	status,
	nodeType,
	setSensorCounts,
	setAllTotalCount,
	selectedSite,
	currentFloorPlan
) => {
	const collectionRef = collection(db, `Sites/${selectedSite.id}/Nodes`)
	const q = query(collectionRef, where("status", "==", status), where("nodeType", "==", nodeType))

	try {
		const snapshot = await getDocs(q)
		const data = snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		const filteredData = data.filter(sensor => sensor.floorPlanId === currentFloorPlan.id)

		setSensorCounts(prevState => ({
			...prevState,
			[status]: filteredData.length
		}))
		setAllTotalCount(prevState => ({
			...prevState,
			[status]: snapshot.size
		}))

		return filteredData
	} catch (error) {
		console.error("Error fetching nodes:", error)
		return []
	}
}
