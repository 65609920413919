import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { Popover } from "@mui/material"

const PopupStickOnClick = ({ component, children, id, className }) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const [showPopover, setShowPopover] = useState(false)
	const childRef = useRef(null)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
		setShowPopover(true)
	}

	const handleClose = () => {
		setShowPopover(false)
		setAnchorEl(null)
	}

	return (
		<>
			{React.cloneElement(children, { onClick: handleClick, ref: childRef })}
			<Popover
				open={showPopover}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				className={className}
			>
				<div id={id}>{component}</div>
			</Popover>
		</>
	)
}

PopupStickOnClick.propTypes = {
	children: PropTypes.element.isRequired,
	component: PropTypes.node.isRequired,
	placement: PropTypes.string,
	id: PropTypes.string,
	className: PropTypes.string
}

PopupStickOnClick.defaultProps = {
	placement: "top",
	className: "",
	id: "popover-id"
}

export default PopupStickOnClick
