import React, { useState, useEffect } from "react"
import { Card, CardBody } from "../../../_partials/Card"
import Chart from "react-apexcharts"

export const MotionSensorChart = ({ chartOptions, sensorData, count, average }) => {
	const [graphData, setGraphData] = useState({
		series: [],
		colors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]
	})

	useEffect(() => {
		let isMounted = true

		setTimeout(() => {
			if (isMounted) {
				setGraphData({
					series: [
						{
							name: "Metric1",
							data: sensorData.map(item => ({ x: item.x, y: item.y })) // Ensure proper data format for chart
						}
					],
					colors: ["#0F2A5E"] // Set a color for Metric1
				})
			}
		}, 1000)

		return () => {
			isMounted = false
		}
	}, [sensorData])

	const titleBar = (
		<div className="title_bar_main_div">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<div className="floor_sensor_icon">
						<img src={chartOptions?.icon} alt="icon" style={{ width: "16px" }} />
					</div>
					<div>
						<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
							{chartOptions.name}
						</span>
					</div>
				</div>
				<div className="floor_sensor_icon_count">
					<span style={{ color: "#8c8ca1", fontSize: "13px", fontWeight: 700 }}>{count}</span>
				</div>
			</div>
		</div>
	)

	return (
		<Card>
			{titleBar}
			<CardBody style={{ padding: "0px" }}>
				<Chart
					options={getChartOptions(graphData, chartOptions?.type)}
					series={graphData?.series || []}
					type={chartOptions?.type}
					height={140}
				/>
			</CardBody>
		</Card>
	)
}

export default MotionSensorChart

function getChartOptions(graphData, chartType) {
	return {
		chart: {
			height: 350,
			type: chartType || "line", // Defaulting to line chart if no type is provided
			toolbar: {
				tools: {
					download: false
				}
			},
			zoom: {
				enabled: false
			}
		},
		grid: {
			show: false
		},
		noData: {
			text: "No data to display."
		},
		legend: {
			show: false
		},
		dataLabels: {
			enabled: false
		},
		colors: graphData.colors || ["#0F2A5E"], // Use colors from graphData
		tooltip: {
			enabled: true,
			x: {
				formatter: function (value) {
					return new Date(value).toLocaleString() // Format timestamp in tooltip
				}
			},
			y: {
				formatter: function (value) {
					return Math.round(value) // Round value in tooltip
				}
			}
		},
		stroke: {
			curve: "smooth"
		},
		xaxis: {
			type: "datetime", // Ensure x-axis is treated as time-based
			tickAmount: 4,
			labels: {
				show: true,
				format: "HH:mm" // Show time format (you can adjust this based on your needs)
			}
		},
		yaxis: {
			show: false
		},
		fill: {
			type: "gradient",
			gradient: {
				shadeIntensity: 1,
				opacityFrom: 0.7,
				opacityTo: 0.9,
				stops: [0, 90, 100]
			}
		},
		responsive: [
			{
				breakpoint: 900,
				options: {
					chart: {
						width: "100%",
						height: "100%"
					}
				}
			}
		]
	}
}
