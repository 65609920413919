import React, { useState, useEffect } from "react"
import { AnchorsPositioningCard } from "./AnchorsPositioningCard"
import { useSelector, shallowEqual } from "react-redux"
import MapInfraTopRowInfo from "../map-infra-helpers/MapInfraTopRowInfo"
import useFetchNodesData from "../map-infra-helpers/_hooks/FetchNodesData"

const AnchorsPositioningPage = () => {
	const { selectedSite, currentFloorPlan } = useSelector(
		state => ({
			selectedSite: state.profile?.currentSite,
			currentFloorPlan: state.profile?.currentFloorPlan
		}),
		shallowEqual
	)

	// ━━━━━━━━━━━ States ━━━━━━━━━━━
	const [anchorCounts, setAnchorCounts] = useState({
		assigned: 0,
		planned: 0,
		placed: 0,
		approved: 0,
		online: 0,
		lost: 0,
		removed: 0
	})

	const [allTotalCount, setAllTotalCount] = useState({
		assigned: 0,
		planned: 0,
		placed: 0,
		approved: 0,
		online: 0,
		lost: 0,
		removed: 0
	})

	const [collectionChanged, setCollectionChanged] = useState(false)

	useEffect(() => {
		setCollectionChanged(false)
	}, [collectionChanged])

	const { isFetching } = useFetchNodesData(
		selectedSite,
		currentFloorPlan,
		collectionChanged,
		setAnchorCounts,
		setAllTotalCount,
		"AnchorsPosition",
		"anchor"
	)

	return (
		<div className="mt-3" data-testid="anchors-positioning-page">
			<MapInfraTopRowInfo
				isFetching={isFetching}
				anchorCounts={anchorCounts}
				allTotalCount={allTotalCount}
				nodeType="anchors"
				defaultSrc="/markers/anchor.svg"
			/>
			<AnchorsPositioningCard setCollectionChanged={setCollectionChanged} />
		</div>
	)
}

export default AnchorsPositioningPage
