import React from "react"

const TopRowInfoDetails = ({ sensors }) => {
	return (
		<div className="row" data-testid="top_row_div_details" id="sensors_top_row_info_details_div">
			<div className="h-100 d-flex col-md-6 col-sm-6 col-xs-12 col flex-column justify-content-lg-between justify-content-md-between mb-3 mb-md-0">
				<GlobalPeopleInfo
					value={sensors?.length || 0}
					text="Active Sensors"
					icon="/media/crowdkeep/sensor2.svg"
					color="#DDE9FD"
				/>
			</div>
			<div className="h-100 d-flex col-md-6 col-sm-6 col-xs-12 col flex-column justify-content-lg-between justify-content-md-between">
				<GlobalPeopleInfo
					value={0}
					text="Alarms Triggered"
					icon="/media/crowdkeep/warning--alt.svg"
					color="#FBE6DE"
				/>
			</div>
		</div>
	)
}

export default TopRowInfoDetails

export function GlobalPeopleInfo({ value, text, icon, color }) {
	return (
		<div
			id="sensors_GlobalPeopleInfo_details_div"
			className="d-flex justify-content-between align-items-center"
			style={{
				backgroundColor: color,
				borderRadius: "5px",
				padding: "15px",
				height: "40px"
			}}
		>
			<div
				className="d-flex flex-row align-items-center"
				style={{
					fontFamily: "Poppins",
					fontStyle: "normal",
					color: "#4A4A68"
				}}
			>
				<span
					style={{
						fontSize: "14px",
						fontWeight: "700",
						marginRight: "10px"
					}}
				>
					{value}
				</span>
				<span
					className="top_row_info_text"
					style={{
						fontSize: "12px",
						fontWeight: "500"
					}}
				>
					{text}
				</span>
			</div>
			<img alt="Person Info Icon" src={icon} style={{ width: "20px" }} />
		</div>
	)
}
