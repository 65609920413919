import React from "react"
import FloorSensorChart from "../FloorCharts/FloorSensorChart/FloorSensorChart"
import MotionSensorChart from "../FloorCharts/MotionSensorChart"
import NoiseSensorChart from "../FloorCharts/NoiseSensorChart"
import VolatileGasesChart from "../FloorCharts/VolatileGasesCart"
import BarometricPressureChart from "../FloorCharts/BarometricPressureChart"

const RenderAverageHistoryMeasurments = ({
	floorSensorOptions,
	filterTypeOptions,
	sensorsMeasurements
}) => {
	const processSensorData = key => {
		const groupedData = Object.values(sensorsMeasurements)
			.flat()
			.reduce((acc, sensor) => {
				const timestamp = new Date(sensor.measurement_time).getTime()
				const value = sensor.measurement?.[key] // Extract value dynamically

				if (value !== undefined) {
					if (!acc[timestamp]) {
						acc[timestamp] = { sum: 0, count: 0 }
					}
					acc[timestamp].sum += value
					acc[timestamp].count += 1
				}

				return acc
			}, {})

		return Object.entries(groupedData).map(([time, { sum, count }]) => ({
			x: Number(time),
			y: (sum / count).toFixed(2)
		}))
	}

	const getSensorCountWithKeyMeasurement = key => {
		return Object.values(sensorsMeasurements).filter(sensorArray =>
			sensorArray.some(sensor => sensor.measurement[key] !== undefined)
		).length
	}

	return (
		<div className="chart-container-overview">
			<div style={{ marginTop: "3rem" }}>
				<div className="row">
					{floorSensorOptions.map((chart, i) => {
						if (!filterTypeOptions.find(option => option.name === chart.name)?.selected)
							return null

						const sensorData = processSensorData(chart.key)

						const latestSensorArray = Object.values(sensorsMeasurements).flat()
						const maxTime = new Date(
							latestSensorArray?.[latestSensorArray?.length - 1]?.measurement_time
						).getTime()

						return (
							<div key={i} className="col-md-3 col-sm-6">
								<FloorSensorChart
									chartOptions={chart}
									sensorData={sensorData}
									color={chart.color}
									count={getSensorCountWithKeyMeasurement(chart.key)}
									maxTime={maxTime}
									// average={1}
								/>
							</div>
						)
					})}
				</div>
			</div>
			<div>
				<div className="row">
					{filterTypeOptions[6].selected && (
						<div className="col-md-3 col-sm-6">
							<MotionSensorChart
								chartOptions={{
									name: "Motion",
									type: "heatmap",
									icon: "/icons/motion.png"
								}}
								sensorData={processSensorData("motion")}
								count={getSensorCountWithKeyMeasurement("motion")}
								// average={motionCalculations.average}
							/>
						</div>
					)}

					{filterTypeOptions[3].selected && (
						<div className="col-md-3 col-sm-6">
							<NoiseSensorChart
								chartOptions={{
									name: "Noise Level",
									type: "radialBar",
									icon: "/icons/noise.png"
								}}
								sensorData={processSensorData("noiseLevel")}
								count={getSensorCountWithKeyMeasurement("noiseLevel")}
								// average={noiseCalculations.average}
							/>
						</div>
					)}
					{filterTypeOptions[6].selected && (
						<div className="col-md-3 col-sm-6">
							<VolatileGasesChart
								chartOptions={{
									name: "Volatile Gases",
									type: "pie",
									icon: "/icons/skill-level--intermediate.png"
								}}
								sensorData={processSensorData("volatileGases")}
								count={getSensorCountWithKeyMeasurement("volatileGases")}
								// average={volatilGasCalculations.average}
							/>
						</div>
					)}
					{filterTypeOptions[5].selected && (
						<div className="col-md-3 col-sm-6">
							<BarometricPressureChart
								chartOptions={{
									name: "Barometric Pressure",
									type: "line",
									icon: "/icons/sort--ascending.png"
								}}
								sensorData={processSensorData("barometricPressure")}
								count={getSensorCountWithKeyMeasurement("barometricPressure")}
								// average={barometricCalculations.average}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default RenderAverageHistoryMeasurments
