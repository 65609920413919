// eslint-disable-next-line import/no-webpack-loader-syntax
import React, { useState, useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import MapDimensions from "../../Admin/design-studio/MapControlButtons/MapDimensions"
import ChangeFloorControlNew from "../../Admin/design-studio/MapControlButtons/ChangeFloorControlNew"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { profileSlice } from "../../../redux/profile/profileSlice"
import { addGeoJsonLayerToMap, addImageLayerToMap, addSensorsToMap } from "../Helpers/Helpers"
import "./MapWidgetSensors.css"
import DrawerSensors from "../DrawerSensors/DrawerSensors"
import { addHeatmaptoMap } from "../Helpers/HeatmapHelpers"
import { useHistory } from "react-router-dom"
import {
	mouseClick,
	removePopupSticky
} from "../../../modules/Dashboard/LiveData/_helpers/SpiderifierHelpers"
import sensorIcon from "../../../../app/assets/dashboard_markers/sensor-marker.png"
import { Info } from "../Helpers/Helpers"
import MapboxglSpiderifier from "mapboxgl-spiderifier"
import { CgArrowsExpandRight } from "react-icons/cg"
import { CgBlock } from "react-icons/cg"
import { SensorChart } from "../Helpers/Helpers"
import PopupStickOnClick from "../../Operations/map-infrastructure/map-infra-helpers/PopupStickOnClick"

const { actions } = profileSlice

const MapWidgetSensors = () => {
	const mapcontainerref = useRef()
	const dispatch = useDispatch()
	const history = useHistory()

	const {
		selectedCustomer,
		currentFloorPlan,
		selectedSite,
		floorPlanData,
		sensors,
		sensorsMeasurements
	} = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			currentFloorPlan: state.profile?.currentFloorPlan,
			floorPlanData: state.basePage?.floorPlans,
			sensors: state.liveData?.sensors,
			sensorsMeasurements: state.liveData?.sensorsMeasurements || {}
		}),
		shallowEqual
	)
	// ━━━━━━━━ States ━━━━━━━━
	const [map, setMap] = useState(null)
	const [floorsControl, setFloorsControl] = useState()
	// ↓ drawer options control state
	const [drawerControl, setDrawerControl] = useState()
	const [openDrawerBar, setOpenDrawerBar] = useState(false)
	const [drawerOptions, setDrawerOptions] = useState({
		openSearch: false,
		openHeatmap: false
	})
	const [goTo, setGoTo] = useState()
	const [heatmapFilter, setHeatmapFilter] = useState({
		displayTemperature: true
	})
	const [showSensors, setShowSensors] = useState(true)
	const [sensorMarkers, setSensorMarkers] = useState([])

	// ━━━━━━━━ Map ━━━━━━━━
	useEffect(() => {
		let isMounted = true

		const map = new mapboxgl.Map({
			container: mapcontainerref.current,
			style: "mapbox://styles/mapbox/streets-v11"
		})

		map.on("load", function (e) {
			map.addControl(
				new mapboxgl.NavigationControl({
					showCompass: false
				}),
				"top-left"
			)
			map.addControl(new MapDimensions(), "bottom-left")

			if (isMounted) {
				setMap(map)
			}
		})

		map.doubleClickZoom.disable()

		return () => {
			if (!map) return
			isMounted = false
			setMap(prevMap => {
				if (prevMap) {
					prevMap.remove()
				}
				return null
			})
		}
	}, [])

	useEffect(() => {
		if (!map) return

		const spiderifier = new MapboxglSpiderifier(map, {
			customPin: true,
			animate: true,
			animationSpeed: 200,
			circleSpiralSwitchover: 15,
			initializeLeg: spiderLeg =>
				initializeSpiderLeg(
					spiderLeg,
					currentFloorPlan,
					mapcontainerref,
					setOpenDrawerBar,
					setDrawerOptions,
					dispatch,
					map,
					linkToSensorsId,
					selectedSite
				)
		})
		let lastClickedClusterId = null

		map.on("click", "cluster-pins", e => {
			const clusterId = e?.features?.[0]?.properties?.cluster_id

			if (clusterId === lastClickedClusterId) {
				spiderifier.unspiderfy()
				lastClickedClusterId = null

				removePopupSticky()
			} else {
				// Clicked a new cluster marker
				mouseClick(e, map, spiderifier)
				lastClickedClusterId = clusterId

				removePopupSticky()
			}
		})

		map.on("zoomstart", () => {
			spiderifier.unspiderfy()
			lastClickedClusterId = null

			removePopupSticky()
		})

		document.addEventListener("fullscreenchange", function () {
			if (document.fullscreenElement) {
				spiderifier.unspiderfy()
				lastClickedClusterId = null
			} else {
				spiderifier.unspiderfy()
				lastClickedClusterId = null
			}
		})

		return () => {
			map.off("click", "cluster-pins")
			map.off("zoomstart")
		}
	}, [map, currentFloorPlan, mapcontainerref, selectedSite])

	// ━━━━━━━━ Select new floorPlan (control floors buttons)
	const handleClick = floorId => {
		if (!floorId) return

		const foundedFloorPlan = floorPlanData && floorPlanData.find(f => f.id === floorId)

		foundedFloorPlan && dispatch(actions.floorPlanSelected(foundedFloorPlan))
	}

	// ━━━━━━━━ Change Floors Control Button ━━━━━━━━ \\
	useEffect(() => {
		if (!map) return

		if (floorsControl) map.removeControl(floorsControl)

		const _floorsControl = new ChangeFloorControlNew({
			dispatch: dispatch,
			selectedSite: selectedSite,
			selectedFloorPlan: currentFloorPlan,
			floorPlans: floorPlanData,
			handleClick
		})
		map.addControl(_floorsControl, "bottom-left")
		setFloorsControl(_floorsControl)
	}, [floorPlanData, selectedSite, currentFloorPlan, map])

	useEffect(() => {
		if (!map) return

		const bottomLeft = document.getElementsByClassName("mapboxgl-ctrl-bottom-left")

		if (bottomLeft && bottomLeft?.[0]) {
			bottomLeft.forEach(element => {
				element.style.display = "flex"
			})
		}
	}, [map])

	// ━━━━━━━━━━━ FloorPlan Image on Map ━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !currentFloorPlan) return

		addImageLayerToMap({
			map,
			floorPlan: currentFloorPlan
		})
	}, [map, currentFloorPlan])

	// ━━━━━━━━━━━━ GeoJson Areas ━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !currentFloorPlan) return

		addGeoJsonLayerToMap({
			map,
			geoJson: currentFloorPlan?.geoJson,
			layerTypes: selectedCustomer?.layerTypes
		})
	}, [map, currentFloorPlan, selectedCustomer])

	// ━━━━ Change route to sensor details
	const linkToSensorsId = val => {
		if (!val || !val.uuid) return
		history.push(`/sensor/${val.uuid}`)
	}

	// ━━━━━━━━━━━ Sensor markers ━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !selectedSite || !currentFloorPlan || sensors?.length === 0) return

		const layers = map.getStyle()

		if (layers) {
			addSensorsToMap({
				dispatch,
				map,
				selectedCustomer,
				selectedSite,
				currentFloorPlan,
				sensors,
				sensorsMeasurements,
				sensorMarkers,
				setSensorMarkers,
				showSensors,
				setShowSensors,
				linkToSensorsId
			})
		}
	}, [map, selectedSite, currentFloorPlan, sensorsMeasurements, selectedCustomer, showSensors])

	// ━━━━━━━━━━━━━ Drawer Sensors ━━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map) return

		if (drawerControl) map.removeControl(drawerControl)

		const _drawerControl = new DrawerSensors({
			selectedSite,
			openDrawerBar,
			setOpenDrawerBar,
			drawerOptions,
			setDrawerOptions,
			map,
			dispatch: dispatch,
			sensors,
			floorPlans: floorPlanData,
			floorPlan: currentFloorPlan,
			setGoTo: setGoTo,
			heatmapFilter,
			setHeatmapFilter
		})

		map.addControl(_drawerControl, "top-right")
		setDrawerControl(_drawerControl)
	}, [
		map,
		selectedSite,
		openDrawerBar,
		drawerOptions,
		floorPlanData,
		currentFloorPlan,
		sensors,
		heatmapFilter
	])

	// ━━━━━━━━━━━━━ Update Map For Heatmap ━━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !currentFloorPlan || !sensors) return

		addHeatmaptoMap({
			map,
			sensors,
			selectedFloorPlan: currentFloorPlan,
			setShowSensors,
			drawerOptions,
			setDrawerOptions,
			heatmapFilter
		})
	}, [drawerOptions, map, currentFloorPlan, sensors, heatmapFilter])

	return (
		<div>
			<div
				ref={mapcontainerref}
				style={{ height: "600px", borderRadius: "2px", marginBottom: "3rem" }}
			/>
		</div>
	)
}

function initializeSpiderLeg(spiderLeg, linkToSensorsId, selectedSite) {
	var feature = spiderLeg.feature
	const sensor = feature.sensorData
	var pinElem = spiderLeg.elements.pin

	const content = (
		<PopupStickOnClick
			component={
				<div
					style={{
						background: "#ECF1F4",
						boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
						borderRadius: "8px",
						fontFamily: "Poppins",
						fontStyle: "normal",
						color: "#4A4A68",
						padding: "1rem",
						width: "400px"
					}}
				>
					<div className="d-flex justify-content-between d-flex align-items-center mb-4">
						<div className="d-flex align-items-center">
							<span
								className="mr-3"
								style={{ color: "#4A4A68", fontSize: "16px", fontWeight: 700 }}
							>
								{" "}
								Temperature #1
							</span>
							<div
								style={{
									backgroundColor: "green",
									width: "14px",
									height: "14px",
									borderRadius: "50%"
								}}
							/>
						</div>

						<div
							className="d-flex justify-content-center align-items-center fullscreen_sensor"
							onClick={() => {
								if (sensor.measurement) {
									linkToSensorsId(sensor)
								}
							}}
							style={{
								pointerEvents: sensor.measurement ? "all" : "none",
								cursor: sensor.measurement ? "pointer" : "not-allowed"
							}}
						>
							{sensor.measurement ? (
								<CgArrowsExpandRight className="fullscreen_icon_sensor" />
							) : (
								<CgBlock className="fullscreen_icon_sensor" />
							)}
						</div>
					</div>
					<div
						style={{
							backgroundColor: "white",
							borderRadius: "5px"
						}}
					>
						<SensorChart sensor={sensor} selectedSite={selectedSite} />
					</div>

					<div className="mt-3">
						<Info sensorPosition={sensor} />
					</div>
				</div>
			}
			placement="top"
			onMouseEnter={() => {}}
			delay={500}
		>
			<div className="d-flex justify-content-center align-items-center">
				<img
					src={sensorIcon}
					alt="marker"
					style={{
						padding: "4px",
						objectFit: "contain",
						margin: "auto",
						width: "90%",
						height: "auto",
						display: "block"
					}}
				/>
			</div>
		</PopupStickOnClick>
	)

	ReactDOM.render(content, pinElem)
}

export default MapWidgetSensors
