import React, { useState, useMemo } from "react"
import { Card, CardBody } from "../../../../_partials/Card"
import Chart from "react-apexcharts"
import "./FloorSensorChart.css"
import { generateMockData } from "../../Helpers/Helpers"

const FloorSensorChart = ({ chartOptions, sensorData, color, average, count, maxTime }) => {
	const [graphData, setGraphData] = useState({
		labels: [],
		series: []
	})

	const data = generateMockData()

	useMemo(() => {
		let isMounted = true

		setTimeout(() => {
			if (isMounted) {
				setGraphData({
					series: [
						{
							name: chartOptions.name + " Average",
							data: sensorData
						}
					]
				})
			}
		}, 1000)

		return () => {
			isMounted = false
		}
	}, [sensorData])

	const titleBar = (
		<div className="title_bar_main_div">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<div className="floor_sensor_icon">
						<img src={chartOptions?.icon} alt="icon" style={{ width: "16px" }} />
					</div>
					<div>
						<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
							{chartOptions.name}
						</span>
					</div>
				</div>
				<div className="floor_sensor_icon_count">
					<span style={{ color: "#8c8ca1", fontSize: "13px", fontWeight: 700 }}>{count}</span>
				</div>
			</div>
		</div>
	)

	const showAverage = (chartOptions, value) => {
		if (chartOptions.name === "Temperature") {
			return `${value.toFixed(2)}ºF`
		} else if (chartOptions.name === "Humidity") {
			return `${value.toFixed(2)}%`
		} else if (chartOptions.name === "CO2") {
			return `${value.toFixed(0)}ppm`
		} else if (chartOptions.name === "Luminosity") {
			return `${value.toFixed(0)}xl`
		} else {
			return undefined
		}
	}

	const minTime = maxTime - 1 * 60 * 60 * 1000

	var options = {
		chart: {
			height: 350,
			type: "area",
			toolbar: {
				show: true,
				tools: {
					download: false
				}
			}
		},
		grid: {
			show: false
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			curve: "smooth"
		},
		xaxis: {
			type: "datetime",
			min: minTime,
			max: maxTime,
			labels: {
				show: false
			},
			axisBorder: {
				show: false
			},
			axisTicks: {
				show: false
			}
		},
		yaxis: {
			labels: {
				show: false
			}
		},
		tooltip: {
			enabled: true,
			x: {
				format: "dd/MM/yy HH:mm"
			}
		},
		colors: [color],
		fill: {
			type: "gradient",
			gradient: {
				type: "vertical",
				opacityFrom: 0.7,
				opacityTo: 0.9,
				stops: [0, 100],
				colorStops: [
					{
						offset: 0,
						color: color,
						opacity: 0.9
					},
					{
						offset: 100,
						color: color,
						opacity: 0
					}
				]
			}
		},
		noData: {
			text: "No data to display."
		}
	}

	return (
		<Card>
			{titleBar}
			<CardBody style={{ padding: "0px", position: "relative" }}>
				<span
					style={{
						position: "absolute",
						marginLeft: "auto",
						marginRight: "auto",
						color: "#ffffff!important",
						fontWeight: "bold",
						fontSize: "18px",

						top: "50%",
						left: "0",
						right: "0",
						textAlign: "center"
					}}
				>
					{/* {showAverage(chartOptions, average)} */}
				</span>
				<Chart options={options} type="area" series={graphData?.series || []} height="100%" />
			</CardBody>
		</Card>
	)
}

export default FloorSensorChart
