import React from "react"

const PAGE_TITLE = "Site Overview"

export const RenderMainLivePageTitle = () => {
	return (
		<div className="w-100 d-flex align-items-sm-start align-items-center justify-content-between mt-5 mb-5">
			<div>
				<h3 style={{ color: "#4A4A68" }}>
					<b>{PAGE_TITLE}</b>
				</h3>
			</div>
		</div>
	)
}

export default RenderMainLivePageTitle
