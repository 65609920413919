import React from "react"
import ReactDOM from "react-dom"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import RouterIcon from "@material-ui/icons/Router"
import { liveDataSlice } from "../../../_redux/liveData/liveDataSlice"
import { firestore as db } from "../../../../../firebase"
import { doc, getDocs, collection, query, where } from "firebase/firestore"

import {
	TagsMarkers,
	addTagsHasLayers,
	addTagsLayersAndClusters,
	addTagsLayersWithoutClusters,
	removeTagsLayers
} from "../LiveMap/Markers/TagsMarkers"
import _ from "lodash"
import * as turf from "@turf/turf"
import { addAnchorsHasLayers } from "./AnchorsHelpers"
import PopoverStickOnHover from "../PopoverStickOnHover"
import { IoLocationSharp } from "react-icons/io5"
import { addGatewaysHasLayers } from "./GatewaysHelpers"

const actions2 = liveDataSlice.actions

// Readme: Don't delete this function. This is when we need to create more nodes to test.
// helper function to create with random coords markers inside a polygon / feature
export const addRandomPointsInBox = params => {
	const { map, floorPlan } = params

	if (!map || !floorPlan) return

	const numberOfPointsToGenerate = 50
	const layers = map.getStyle()

	if (layers) {
		const coordsArray = []
		floorPlan.geoJson.features[3].geometry.coordinates.forEach(coord => {
			coordsArray.push([coord.latitude, coord.longitude])
		})

		const geoJsonFeature = {
			type: "Feature",
			geometry: {
				type: "Polygon",
				coordinates: [coordsArray]
			}
		}

		const bbox = turf.bbox(geoJsonFeature)

		const points = []

		var poly = turf.polygon([coordsArray])

		for (let i = 0; i < numberOfPointsToGenerate; i++) {
			const randomLongitude = Math.random() * (bbox[2] - bbox[0]) + bbox[0]
			const randomLatitude = Math.random() * (bbox[3] - bbox[1]) + bbox[1]

			var pt = turf.point([randomLongitude, randomLatitude])

			var inside = turf.booleanPointInPolygon(pt, poly)

			if (inside) {
				points.push([randomLatitude, randomLongitude])
			}
		}

		return points
	}
}

export const addImageLayerToMap = params => {
	//━━━ Parameters ━━━\\
	const { map, floorPlan, mapStyleChanged, newStyleLayer } = params

	if (!map) return

	const layers = map.getStyle()

	if (layers) {
		//━━━ Remove sources and layers ━━━\\
		typeof map.getLayer("imageLayer") !== "undefined" && map.removeLayer("imageLayer")
		typeof map.getSource("imageSource") !== "undefined" && map.removeSource("imageSource")
		typeof map.getLayer("geoJsonLayer") !== "undefined" && map.removeLayer("geoJsonLayer")
		typeof map.getSource("geoJsonSource") !== "undefined" && map.removeSource("geoJsonSource")
		typeof map.getLayer("geoJsonLinesLayer") !== "undefined" &&
			map.removeLayer("geoJsonLinesLayer")
		typeof map.getSource("geoJsonLinesSource") !== "undefined" &&
			map.removeSource("geoJsonLinesSource")

		if (!floorPlan) return
		let topLeftCornerLongitude =
			floorPlan?.coords?.topLeftCorner?.coords?.longitude ||
			floorPlan?.coords?.topLeftCorner?.longitude
		let topLeftCornerLatitude =
			floorPlan?.coords?.topLeftCorner?.coords?.latitude ||
			floorPlan?.coords?.topLeftCorner?.latitude
		let topRightCornerLongitude =
			floorPlan?.coords?.topRightCorner?.coords?.longitude ||
			floorPlan?.coords?.topRightCorner?.longitude
		let topRightCornerLatitude =
			floorPlan?.coords?.topRightCorner?.coords?.latitude ||
			floorPlan?.coords?.topRightCorner?.latitude
		let bottomRightCornerLongitude =
			floorPlan?.coords?.bottomRightCorner?.coords?.longitude ||
			floorPlan?.coords?.bottomRightCorner?.longitude
		let bottomRightCornerLatitude =
			floorPlan?.coords?.bottomRightCorner?.coords?.latitude ||
			floorPlan?.coords?.bottomRightCorner?.latitude
		let bottomLeftCornerLongitude =
			floorPlan?.coords?.bottomLeftCorner?.coords?.longitude ||
			floorPlan?.coords?.bottomLeftCorner?.longitude
		let bottomLeftCornerLatitude =
			floorPlan?.coords?.bottomLeftCorner?.coords?.latitude ||
			floorPlan?.coords?.bottomLeftCorner?.latitude

		if (!floorPlan?.imageUrl) {
			map.fitBounds(
				[
					[topLeftCornerLongitude, topLeftCornerLatitude],
					[bottomRightCornerLongitude, bottomRightCornerLatitude]
				],
				// ↓ Duração da animação a iniciar o mapa e o zoom inicial
				{
					padding: { top: 10, bottom: 10, left: 10, right: 10 },
					// animationOptions,
					duration: 0
				}
			)
		} else {
			//━━━ Add image ━━━\\
			map.addSource("imageSource", {
				type: "image",
				url: floorPlan?.imageUrl ? floorPlan?.imageUrl : "",
				coordinates: [
					[topLeftCornerLongitude, topLeftCornerLatitude],
					[topRightCornerLongitude, topRightCornerLatitude],
					[bottomRightCornerLongitude, bottomRightCornerLatitude],
					[bottomLeftCornerLongitude, bottomLeftCornerLatitude]
				]
			})

			typeof map.getSource("geoJsonLayer-0") !== "undefined"
				? map.addLayer(
						{
							id: "imageLayer",
							source: "imageSource",
							type: "raster",
							paint: {
								"raster-opacity": 0.8
							}
						}
						// "settlement-subdivision-label"
						// "state-label"
				  )
				: map.addLayer(
						{
							id: "imageLayer",
							source: "imageSource",
							type: "raster",
							paint: {
								"raster-opacity": 0.8
							}
						},
						"settlement-subdivision-label"
						// "state-label"
				  )
		}

		if (mapStyleChanged && !newStyleLayer) {
			return
		} else if (!mapStyleChanged && newStyleLayer) {
			return
		} else {
			// ↓ If exists is this floorPlan mapOrientation it displays orientation from db
			if (Boolean(floorPlan.mapOrientation) === true) {
				const lat = floorPlan?.mapOrientation?.center?.latitude
				const lng = floorPlan?.mapOrientation?.center?.longitude

				// ↑ equal to map.easeTo (to give animation fx)
				lng &&
					lat &&
					map.easeTo({
						center: [lng, lat],
						bearing: floorPlan?.mapOrientation?.bearing,
						pitch: floorPlan?.mapOrientation?.pitch,
						zoom: floorPlan?.mapOrientation?.zoom,
						duration: 0
					})
			} else {
				// Onde é centrado o mapa por default

				map.fitBounds(
					[
						[topLeftCornerLongitude, topLeftCornerLatitude],
						[bottomRightCornerLongitude, bottomRightCornerLatitude]
					],
					// ↓ Duração da animação a iniciar o mapa e o zoom inicial
					{
						padding: { top: 10, bottom: 10, left: 10, right: 10 },
						// animationOptions,
						duration: 0
					}
				)
			}
		}
	}
}

export const addGeoJsonLayerToMap = params => {
	const { map, geoJson, layerTypes } = params

	if (!map) return

	const layers = map.getStyle()

	if (layers) {
		typeof map.getLayer("geoJsonLayer") !== "undefined" && map.removeLayer("geoJsonLayer")
		typeof map.getSource("geoJsonSource") !== "undefined" && map.removeSource("geoJsonSource")
		typeof map.getLayer("geoJsonLinesLayer") !== "undefined" &&
			map.removeLayer("geoJsonLinesLayer")
		typeof map.getSource("geoJsonLinesSource") !== "undefined" &&
			map.removeSource("geoJsonLinesSource")

		var iterateWhile = true
		var index = 0

		while (iterateWhile === true) {
			typeof map.getLayer(`geoJsonLayer-${index}`) !== "undefined" &&
				map.removeLayer(`geoJsonLayer-${index}`)
			typeof map.getSource(`geoJsonSource-${index}`) !== "undefined" &&
				map.removeSource(`geoJsonSource-${index}`)
			typeof map.getLayer(`geoJsonLinesLayer-${index}`) !== "undefined" &&
				map.removeLayer(`geoJsonLinesLayer-${index}`)
			typeof map.getSource(`geoJsonLinesSource-${index}`) !== "undefined" &&
				map.removeSource(`geoJsonLinesSource-${index}`)
			index++
			if (
				typeof map.getLayer(`geoJsonLinesLayer-${index}`) === "undefined" &&
				typeof map.getSource(`geoJsonLinesSource-${index}`) === "undefined" &&
				typeof map.getLayer(`geoJsonLayer-${index}`) === "undefined" &&
				typeof map.getSource(`geoJsonSource-${index}`) === "undefined"
			) {
				iterateWhile = false
			}
		}

		if (!geoJson) return

		//━━━ Tranform data for mapbox ━━━\\
		geoJson.features.forEach((feature, index) => {
			typeof map.getLayer(`geoJsonLayer-${index}`) !== "undefined" &&
				map.removeLayer(`geoJsonLayer-${index}`)
			typeof map.getSource(`geoJsonSource-${index}`) !== "undefined" &&
				map.removeSource(`geoJsonSource-${index}`)
			typeof map.getLayer(`geoJsonLinesLayer-${index}`) !== "undefined" &&
				map.removeLayer(`geoJsonLinesLayer-${index}`)
			typeof map.getSource(`geoJsonLinesSource-${index}`) !== "undefined" &&
				map.removeSource(`geoJsonLinesSource-${index}`)

			var currentType =
				layerTypes && layerTypes.find(type => type.id === feature.properties.type)

			var coordinates = []
			feature.geometry.coordinates.forEach(coords => {
				coordinates.push([coords.longitude, coords.latitude])
			})

			const data = {
				type: geoJson.features[index].type,
				properties: {
					...geoJson.features[index].properties,
					color: currentType?.color || "#e9ecef",
					outline: feature.properties.geoFencing
						? "#4A4A68"
						: currentType?.color
						? currentType?.color
						: "#ced4da",
					lineWidth: feature.properties.type !== "baseLayer" ? (currentType ? 2 : 2) : 4
				},
				geometry: {
					type: geoJson.features[index].geometry.type,
					coordinates: [coordinates]
				}
			}

			const isVisible = feature.properties.visible === true ? "visible" : "none"

			const noPropertyInDBCondition =
				feature.properties.visible !== undefined ? isVisible : "visible"

			//━━━ Add to floorplan ━━━\\
			map.addSource(`geoJsonSource-${index}`, {
				type: "geojson",
				data: data
			})

			map.addLayer(
				{
					id: `geoJsonLayer-${index}`,
					source: `geoJsonSource-${index}`,
					type: "fill",
					layout: {
						visibility: `${noPropertyInDBCondition}`
					},
					paint: {
						"fill-color": ["get", "color"],
						"fill-outline-color": ["get", "outline"],
						"fill-opacity": 0.4
					}
				},
				"settlement-subdivision-label"
				// "state-label"
			)

			//━━━ Add lines ━━━\\
			map.addSource(`geoJsonLinesSource-${index}`, {
				type: "geojson",
				data: data
			})
			map.addLayer(
				{
					id: `geoJsonLinesLayer-${index}`,
					source: `geoJsonLinesSource-${index}`,
					type: "line",
					layout: {
						visibility: `${noPropertyInDBCondition}`
						// visibility: "visible",
					},
					paint: {
						"line-color": ["get", "outline"],
						"line-width": ["get", "lineWidth"]
					}
				},
				"settlement-subdivision-label"
				// "state-label"
			)
		})
	}
}

export async function addGatewaysToMap(params) {
	const {
		dispatch,
		customClaims,
		map,
		selectedCustomer,
		selectedSite,
		selectedFloorPlan,
		gateways,
		gatewaysMarkers,
		setGatewaysMarkers,
		showGateways,
		markersSize,
		containerRef,
		isFullscreen,
		gatewaysSize
	} = params

	if (!map || !selectedCustomer || !selectedSite || !selectedFloorPlan) return
	const nodesCollection = global.nodesCollection

	// Remove existing gateway markers
	if (gatewaysMarkers) {
		gatewaysMarkers.forEach(marker => marker.remove())
		setGatewaysMarkers([])
	}

	// Handle gateways layer visibility
	if (!showGateways) {
		if (map.getLayer("gateways-layer")) {
			map.removeLayer("gateways-layer")
		}
		if (map.getSource("gateways-source")) {
			map.removeSource("gateways-source")
		}
	}

	const _gateways =
		!showGateways || !gateways
			? []
			: gateways.filter(val => val.floorPlanId === selectedFloorPlan.id)

	// Remove gateways layer if no gateways for the current floor plan
	if (_gateways.length === 0 && showGateways) {
		const snapshot = await getDocs(
			query(
				collection(db, `Sites/${selectedSite.id}/${nodesCollection}`),
				where("floorPlanId", "==", selectedFloorPlan.id),
				where("nodeType", "==", "gateway")
			)
		)

		const newGateways = snapshot.docs.map(doc => ({
			id: doc.id,
			...doc.data()
		}))

		if (newGateways.length > 0) {
			dispatch(actions2.addGateways(newGateways))
		}
		return
	}

	// Add markers for each gateway
	const newMarkers = _gateways
		.map(val => {
			if (!val.gpsData) return null

			const markerDiv = document.createElement("div")
			markerDiv.className = "connectorsMarkerDashboard"
			markerDiv.style.cursor = "pointer"

			const markerSize = markersSize?.gateways
				? `${Math.abs(Math.floor((markersSize?.gateways / 100) * (80 - 14 + 1) + 14))}px`
				: "34px"

			const popupContent = (
				<PopoverStickOnHover
					component={
						customClaims?.isSuper && (
							<div
								style={{
									background: "#ECF1F4",
									boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
									borderRadius: "8px",
									fontFamily: "Poppins",
									color: "#4A4A68",
									paddingBottom: "2px",
									width: "276px"
								}}
							>
								<div className="d-flex justify-content-between align-items-center popup_title_dashboard">
									<span>{val.node_name}</span>
									<div>
										<IoLocationSharp
											style={{ height: "18px", width: "18px", marginRight: "5px" }}
										/>
										<span
											style={{ fontWeight: 300, fontSize: "10px", lineHeight: "160%" }}
										>
											{val?.floorPlanId === selectedFloorPlan.id
												? selectedFloorPlan?.name
												: null}
										</span>
									</div>
								</div>
								<InfoGatewayPopupDashboard gateway={val} />
							</div>
						)
					}
					placement="top"
					delay={200}
					containerRef={isFullscreen ? containerRef : null}
				>
					<div
						className="popupLineRoutes slider-markers-size markers-gateways"
						style={{
							backgroundColor: "transparent",
							borderRadius: "50%",
							width: markerSize,
							height: markerSize,
							position: "relative",
							overflow: "hidden"
						}}
						id={val.id}
					>
						<img
							style={{
								padding: "4px",
								objectFit: "contain",
								width: "76%",
								height: "auto",
								display: "none"
							}}
							src="/markers/gateway.svg"
							alt="Gateway icon"
							className="iconImageRoutes"
						/>
					</div>
				</PopoverStickOnHover>
			)

			ReactDOM.render(popupContent, markerDiv)

			const markerWithPopup = new mapboxgl.Marker(markerDiv, { draggable: false })
				.setLngLat([val.gpsData.longitude, val.gpsData.latitude])
				.addTo(map)

			return markerWithPopup
		})
		.filter(val => val)

	setGatewaysMarkers(newMarkers)

	// Add markers with layers
	const layers = map.getStyle()?.layers
	if (layers) {
		addGatewaysHasLayers({
			map,
			gateways: _gateways,
			gatewaysSize
		})
	}
}

export async function addAnchorsToMap(params) {
	const {
		customClaims,
		map,
		selectedCustomer,
		selectedSite,
		selectedFloorPlan,
		anchors,
		anchorsMarkers,
		setAnchorsMarkers,
		showAnchors,
		markersSize,
		containerRef,
		isFullscreen,
		anchorsSize
	} = params

	if (!map || !selectedCustomer || !selectedSite) return

	// Remove existing anchor markers
	if (anchorsMarkers) {
		anchorsMarkers.forEach(marker => marker.remove())
		setAnchorsMarkers([])
	}

	// Handle anchors layer visibility
	if (!showAnchors) {
		if (map.getLayer("anchors-layer")) {
			map.removeLayer("anchors-layer")
		}
		if (map.getSource("anchors-source")) {
			map.removeSource("anchors-source")
		}
	}

	if (!selectedFloorPlan) return

	const _anchors =
		!showAnchors || !anchors
			? []
			: anchors
					.filter(val => val?.floorPlanId === selectedFloorPlan?.id)
					.filter(val => val?.status === "online") // only display anchors "online"

	// Remove anchors if there are not on the correspondent floorPlanId
	const anchorsOnThisFloorPlan =
		_anchors && _anchors.some(val => val.floorPlanId === selectedFloorPlan.id)

	if (!anchorsOnThisFloorPlan) {
		if (typeof map.getLayer("anchors-layer") !== "undefined") {
			map.removeLayer("anchors-layer")
		}
		if (typeof map.getSource("anchors-source") !== "undefined") {
			map.removeSource("anchors-source")
		}
		return
	}

	// Add markers for each anchor
	const newMarkers = _anchors
		.map(val => {
			if (!val.gpsData || val.status !== "online") return null

			const markerSize = markersSize?.anchors
				? `${Math.abs(Math.floor((markersSize?.anchors / 100) * (80 - 14 + 1) + 14))}px`
				: "34px"

			const popupContent = (
				<PopoverStickOnHover
					component={
						customClaims?.isSuper && (
							<div
								style={{
									background: "#ECF1F4",
									boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
									borderRadius: "8px",
									fontFamily: "Poppins",
									color: "#4A4A68",
									paddingBottom: "2px",
									width: "276px"
								}}
							>
								<div className="d-flex justify-content-between align-items-center popup_title_dashboard">
									<span>{val.node_name}</span>
									<div>
										<IoLocationSharp
											style={{ height: "18px", width: "18px", marginRight: "5px" }}
										/>
										<span
											style={{ fontWeight: 300, fontSize: "10px", lineHeight: "160%" }}
										>
											{val?.floorPlanId === selectedFloorPlan.id
												? selectedFloorPlan?.name
												: null}
										</span>
									</div>
								</div>
								<InfoAnchorPopupDashboard anchor={val} />
							</div>
						)
					}
					placement="top"
					delay={200}
					containerRef={isFullscreen ? containerRef : null}
				>
					<div
						className="popupLineRoutes slider-markers-size markers-anchors"
						style={{
							backgroundColor: "transparent",
							borderRadius: "50%",
							width: markerSize,
							height: markerSize,
							position: "relative",
							overflow: "hidden",
							display: "flex",
							justifyContent: "center",
							alignItems: "center"
						}}
						id={val.id}
					>
						<img
							style={{
								padding: "4px",
								objectFit: "contain",
								width: "76%",
								height: "auto",
								display: "none"
							}}
							src="/markers/anchor.svg"
							alt="Anchor icon"
							className="iconImageRoutes"
						/>
					</div>
				</PopoverStickOnHover>
			)

			const markerDiv = document.createElement("div")
			markerDiv.style.cursor = "pointer"
			ReactDOM.render(popupContent, markerDiv)

			const markerWithPopup = new mapboxgl.Marker(markerDiv, { draggable: false })
				.setLngLat([val.gpsData.longitude, val.gpsData.latitude])
				.addTo(map)

			return markerWithPopup
		})
		.filter(val => val)

	setAnchorsMarkers(newMarkers)

	// Add markers with layers
	const layers = map.getStyle()?.layers
	if (layers) {
		addAnchorsHasLayers({
			map,
			anchors: _anchors,
			anchorsSize
		})
	}
}

function InfoGatewayPopupDashboard(props) {
	const { gateway } = props

	return (
		<div className="d-flex mt-3" style={{ padding: "0px 10px 8px 10px" }}>
			<div className="flex-fill mr-3 p-0">
				<div>
					<div
						style={{
							background: "#FFFFFF",
							borderRadius: "4px",
							minHeight: "5.5rem"
						}}
					>
						<div
							style={{
								padding: "3px",
								textAlign: "center",
								fontWeight: "300",
								lineHeight: "160%",
								fontSize: "10px",
								color: "#4A4A68"
							}}
						>
							UUID
						</div>
						<hr className="m-0" />
						<div
							style={{
								padding: "3px",
								textAlign: "center",
								fontWeight: "600",
								lineHeight: "160%",
								fontSize: "10px",
								color: "#4A4A68"
							}}
						>
							{gateway?.uuid}
						</div>
					</div>
				</div>
			</div>
			<div
				className="flex-fill p-0"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px",
					overflowY: "auto", // Enable vertical scrolling
					maxHeight: "108px",
					maxWidth: "150px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Status
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					{gateway?.status}
				</div>
			</div>
		</div>
	)
}

function InfoAnchorPopupDashboard(props) {
	const { anchor } = props

	return (
		<div className="d-flex mt-3" style={{ padding: "0px 10px 8px 10px" }}>
			<div className="flex-fill mr-3 p-0">
				<div>
					<div
						style={{
							background: "#FFFFFF",
							borderRadius: "4px",
							minHeight: "2.7rem"
						}}
					>
						<div
							style={{
								padding: "3px",
								textAlign: "center",
								fontWeight: "300",
								lineHeight: "160%",
								fontSize: "10px",
								color: "#4A4A68"
							}}
						>
							UUID
						</div>
						<hr className="m-0" />
						<div
							style={{
								padding: "3px",
								textAlign: "center",
								fontWeight: "600",
								lineHeight: "160%",
								fontSize: "10px",
								color: "#4A4A68"
							}}
						>
							{anchor?.uuid}
						</div>
					</div>
				</div>
			</div>
			<div
				className="flex-fill p-0"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px",
					overflowY: "auto", // Enable vertical scrolling
					maxHeight: "108px",
					maxWidth: "150px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Status
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					{anchor.status}
				</div>
			</div>
		</div>
	)
}

function getDifference(arr1, arr2) {
	// Find items in arr1 that are not in arr2
	const difference1 = arr1.filter(
		item1 => !arr2.some(item2 => item1.id === item2.id && item1.name === item2.name)
	)

	// Find items in arr2 that are not in arr1
	const difference2 = arr2.filter(
		item2 => !arr1.some(item1 => item1.id === item2.id && item1.name === item2.name)
	)

	// Combine the two differences
	return [...difference1, ...difference2]
}

export function addTagsToMap(props) {
	const {
		map,
		selectedFloorPlan,
		selectedSite,
		unfilteredTags,
		roles,
		assetTypes,
		setSendMessageModal,
		customClaims,
		areas,
		markers,
		setMarkers,
		oldTags,
		setOldTags,
		containerRef,
		setGetDirections,
		geoJsonRoutesUndefinedSnackBar,
		// setTravelTo,
		// setCopyOfStartPointCoords,
		setClickedToResetPath,
		isFullscreen,
		tagsUpdatedWithFullscreen,
		setTagsUpdatedWithFullscreen,
		showTags,
		showPeople,
		showAssets,
		showUnassignedTags,
		setOpenDrawerBar,
		setDrawerOptions,
		setEndingPoint,
		people,
		dispatch,
		showRoles,
		clickedRolesButton,
		setClickedRolesButton,
		roleFilterButtonStates,
		assetFilterButtonStates,
		markersSize,
		peopleSize,
		assetsSize,
		unassignedTagsSize,
		notificationWarning,
		companies,
		linkToPersonId
	} = props
	if (!map || !unfilteredTags || !selectedFloorPlan) return
	var shouldUpdateAllTags = false

	// ━━━━ Toggle fullscreen button ━━━━
	if (isFullscreen !== tagsUpdatedWithFullscreen) {
		shouldUpdateAllTags = true
		setTagsUpdatedWithFullscreen(isFullscreen)
	}

	// ━━━━ Toggle showRoles button - updates all the tags / people on map ━━━━
	if (showRoles !== clickedRolesButton) {
		shouldUpdateAllTags = true
		setClickedRolesButton(showRoles)
	}

	var finalMarkers = markers

	// ━━━━ Tags variable are the tags that are showed in the map
	const tags = !showTags
		? []
		: unfilteredTags.filter(tag => {
				if (!showPeople && tag.person) return false
				if (!showAssets && tag.asset) return false
				if (!showUnassignedTags && !tag.person && !tag.asset) return false
				if (tag.floorPlanId !== selectedFloorPlan?.id) return
				if (tag?.person?.role && !roleFilterButtonStates?.includes(tag?.person?.role))
					return false
				if (tag?.asset?.type && !assetFilterButtonStates?.includes(tag?.asset?.type))
					return false

				return true
		  })
	// const differences = getDifference(tags, unfilteredTags)
	// ━━━━ This is the tags that are updated in this run of the function
	const tagsToUpdate = shouldUpdateAllTags
		? tags
		: tags.filter(val => {
				const oldTag = oldTags.find(oldVal => oldVal.id === val.id)
				const isTagSameValue = _.isEqual(val, oldTag)
				return !isTagSameValue
		  })
	const tagsToRemove = oldTags.filter(val => !tags.some(value => val.id === value.id))

	// ━━━━━━━━ Remove all layers and markers
	tagsToRemove.forEach((tag, i) => {
		const markerToRemove = markers.find(m => m.id === tag.id)
		markerToRemove.marker.remove()
		const index = finalMarkers && finalMarkers.findIndex(value => markerToRemove.id === value.id)
		if (index !== -1) {
			finalMarkers && finalMarkers.splice(index, 1)
		}
		removeTagsLayers({ map, tag, index: i }) // remove layers markers on rerender or change floorplan
	})

	// New color if !showRoles
	let targetColor = null //!showRoles ? "#95A5A6" : null

	// ━━━━━━━━ Tags Markers (People and Assets) ━━━━━━━━
	var newMarkers =
		tagsToUpdate &&
		tagsToUpdate.map((tag, index) => {
			// if (tag.position) {
			// }

			const oldMarker = markers && markers.find(m => m.id === tag.id)

			if (oldMarker) oldMarker.marker.remove()

			const role = roles && roles.find(val => val.name === tag.person?.role) // find color of each role and pass to TagsMarkers

			// Color by tagState
			const tagStateColor =
				tag.tagState === "active"
					? "#53B04F"
					: tag.tagState === "idle"
					? "#F6B952"
					: tag.tagState === "SOS"
					? "#E65019"
					: "#95A5A6"

			const color = targetColor || tagStateColor
			const important = role?.important
			const content = (
				<TagsMarkers
					tag={tag}
					floorPlan={selectedFloorPlan}
					selectedSite={selectedSite}
					areas={areas}
					roles={roles}
					customClaims={customClaims}
					setSendMessageModal={setSendMessageModal}
					containerRef={containerRef}
					setGetDirections={setGetDirections}
					geoJsonRoutesUndefinedSnackBar={geoJsonRoutesUndefinedSnackBar}
					setClickedToResetPath={setClickedToResetPath}
					isFullscreen={isFullscreen}
					setOpenDrawerBar={setOpenDrawerBar}
					setDrawerOptions={setDrawerOptions}
					setEndingPoint={setEndingPoint}
					people={people}
					dispatch={dispatch}
					color={color}
					important={important}
					markersSize={markersSize}
					linkToPersonId={linkToPersonId}
				/>
			)

			const divElement = document.createElement("div")
			divElement.id = tag.id
			divElement.className = "tags_html_markers_dashboard"
			// divElement.style.display = "none"
			ReactDOM.render(content, divElement)

			const marker = new mapboxgl.Marker({
				element: divElement
			}).setLngLat([tag.gpsData.longitude, tag.gpsData.latitude])
			// .addTo(map) // Note: don't need to add to map anymore because popups are being created on mouseenter layer "unclustered-images"

			// return each property that we need to have on the layers feature.properties object
			return {
				id: tag?.id,
				marker,
				gpsData: tag?.gpsData,
				person: tag?.person,
				asset: tag?.asset,
				role: role,
				color: color,
				important: important,
				floorPlanId: selectedFloorPlan?.id,
				tagState: tag?.tagState,
				status: tag?.status,
				uuid: tag?.uuid,
				positioningAnchorInfo: tag?.positioningAnchorInfo,
				firstSeen: {
					seconds: tag?.firstSeen?.seconds,
					nanoseconds: tag?.firstSeen?.nanoseconds
				},
				lastSeen: {
					seconds: tag?.lastSeen?.seconds,
					nanoseconds: tag?.lastSeen?.nanoseconds
				},
				position: tag?.position || false,
				num_anchors: tag?.num_anchors || null,
				hop_count: tag?.hop_count || null,
				travel_time_ms: tag?.travel_time_ms || null,
				measurement: tag?.measurement
			}
		})

	// Update or add new markers into markers array
	newMarkers.forEach(val => {
		const index = finalMarkers && finalMarkers.findIndex(value => val?.id === value?.id)
		if (index !== -1) {
			finalMarkers[index] = val
		} else {
			finalMarkers.push(val)
		}
	})
	setOldTags(tags)
	setMarkers(finalMarkers)
	// ━━━━━━━━━ Add markers with Layers
	// console.log("🎃 🎃 🎃 🎃 🎃 map.getStyle()", map.getStyle().layers)

	const layers = map.getStyle()
	if (layers) {
		if (notificationWarning && notificationWarning.length !== 0) {
			// layer without clusters
			addTagsLayersWithoutClusters({
				map,
				geoJsonArray: finalMarkers,
				customClaims: customClaims,
				floorPlan: selectedFloorPlan,
				selectedSite,
				areas,
				peopleSize,
				assetsSize,
				unassignedTagsSize,
				setSendMessageModal,
				setGetDirections,
				geoJsonRoutesUndefinedSnackBar,
				setClickedToResetPath,
				setOpenDrawerBar,
				setDrawerOptions,
				setEndingPoint,
				dispatch,
				isFullscreen,
				containerRef,
				companies,
				linkToPersonId
			})
		} else {
			// clusters layers
			addTagsLayersAndClusters({
				map,
				geoJsonArray: finalMarkers,
				customClaims: customClaims,
				floorPlan: selectedFloorPlan,
				selectedSite,
				areas,
				peopleSize,
				assetsSize,
				unassignedTagsSize,
				setSendMessageModal,
				setGetDirections,
				geoJsonRoutesUndefinedSnackBar,
				setClickedToResetPath,
				setOpenDrawerBar,
				setDrawerOptions,
				setEndingPoint,
				dispatch,
				isFullscreen,
				containerRef,
				companies,
				linkToPersonId
			})
		}

		// Note: not used. Layers has markers (source forEach index)
		// 	finalMarkers &&
		// 	finalMarkers.forEach((tag, index) => {
		// 		addTagsHasLayers({ map, tag, index })
		// 	})
	}
}

export function addApMarkers(params) {
	const { map, aps } = params

	const filter = aps.filter(ap => ap.model.startsWith("MR"))

	var apMarkers = []

	filter.forEach(d => {
		var markerDiv = document.createElement("div")
		ReactDOM.render(
			<div className="pinBlack">
				<div className="letterDiv">
					<a className="letterText">
						<RouterIcon style={{ fontSize: "17px" }} />
					</a>
				</div>
			</div>,
			markerDiv
		)

		const marker = new mapboxgl.Marker(markerDiv, { anchor: "center" })
			.setLngLat([d.lng, d.lat])
			.addTo(map)

		apMarkers.push(marker)
	})

	return apMarkers
}

export function pixelToSize(pixel) {
	let newSize = 0.4

	if (pixel === 30) {
		newSize = 0.4
	} else if (pixel < 30) {
		newSize = 0.3
	} else if (pixel > 30) {
		newSize = 0.6
	}

	return newSize
}

export function percentageToSize(percentage, originalValue) {
	// avoid getting less than 4 of percentage so that markers don't disappear on map
	if (percentage > 50) {
		var result = (65 / 100) * originalValue
		return result
	} else if (percentage < 50) {
		result = (35 / 100) * originalValue
		return result
	} else if (percentage === 0) {
		result = (35 / 100) * originalValue
		return result
	} else {
		result = (percentage / 100) * originalValue
		return result
	}

	// if (percentage >= 4) {
	// 	var result = (percentage / 100) * originalValue
	// 	return result
	// } else {
	// 	result = (5 / 100) * originalValue
	// 	return result
	// }
}

export function ruleOfThree(value1, proportion1, proportion2) {
	// Check if any of the parameters are zero to avoid division by zero
	if (proportion1 === 0 || proportion2 === 0) {
		console.error("Error: Proportion values cannot be zero.")
		return NaN // Return NaN (Not a Number) to indicate an error
	}

	// Calculate the value2 using the rule of three formula
	const value2 = (value1 * proportion2) / proportion1

	return value2
}
