import React, { useState, useMemo } from "react"
import { Button } from "../../../_partials/Button"
import FilterListIcon from "@material-ui/icons/FilterList"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import CheckIcon from "@material-ui/icons/Check"
import AddIcon from "@material-ui/icons/Add"
import { GrFilter } from "react-icons/gr"
import { IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { ModalProgressBar } from "../../../_partials/ModalProgressBar"
import DateFilter from "../_partials/DateFilter"
import { ERRORS } from "../../../_enums/errors"
import { fetchAllSensorsFromToday } from "../../_redux/liveData/liveDataCrud"
import RenderAverageHistoryMeasurments from "../_partials/RenderAverageHistoryMeasurments"
import RenderDetailedHistoryMeasurments from "../_partials/RenderDetailedHistoryMeasurments"

const FloorSensorOverview = React.memo(() => {
	const [filterTypeOptions, setFilterTypeOptions] = React.useState([
		{ name: "Temperature", selected: true },
		{ name: "Humidity", selected: true },
		{ name: "CO2", selected: true },
		{ name: "Luminosity", selected: true },
		{ name: "Noise Level", selected: true },
		{ name: "Barometric Pressure", selected: true },
		{ name: "Motion", selected: true },
		{ name: "Volatile Gases", selected: true }
	])
	const floorSensorOptions = [
		{
			name: "Temperature",
			type: "area",
			icon: "/icons/temperature--celsius.png",
			key: "temperature",
			color: "#1065EF"
		},
		{
			name: "Humidity",
			type: "area",
			icon: "/icons/humidity--alt.png",
			key: "humidity",
			color: "#8680FF"
		},
		{ name: "CO2", type: "area", icon: "/icons/co2.png", key: "co2", color: "#8680FF" },
		{
			name: "Luminosity",
			type: "area",
			icon: "/icons/ai-status.png",
			key: "luminosity",
			color: "#1065EF"
		}
	]

	const dispatch = useDispatch()

	const { siteId, currentFloorPlan, sensors } = useSelector(
		state => ({
			siteId: state.profile?.currentSite.id,
			currentFloorPlan: state.profile?.currentFloorPlan,
			sensors: state.liveData?.sensors
		}),
		shallowEqual
	)

	const options = useMemo(
		() => [
			...sensors?.map(sensor => {
				return {
					name: sensor.node_name,
					value: sensor.id
				}
			})
		],
		[sensors]
	)

	const [dateRange, setDateRange] = useState([null, null])
	const [selectedSensors, setSelectedSensors] = useState(() => options.map(option => option.value))
	const [prevDateRange, setPrevDateRange] = useState([null, null])
	const [prevSelectedSensors, setPrevSelectedSensors] = useState([])

	const [sensorsMeasurements, setSensorsMeasurements] = useState({})
	const [isLoading, setIsLoading] = useState(false)

	const enableSubmit = useMemo(() => {
		return dateRange?.[0] && dateRange?.[1] && selectedSensors.length > 0
	}, [dateRange, selectedSensors])

	const handleSubmit = async () => {
		// Check if the dateRange and selectedSensors are the same as before
		const isSameDateRange =
			dateRange[0] === prevDateRange?.[0] && dateRange[1] === prevDateRange?.[1]
		const isSameSelectedSensors =
			JSON.stringify(selectedSensors) === JSON.stringify(prevSelectedSensors)

		// If both are the same, do nothing
		if (isSameDateRange && isSameSelectedSensors) {
			dispatch({
				type: ERRORS.TYPES.SNACKBAR_INFO,
				payload: { message: ERRORS.GENERIC_ERRORS.IDENTICAL_FETCH }
			})
			return
		}

		setIsLoading(true)

		try {
			const result = await fetchAllSensorsFromToday({
				siteId: siteId,
				dateRange: { startDate: dateRange[0], endDate: dateRange[1] },
				sensors: selectedSensors
			}).then(res => res.json())

			const { success, ...sensorData } = result

			if (!success) {
				dispatch({
					type: ERRORS.TYPES.SNACKBAR_ERROR,
					payload: { message: ERRORS.FETCH_ERRORS.SENSORS_DATA }
				})
			}

			setSensorsMeasurements(sensorData)
			// Save the current values as "previous" to compare on the next submit
			setPrevDateRange([...dateRange])
			setPrevSelectedSensors([...selectedSensors])
		} catch (error) {
			dispatch({
				type: ERRORS.TYPES.SNACKBAR_ERROR,
				payload: { message: ERRORS.FETCH_ERRORS.SENSORS_DATA }
			})
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div>
			<DateFilter
				dateRange={dateRange}
				setDateRange={setDateRange}
				options={options}
				selectedSensors={selectedSensors}
				setSelectedSensors={setSelectedSensors}
				enableSubmit={enableSubmit}
				handleSubmit={handleSubmit}
			/>
			<Filter
				filterTypeOptions={filterTypeOptions}
				setFilterTypeOptions={setFilterTypeOptions}
				isLoading={isLoading}
			/>
			<style>
				{`
        @media print {
          body * {
            visibility: hidden; /* Hide everything initially */
          }
          .chart-container-overview, .chart-container-overview * {
            visibility: visible; /* Show only the chart */
          }
          .chart-container-overview {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        `}
			</style>
			<RenderAverageHistoryMeasurments
				floorSensorOptions={floorSensorOptions}
				filterTypeOptions={filterTypeOptions}
				sensorsMeasurements={sensorsMeasurements}
			/>

			<div className="w-100 d-flex align-items-sm-start align-items-center justify-content-between mt-8 mb-5">
				<div>
					{isLoading && <ModalProgressBar variant="query" />}
					<h3 style={{ color: "#4A4A68" }}>
						<b>Detailed Sensor Overview</b>
					</h3>
				</div>
			</div>
			<RenderDetailedHistoryMeasurments sensorsMeasurements={sensorsMeasurements} />
		</div>
	)
})

export const Filter = React.memo(({ filterTypeOptions, setFilterTypeOptions, isLoading }) => {
	const [filterPosition, setFilterPosition] = React.useState({ top: 0, left: 0 })
	const [allSelected, setAllSelected] = React.useState(false)
	const [showFilter, setShowFilter] = React.useState(false)

	const handlePrint = () => {
		window.print()
	}

	const handleShowFilter = React.useCallback(event => {
		const { clientX, clientY } = event
		setFilterPosition({ top: clientY, left: clientX })
		setShowFilter(prev => !prev)
	}, [])

	const dynamicSelectedIconTypes = selected => {
		return selected ? (
			<CheckIcon style={{ color: "#FFFFFF" }} />
		) : (
			<AddIcon style={{ color: "#8C8CA2" }} />
		)
	}

	const toggleButtonTypes = index => {
		setFilterTypeOptions(prevState => {
			const newState = [...prevState]
			newState[index].selected = !newState[index].selected
			return newState
		})
	}

	const selectAllOptions = () => {
		setFilterTypeOptions(prevState => {
			const newState = prevState.map(option => ({
				...option,
				selected: !allSelected // Set selected to the opposite of current state
			}))
			return newState
		})
		setAllSelected(prev => !prev) // Toggle the allSelected state
	}

	return (
		<>
			<div className="w-100 d-flex align-items-sm-start align-items-center justify-content-between mt-8 mb-5">
				<div>
					{isLoading && <ModalProgressBar variant="query" />}
					<h3 style={{ color: "#4A4A68" }}>
						<b>Average Sensor Overview</b>
					</h3>
				</div>
				<div className="d-flex relative">
					{/* //? I don't think this is necessary */}
					{/* <Button
						variant="contained"
						color="primary"
						text="FILTER"
						endIcon={<FilterListIcon />}
						onClick={handleShowFilter}
						className="mr-3"
					/> */}

					<Button variant="contained" color="primary" text="Export" onClick={handlePrint} />
				</div>
			</div>

			{showFilter && (
				<div
					className="d-flex flex-column h-50"
					style={{
						position: "absolute",
						bottom: filterPosition.top - 1000,
						left: filterPosition.left - 300,
						padding: "2rem",
						background: "#FFFFFF",
						boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)",
						zIndex: 1000
					}}
				>
					<div className="drawer_title_container d-flex w-100 align-items-center justify-content-between">
						<div>
							<GrFilter
								className="drawer_option_title_icon1"
								style={{
									filter:
										"invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
								}}
							/>
							<span className="drawer_option_title_span">Filter</span>
						</div>
						<div>
							<IconButton
								className="livemap_drawer_filter_close_btn"
								size="small"
								onClick={() => setShowFilter(false)}
								data-testid="close-button"
							>
								<CloseIcon style={{ fontSize: "18px" }} />
							</IconButton>
						</div>
					</div>

					<div
						id="scroll-container-drawer-types-buttons"
						className="drawer_options_types_buttons"
						style={{
							display: "grid",
							gridTemplateColumns: "repeat(2, 1fr)",
							gap: "10px"
						}}
					>
						{filterTypeOptions.map((option, index) => (
							<Button
								key={index}
								className="sensor_options_filter_button"
								endIcon={dynamicSelectedIconTypes(option.selected)}
								style={{
									background: option.selected ? "#3077D3" : "#DBE1E5",
									color: option.selected ? "#FFFFFF" : "#8C8CA2",
									fontSize: "12px !important"
								}}
								variant="contained"
								onClick={() => toggleButtonTypes(index)}
							>
								<span>{option.name}</span>
							</Button>
						))}
					</div>
					<div className="d-flex align-items-center justify-content-end mt-3">
						<Button
							className="inventory_options_filter_button_select_all_filter_one mr-3"
							style={{
								backgroundColor: "#FFFFFF",
								color: "#3077D3",
								border: "1px solid #3077D3",
								width: allSelected ? "130px" : "120px",
								height: "31px"
							}}
							variant="contained"
							onClick={selectAllOptions}
						>
							{!allSelected ? "Select All" : "Unselect All"}
						</Button>
					</div>
				</div>
			)}
		</>
	)
})

export default FloorSensorOverview
