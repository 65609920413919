// SensorsDetailsPage.jsx
import React, { useMemo } from "react"
import { Card, CardBody } from "../../../_partials/Card"
import ReactApexChart from "react-apexcharts"

const RenderDetailedHistoryMeasurments = ({ sensorsMeasurements }) => {
	return (
		<div>
			<div className="row " style={{ marginBottom: "2rem" }}>
				<div className="col col-md-12">
					<SensorChart
						sensors={Object.entries(sensorsMeasurements).map(([id, data]) => ({
							id,
							data
						}))}
						chartOptions={{
							name: "Temperature",
							icon: "/icons/temperature--celsius.png",
							key: "temperature"
						}}
					/>
				</div>
				<div className="col col-md-12">
					<SensorChart
						sensors={Object.entries(sensorsMeasurements).map(([id, data]) => ({
							id,
							data
						}))}
						chartOptions={{
							name: "Humidity",
							icon: "/icons/humidity--alt.png",
							key: "humidity"
						}}
					/>
				</div>
				<div className="col col-md-12">
					<SensorChart
						sensors={Object.entries(sensorsMeasurements).map(([id, data]) => ({
							id,
							data
						}))}
						chartOptions={{
							name: "CO2",
							icon: "/icons/co2.png",
							key: "co2"
						}}
					/>
				</div>
				<div className="col col-md-12">
					<SensorChart
						sensors={Object.entries(sensorsMeasurements).map(([id, data]) => ({
							id,
							data
						}))}
						chartOptions={{
							name: "Luminosity",
							icon: "/icons/humidity--alt.png",
							key: "luminosity"
						}}
					/>
				</div>
				<div className="col col-md-12">
					<SensorChart
						sensors={Object.entries(sensorsMeasurements).map(([id, data]) => ({
							id,
							data
						}))}
						chartOptions={{
							key: "motion",
							name: "Motion (mock data)",
							icon: "/icons/motion.png"
						}}
					/>
				</div>
				<div className="col col-md-12">
					<SensorChart
						sensors={Object.entries(sensorsMeasurements).map(([id, data]) => ({
							id,
							data
						}))}
						chartOptions={{
							name: "Noise Level (mock data)",
							icon: "/icons/noise.png",
							key: "noiseLevel"
						}}
					/>
				</div>
				<div className="col col-md-12">
					<SensorChart
						sensors={Object.entries(sensorsMeasurements).map(([id, data]) => ({
							id,
							data
						}))}
						chartOptions={{
							name: "Volatile Gases (mock data)",
							icon: "/icons/skill-level--intermediate.png",
							key: "volatileGases"
						}}
					/>
				</div>
				<div className="col col-md-12">
					<SensorChart
						sensors={Object.entries(sensorsMeasurements).map(([id, data]) => ({
							id,
							data
						}))}
						chartOptions={{
							name: "Barometric Pressure (mock data)",
							icon: "/icons/sort--ascending.png",
							key: "barometricPressure"
						}}
					/>
				</div>
			</div>
		</div>
	)
}

export const SensorChart = ({ sensors, chartOptions }) => {
	if (!sensors) return null

	const chartData = useMemo(() => {
		if (!sensors || sensors.length === 0) return []

		return sensors
			.map(({ id: sensorId, data: sensorData }) => {
				// Destructure sensorId and sensorData
				if (!sensorData || sensorData.length === 0) return null

				const transformedData = sensorData
					.map(entry => {
						const value = entry.measurement?.[chartOptions.key]
						if (value === undefined || value === null) return null // Exclude invalid data
						return {
							x: new Date(entry.measurement_time).getTime(),
							y: value
						}
					})
					.filter(Boolean) // Remove null values

				if (transformedData.length === 0) return null // If no valid data, don't include the series

				return {
					name: `Sensor ${sensorId}`, // Use the sensor ID
					data: transformedData
				}
			})
			.filter(Boolean) // Remove empty series
	}, [sensors, chartOptions.key])

	const latestDate = useMemo(() => {
		if (!chartData || chartData.length === 0) return null
		const allTimestamps = chartData.flatMap(sensor => sensor.data.map(entry => entry.x))
		return Math.max(...allTimestamps)
	}, [chartData])

	const halfDayAgo = latestDate ? latestDate - 12 * 60 * 60 * 1000 : null

	const defaultChartOptions = {
		chart: {
			type: "line",
			height: 350,
			stacked: false,
			zoom: {
				type: "x",
				enabled: true,
				autoScaleYaxis: true
			},
			toolbar: { autoSelected: "zoom" }
		},
		noData: {
			text: "No data to display."
		},
		dataLabels: { enabled: false },
		markers: { size: 0 },
		fill: {
			type: "solid",
			colors: ["#0F2A5E", "#0C48AA", "#1065EF", "#5591F3", "#99BDF8", "#D1D1D1"]
		},
		xaxis: {
			type: "datetime",
			min: halfDayAgo, // Set the minimum value to one day before the latest date
			max: latestDate
		},
		yaxis: {
			labels: { show: true },
			title: { text: chartOptions.key }
		},
		tooltip: {
			shared: true,
			x: { format: "dd MMM HH:mm" },
			y: {
				formatter: value => {
					if (chartOptions.key === "temperature") {
						return `${value.toFixed(2)}°C`
					} else if (chartOptions.key === "humidity") {
						return `${value.toFixed(2)}%`
					} else if (chartOptions.key === "co2") {
						return `${value.toFixed(2)} ppm`
					}
					return value
				}
			}
		}
	}

	return (
		<div>
			<Card style={{ height: "100%" }}>
				<div className="title_bar_main_div">
					<div className="d-flex justify-content-between align-items-center">
						<div className="d-flex align-items-center">
							<div className="floor_sensor_icon">
								<img src={chartOptions.icon} alt="icon" style={{ width: "16px" }} />
							</div>
							<span className="ml-3" style={{ fontSize: "14px", fontWeight: 600 }}>
								{chartOptions.name}
							</span>
						</div>
					</div>
				</div>
				<CardBody style={{ padding: "0px" }}>
					<ReactApexChart
						options={defaultChartOptions}
						series={chartData}
						type="line"
						height={350}
					/>
				</CardBody>
			</Card>
		</div>
	)
}
export default RenderDetailedHistoryMeasurments
