import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	customUserRoles: [],
	lastError: null
}

export const callTypes = {
	list: "list",
	action: "action"
}

export const manageCustomUserRolesSlice = createSlice({
	name: "manageCustomUserRoles",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},

		customUserRoleFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.customUserRoles = action.payload
		},
		customUserRoleCreated: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.customUserRoles = state.customUserRoles
				? [...state.customUserRoles, action.payload]
				: state.customUserRoles
		},
		customUserRoleEdited: (state, action) => {
			state.actionsLoading = false
			state.error = null
			state.customUserRoles =
				state.customUserRoles &&
				state.customUserRoles.map(entity => {
					if (entity.id === action.payload.id) return action.payload
					return entity
				})
		},
		customUserRoleDeleted: (state, action) => {
			state.actionsLoading = false
			state.error = null

			// const idsToRemove = action.payload.map(role => role.id)
			const idsToRemove = action.payload

			state.customUserRoles = state.customUserRoles.filter(
				val => val.id?.toString() !== idsToRemove.toString()
			)
		}
	}
})
